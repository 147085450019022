import React, { useState, useEffect } from 'react';
import Filters from '../../../components/Filters/Filters';
import { WSpinnerLarge } from '../../../components/WLoader/WSpinner';
import { Card } from 'primereact/card';
import { areObjectsEqual } from '../../../utils/utils';
import BarChartComponent from '../../../components/Chart/BarChartComponent';
import HeatmapChart from '../../../components/Chart/HeatmapChart';
import ScatterChartComponent from '../../../components/Chart/ScatterChartComponent';
import axios from '../../../utils/axios';

// const filterData = {
//   color: { type: 'select', values: ['Red', 'Green', 'Blue'] },
//   size: { type: 'select', values: ['Small', 'Medium', 'Large'] },
//   price: { type: 'range', values: [0, 100] }, // Range from 0 to 100
// };

const SiteQuality = ({ scenarioID }) => {
  // const data = [
  //   {
  //     name: 'Cells',
  //     'Bar 1': 54.29,
  //     'Bar 2': 26.67,
  //     'Bar 3': 15.09,
  //     'Bar 4': 3.96,
  //   },
  // ];

  const series = [
    {
      name: 'Traffic',
      data: [
        { x: '<=5K', y: 41.81 },
        { x: '5K-10K', y: 25.19 },
        { x: '10K-20K', y: 22.14 },
        { x: '20K-30K', y: 7.13 },
        { x: '30K-40K', y: 2.35 },
        { x: '40K-50K', y: 0.81 },
        { x: '50K-75K', y: 0.43 },
        { x: '75K-100K', y: 0.08 },
        { x: '>100K', y: 0.06 },
      ],
    },
  ];

  //   [
  //     {
  //         "data": [
  //             {
  //                 "x": "10K-20K",
  //                 "y": 22.0
  //             },
  //             {
  //                 "x": "20K-30K",
  //                 "y": 7.0
  //             },
  //             {
  //                 "x": "30K-40K",
  //                 "y": 2.0
  //             },
  //             {
  //                 "x": "40K-50K",
  //                 "y": 0.0
  //             },
  //             {
  //                 "x": "50K-75K",
  //                 "y": 0.0
  //             },
  //             {
  //                 "x": "5K-10K",
  //                 "y": 25.0
  //             },
  //             {
  //                 "x": "75K-100K",
  //                 "y": 0.0
  //             },
  //             {
  //                 "x": "<=5K",
  //                 "y": 41.0
  //             },
  //             {
  //                 "x": ">100K",
  //                 "y": 0.0
  //             }
  //         ],
  //         "name": "Cells by Traffic in Busy Hour"
  //     }
  // ],

  const options = {
    chart: {
      height: 60,
      type: 'heatmap',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        radius: 4,
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 10,
              color: '#cce1f2',
            },
            {
              from: 10,
              to: 20,
              color: '#99c3e4',
            },
            {
              from: 20,
              to: 30,
              color: '#6695d6',
            },
            {
              from: 30,
              to: 40,
              color: '#3367c8',
            },
            {
              from: 40,
              to: 50,
              color: '#0039ba',
            },
            {
              from: 50,
              to: 100,
              color: '#002b8c',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#1f1f1f'],
      },
    },
    xaxis: {
      type: 'category',
      categories: ['<=5K', '5K-10K', '10K-20K', '20K-30K', '30K-40K', '40K-50K', '50K-75K', '75K-100K', '>100K'],
      labels: {
        style: {
          colors: '#FFFFFF',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#FFFFFF',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  const colors = ['#3E8838', '#A4548B', '#C6A246', '#C64947'];

  const handleFilterChange = (key, type, selectedValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        type,
        values: selectedValues,
      },
    }));
  };

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/phase2-sitequality/';
    axios
      .post(url, filters, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setFilterData(response.data.filters);
        Object.keys(response.data.filters).forEach((key) => {
          const type = response.data.filters[key].type;
          const values = response.data.filters[key].values;
          handleFilterChange(key, type, values);
        });
        setLoading(false);
      });
  }, []);

  const applyFilters = () => {
    setLoading(true);
    const url = '/api/charts/phase2-sitequality/';
    const filtersBody = areObjectsEqual(filters, filterData) ? {} : filters;
    axios
      .post(url, filtersBody, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        // setFilterData(response.data.filters);
        setLoading(false);
      });
  };

  return (
    <div className="container1">
      {/* {isOpen && <FiltersModal isOpen={isOpen} openHandler={openHandler} filterData={filterData} />} */}
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div style={{ flex: 1 }}>
            <Filters
              data={filterData}
              filters={filters}
              handleFilterChange={handleFilterChange}
              applyFilters={applyFilters}
            />
          </div>
          <div style={{ flex: 9 }}>
            {/* <div className="p-grid p-nogutter stats-container">
              <div className="p-col-12 p-md-6 cards">
                {Object.keys(data.stats[0]).map((key) => (
                  <Card className="custom-card">
                    <div className="card-content">
                      <h2>{convertToTitleCase(key)}</h2>
                      <h3>{formatNumber(data.stats[0][key])}</h3>
                    </div>
                  </Card>
                ))}
              </div>
            </div> */}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                // justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginTop: '20px',
              }}
            >
              <div
                style={{
                  width: '18%',
                  height: '306px',
                  marginBottom: '-20px',
                  borderRadius: '8px',
                  border: '0.5px solid transparent',
                }}
              >
                {/* eslint-disable-next-line react/jsx-boolean-value */}
                <BarChartComponent
                  phase={2}
                  data={data?.chart_data?.cell_by_quality}
                  colors={colors}
                  stacked={true}
                  title="Cells by Quality Level"
                />
              </div>

              <div
                style={{
                  width: '68%',
                  height: '306px',
                  marginBottom: '-20px',
                  borderRadius: '8px',
                  border: '0.5px solid transparent',
                }}
              >
                <HeatmapChart
                  series={data?.chart_data?.cells_by_traffic_in_busy_hour}
                  options={options}
                  title="Propensity to device technology change"
                  height={200}
                  width={1000}
                />
              </div>
              <div
                style={{
                  width: '18%',
                  height: '306px',
                  marginBottom: '20px',
                  //   marginTop: '300px',
                  borderRadius: '8px',
                  border: '0.5px solid transparent',
                }}
              >
                {/* eslint-disable-next-line react/jsx-boolean-value */}
                <BarChartComponent
                  phase={2}
                  data={data?.chart_data?.traffic_by_quality}
                  colors={colors}
                  stacked={true}
                  title="Traffic by Quality Level"
                />
              </div>
              {/* <div
                style={{
                  width: '78%',
                  height: '306px',
                  marginBottom: '20px',
                  //   marginTop: '300px',
                  borderRadius: '8px',
                  border: '0.5px solid transparent',
                }}
              >
                <ScatterChartComponent data={data?.chart_data?.traffic_distribution} />
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SiteQuality;
