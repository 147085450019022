import UserScreenItem from './UserScreenItem/UserScreenItem';
import './UserScreen.scss';

const UserScreen = () => {
  return (
    <div className="userscreen">
      <UserScreenItem />
    </div>
  );
};

export default UserScreen;
