/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import axios from '../../utils/axios';
import {
  FILE_BEGIN_PHASE_1,
  FILE_GET_PHASE_1,
  FILE_POST_PHASE_1,
  FILE_ERROR_PHASE_1,
  FILE_BEGIN_PHASE_2,
  FILE_GET_PHASE_2,
  FILE_POST_PHASE_2,
  FILE_ERROR_PHASE_2,
  FILE_EXCEL_LOADING_PHASE_1,
  FILE_EXCEL_LOADING_PHASE_2,
} from '../actionTypes';

const getInputFilePhase1 = (inputId, scenarioId) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: FILE_BEGIN_PHASE_1 });
      // check user.
      const url = `/api/scenarios/${scenarioId}/inputs/${inputId}/files/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: FILE_GET_PHASE_1, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: FILE_ERROR_PHASE_1, payload: { error } });
    }
  };
};

const getInputFilePhase2 = (inputId, scenarioId) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: FILE_BEGIN_PHASE_2 });
      // check user.
      const url = `/api/scenarios/${scenarioId}/inputs/${inputId}/files/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: FILE_GET_PHASE_2, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: FILE_ERROR_PHASE_2, payload: { error } });
    }
  };
};

const deleteInputFilePhase = (inputId, scenarioId, phaseNo) => {
  return async (dispatch) => {
    const getTypes = () => {
      let type = { beginType: '', getType: '', errorType: '' };
      if (phaseNo === 1)
        type = { ...type, beginType: FILE_BEGIN_PHASE_1, getType: FILE_GET_PHASE_1, errorType: FILE_ERROR_PHASE_1 };
      if (phaseNo === 2)
        type = { ...type, beginType: FILE_BEGIN_PHASE_2, getType: FILE_GET_PHASE_2, errorType: FILE_ERROR_PHASE_2 };
      return type;
    };

    const actionType = getTypes();

    try {
      // Begin.
      dispatch({ type: actionType.beginType });
      // check user.
      const url = `/api/scenarios/${scenarioId}/inputs/${inputId}/files/`;

      const response = await axios.delete(url);

      if (response.status === 200) {
        try {
          const inputData = await axios.get(url);
          return dispatch({ type: actionType.getType, payload: { data: inputData } });
        } catch (error) {
          return dispatch({ type: actionType.errorType, payload: { error } });
        }
      }
      // eslint-disable-next-line consistent-return
      return;
    } catch (error) {
      // Handle error.
      return dispatch({ type: actionType.errorType, payload: { error } });
    }
  };
};

const postInputFilePhase1 = (inputId, scenarioId, file, phase1ExcelData) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: FILE_BEGIN_PHASE_1 });
      dispatch({ type: FILE_EXCEL_LOADING_PHASE_1, payload: true });
      // check user.
      const url = `/api/scenarios/${scenarioId}/inputs/${inputId}/files/`;
      // console.log('API call:', url);
      const { data } = await axios.post(url, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({ type: FILE_POST_PHASE_1, payload: { data } });
      dispatch({ type: FILE_GET_PHASE_1, payload: { data } });
      return dispatch({ type: FILE_EXCEL_LOADING_PHASE_1, payload: false });
    } catch (error) {
      // Handle error.
      const dataFinal = { ...phase1ExcelData, is_valid: false, upload_url: '' };
      dispatch({ type: FILE_GET_PHASE_1, payload: { data: dataFinal } });
      dispatch({ type: FILE_ERROR_PHASE_1, payload: { error } });
      return dispatch({ type: FILE_EXCEL_LOADING_PHASE_1, payload: false });
    }
  };
};

const postInputFilePhase2 = (inputId, scenarioId, file, phase2ExcelData) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: FILE_BEGIN_PHASE_2 });
      dispatch({ type: FILE_EXCEL_LOADING_PHASE_2, payload: true });
      // check user.
      const url = `/api/scenarios/${scenarioId}/inputs/${inputId}/files/`;
      // console.log('API call:', url);
      const { data } = await axios.post(url, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({ type: FILE_POST_PHASE_2, payload: { data } });
      dispatch({ type: FILE_GET_PHASE_2, payload: { data } });
      return dispatch({ type: FILE_EXCEL_LOADING_PHASE_2, payload: false });
    } catch (error) {
      // Handle error.
      const dataFinal = { ...phase2ExcelData, is_valid: false, upload_url: '' };
      dispatch({ type: FILE_GET_PHASE_2, payload: { data: dataFinal } });
      dispatch({ type: FILE_ERROR_PHASE_2, payload: { error } });
      return dispatch({ type: FILE_EXCEL_LOADING_PHASE_2, payload: false });
    }
  };
};

export { getInputFilePhase1, getInputFilePhase2, deleteInputFilePhase, postInputFilePhase1, postInputFilePhase2 };
