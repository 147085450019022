/**
 Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party dependencies
import PropTypes from 'prop-types';

// local dependencies
import './WCard.scss';
import { WSpinnerMedium } from '../WLoader/WSpinner';

const WCard = ({ children, backgroundColor, disabled, loading }) => {
  return (
    <div className={`WCard ${backgroundColor}`}>
      {(disabled || loading) && <div className="Overlay" />}
      {loading && (
        <div className="Spinner">
          <WSpinnerMedium visible />
        </div>
      )}
      {children?.header && <div className="Header">{children?.header}</div>}
      <div className="Body">{children}</div>
    </div>
  );
};

export default WCard;

WCard.propTypes = {
  backgroundColor: PropTypes.oneOf(['brand', 'white', 'dark']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

WCard.defaultProps = {
  backgroundColor: 'dark',
  disabled: false,
  loading: false,
};
