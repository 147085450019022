/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import Phase1 from './phases/phase1/phase1';
import Phase2 from './phases/phase2/phase2';
import Phase3 from './phases/phase3/phase3';
import { PHASES_TRANSFORMER, PHASE_STATUSES } from '../../../constants/data';
import { getCurrentPhaseNo } from '../../../utils/utils';

const ScenarioInputs = ({ scenario }) => {
  const isReadOnly = () => {
    const readOnlyDict = {};
    // eslint-disable-next-line react/prop-types
    scenario?.inputs.forEach((inp) => {
      if (inp.status === PHASE_STATUSES.done) {
        readOnlyDict[PHASES_TRANSFORMER.toString[inp.phase_number]] = true;
      } else {
        readOnlyDict[PHASES_TRANSFORMER.toString[inp.phase_number]] = false;
      }
    });

    return readOnlyDict;
  };

  const getPhaseData = (pNumber) => scenario?.inputs.filter((si) => si.phase_number === pNumber)[0];

  return (
    <>
      <Phase1
        phase={getPhaseData(1)}
        user={scenario?.user_details}
        currentPhase={getCurrentPhaseNo(scenario)}
        readOnly={isReadOnly()?.phaseOne}
      />
      <Phase2 phase={getPhaseData(2)} currentPhase={getCurrentPhaseNo(scenario)} readOnly={isReadOnly()?.phaseTwo} />
      <Phase3 phase={getPhaseData(3)} currentPhase={getCurrentPhaseNo(scenario)} readOnly={isReadOnly()?.phaseThree} />
    </>
  );
};

export default ScenarioInputs;
