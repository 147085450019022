/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import axios from '../../utils/axios';
import {
  CLUSTER_STATUS_GET,
} from '../actionTypes';

const getClusterStatus = () => {
    // Return dispatch.
    return async (dispatch) => {
      try {
        // Begin.
  
        const url = `/api/cluster-status/`;
        // console.log('API call:', url);
        const { data } = await axios.get(url);
  
        return dispatch({ type: CLUSTER_STATUS_GET, payload: { data } });
      } catch (error) {
        // Handle error.
        console.log(error)
      }
    };
  };

  export {
    getClusterStatus
  };