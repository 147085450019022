import { USER_FUNCTIONS_BEGIN, USER_FUNCTIONS_ERROR, USER_FUNCTIONS_GET } from '../actionTypes';

const initialState = {
  userFunctions: {
    loading: false,
    data: [],
    error: null,
  },
};

// eslint-disable-next-line default-param-last
const entityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_FUNCTIONS_BEGIN:
      return {
        ...state,
        userFunctions: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case USER_FUNCTIONS_GET:
      return {
        ...state,
        userFunctions: {
          loading: false,
          data: payload.data,
          error: null,
        },
      };

    case USER_FUNCTIONS_ERROR:
      return {
        ...state,
        userFunctions: {
          loading: false,
          data: [],
          error: payload.error,
        },
      };

    default:
      return state;
  }
};

export default entityReducer;
