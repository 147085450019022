/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Add third-party dependencies.
import { configureStore } from '@reduxjs/toolkit';

// Add local dependencies.
import userReducer from './reducers/userReducer';
import scenarioReducer from './reducers/scenarioReducer';
import fileReducer from './reducers/fileReducer';
import reportReducer from './reducers/reportReducer';
import notificationReducer from './reducers/notificationReducer';
import adminReducer from './reducers/adminReducer';
import entityReducer from './reducers/entityReducer';
import clusterReducer from './reducers/clusterReducer';

// Create store.
const store = configureStore({
  reducer: {
    users: userReducer,
    scenarios: scenarioReducer,
    inputFiles: fileReducer,
    reports: reportReducer,
    notification: notificationReducer,
    admin: adminReducer,
    entities: entityReducer,
    cluster: clusterReducer
  },
});

// Export store.
export default store;
