/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// local dependencies
import { getforDuplicateScenario } from '../../redux/actions';
import DuplicateModal from '../DuplicateModal/DuplicateModal';
import { SUBPHASE_STATUSES } from '../../constants/data';
import { WSpinnerMedium } from '../WLoader/WSpinner';
import WText from '../WText/Wtext';
import Modal from '../Modal/Modal';
import './DuplicateModalFetch.scss';

// eslint-disable-next-line
const DuplicateModalFetch = ({ open, setOpen, selectedItem, refreshScenarioData = false }) => {
  // dispatch
  const dispatch = useDispatch();

  // global state
  const { duplicateLoading, duplicateData, duplicateError } = useSelector((state) => state.scenarios);

  // local state
  const [result, setResult] = useState({
    scenario: {},
    phases: [],
  });

  useEffect(() => {
    if (open) {
      dispatch(getforDuplicateScenario(selectedItem?.id));
    }
  }, [open]);

  useEffect(() => {
    if (duplicateData !== null) {
      const phases = structuredClone(duplicateData[1]);

      phases[0].data.fields = [
        ...phases[0].data.fields,
        {
          section: 'uploadExcel',
          status:
            duplicateData[2].is_valid && duplicateData[2].is_uploaded
              ? SUBPHASE_STATUSES.valid
              : SUBPHASE_STATUSES.void,
        },
      ];

      phases[1].data.fields = [
        ...phases[1].data.fields,
        {
          section: 'uploadExcel',
          status:
            duplicateData[3].is_valid && duplicateData[3].is_uploaded
              ? SUBPHASE_STATUSES.valid
              : SUBPHASE_STATUSES.void,
        },
      ];

      setResult({ scenario: duplicateData[0], phases });
    }
  }, [duplicateData]);

  return (
    <div className="duplicate-modal">
      <Modal isOpen={open} openHandler={setOpen} closeBtn dismissable>
        {/* TODO: fade */}
        {{
          body: (
            <>
              {(duplicateLoading && (
                <div className="duplicate-fetch-spinner">
                  <WSpinnerMedium />
                </div>
              )) ||
                (duplicateError && (
                  <div className="duplicate-fetch-error">
                    <WText>
                      <FormattedMessage id="scenarios.error" />
                      <button
                        type="button"
                        className="WButton ml-2 primary small"
                        onClick={() => dispatch(getforDuplicateScenario(selectedItem?.id))}
                      >
                        <FormattedMessage id="scenarios.retry" />
                      </button>
                    </WText>
                  </div>
                )) ||
                (duplicateData && (
                  <DuplicateModal
                    open={open}
                    openHandler={setOpen}
                    scenario={result?.scenario}
                    phases={result?.phases}
                    refreshScenarioData
                  />
                ))}
              ,
            </>
          ),
        }}
      </Modal>
    </div>
  );
};

export default DuplicateModalFetch;
