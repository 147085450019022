/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party dependencies
import { useIntl } from 'react-intl';

// local dependencies
import './scenarioHeader.scss';

const ScenarioHeader = () => {
  const intl = useIntl();
  return (
    <div className="scenario-header">
      <p className="name-title">{intl.formatMessage({ id: 'scenarios.header.name' })}</p>
      <p className="date-title">{intl.formatMessage({ id: 'scenarios.header.date' })}</p>
      <p className="progress-title">{intl.formatMessage({ id: 'scenarios.header.progress' })}</p>
      <p className="actions-title">{intl.formatMessage({ id: 'scenarios.header.actions' })}</p>
    </div>
  );
};

export default ScenarioHeader;
