/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party dependencies
import PropTypes from 'prop-types';
import clsx from 'clsx';

// local dependencies
import './WText.scss';

const WText = ({ children, typo, tag, capitalize, align, color, underline, italic, uppercase, size, weight }) => {
  const tagByTypo = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    body: 'p',
    title: 'h5',
    bodyLight: 'p',
    small: 'p',
    smallLight: 'p',
    caption: 'p',
    captionLight: 'p',
    overline: 'p',
  };

  const classes = clsx({ capitalize, italic, uppercase, underline });
  return (
    <div className={`WText ${tag || tagByTypo[typo]} ${typo} ${align} ${color} ${size} ${weight} ${classes}`}>
      {children}
    </div>
  );
};

export default WText;

WText.propTypes = {
  typo: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'title',
    'body',
    'bodyLight',
    'small',
    'smallLight',
    'caption',
    'captionLight',
    'overline',
  ]),
  // eslint-disable-next-line react/require-default-props
  tag: PropTypes.oneOf(['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']),
  capitalize: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right', '']),
  color: PropTypes.oneOf([
    'black',
    'danger',
    'dark',
    'gray',
    'grayDark',
    'grey01',
    'grey05',
    'grey06',
    'grey07',
    'primary',
    'primaryDark',
    'red',
    'secondary',
    'success',
    'ultralight',
    'warning',
    'white',
    'yellow',
    '',
  ]),
  underline: PropTypes.bool,
  italic: PropTypes.bool,
  uppercase: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', '']),
  weight: PropTypes.oneOf(['thin', 'light', 'normal', 'bold', '']),
};

WText.defaultProps = {
  typo: 'body',
  capitalize: false,
  align: 'left',
  color: 'white',
  underline: false,
  italic: false,
  uppercase: false,
  size: '',
  weight: '',
};
