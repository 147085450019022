/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

// local dependencies

const WorksheetTabsList = ({ worksheets, workbookID, onWorkSheetChange }) => {
  // params
  const { tableaurefname } = useParams();

  // worksheet items to render
  const items = worksheets
    ?.filter((ws) => ws.is_active === true)
    .filter((ws) => ws.workbook === parseInt(workbookID, 10))
    .map((ws) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          key={ws.name}
          className={`${clsx({
            activated: ws.tableau_ref_name === tableaurefname,
          })}`}
          onClick={() => onWorkSheetChange(ws.workbook, ws.tableau_ref_name)}
        >
          {ws.name}
        </a>
      );
    });
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{items}</>;
};

WorksheetTabsList.propTypes = {
  worksheets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tableau_ref_name: PropTypes.string,
      workbook: PropTypes.number,
      phase: PropTypes.number,
      is_active: PropTypes.bool,
    })
  ).isRequired,
  workbookID: PropTypes.string.isRequired,
  onWorkSheetChange: PropTypes.func.isRequired,
};

export default WorksheetTabsList;
