/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { every, find, isArray } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useAlert } from 'react-alert';

// local dependencies
import { PHASE_STATUSES, SUBPHASE_STATUSES } from '../../constants/data';
import StatusWarningIcon from '../../assets/icons/status-warning.svg';
import { getScenarioList, postDuplicateScenario } from '../../redux/actions';
import WCheckboxAll from '../WCheckboxAll/WCheckboxAll';
import WCheckBox from '../WCheckBox/WCheckBox';
import WText from '../WText/Wtext';
import './DuplicateModal.scss';
import { WSpinnerExtraSmall } from '../WLoader/WSpinner';
import { SCENARIO_DUPLICATE_NEW } from '../../redux/actionTypes';
import { isDatabaseExpired } from '../../utils/utils';

// eslint-disable-next-line
const DuplicateModal = ({ openHandler, scenario, phases, refreshScenarioData = false }) => {
  // intl
  const intl = useIntl();

  // alert
  const alert = useAlert();

  // local state
  const [value, setValue] = useState({});

  // global state
  const { duplicatePostLoading, duplicatePostData, duplicatePostError } = useSelector((state) => state.scenarios);

  // dispatch
  const dispatch = useDispatch();

  const defaultCheckedSubphase = (phase, subphase) => {
    if (phase) {
      const { phase_number } = phase;
      if (phase_number == 3) {
        return true
      } else if (phase_number == 2 && subphase) {
        if (subphase.section == 'preprocessParameters') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
    return false
  }

  useEffect(() => {
    if (isArray(phases)) {
      const newValues = phases?.reduce(
        (obj, phase) => ({
          ...obj,
          [phase.phase_number]: {
            ...phase.data.fields.reduce(
              (subphases, subphase) => ({
                ...subphases,
                [subphase.section]: false,
              }),
              {}
            ),
          },
        }),
        {}
      );
      setValue(newValues);
    }
  }, [phases]);

  useEffect(() => {
    if (!duplicatePostLoading && duplicatePostData != null) {
      alert.success({ text: 'Succesfully Duplicate Scenario' }, { position: 'top center' });
    } else if (duplicatePostError != null) {
      alert.error({ text: `Error in duplicating scenario` }, { position: 'top center' });
    }
  }, [duplicatePostLoading, duplicatePostData]);

  useEffect(() => {
    let timeInterval;
    if (duplicatePostData != null) {
      timeInterval = setInterval(() => {
        dispatch({ type: SCENARIO_DUPLICATE_NEW });
        openHandler(false);
      }, 1500);
    }
    return () => {
      clearInterval(timeInterval);
    };
  }, [duplicatePostData]);

  const phaseChecks = () => {
    return phases?.reduce((checks, phase) => {
      let check = 'halfChecked';
      if (!value?.[phase.phase_number] || every(Object.values(value?.[phase.phase_number]), (v) => v === false))
        check = 'unchecked';
      else if (
        every(
          phase.data.fields,
          (subphase) =>
            subphase.status === SUBPHASE_STATUSES.void ||
            (Boolean(value?.[phase.phase_number]) && value?.[phase.phase_number][subphase.section])
        ) &&
        (phase.status !== PHASE_STATUSES.done || isDatabaseExpired(scenario))
      )
        check = 'checked';
      return { ...checks, [phase.phase_number]: check };
    }, {});
  };

  const phaseDisableds = () => {
    return phases?.reduce(
      (disableds, phase) => ({
        ...disableds,
        [phase.phase_number]: every(phase.data.fields, (subphase) => subphase.status === SUBPHASE_STATUSES.void),
      }),
      {}
    );
  };

  const handleInput = (phaseNumber, subphase, inputValue) => {
    setValue({
      ...value,
      [phaseNumber]: {
        ...value[phaseNumber],
        [subphase]: inputValue,
      },
    });
  };

  const allUncheck = () => {
    return every(Object.values(phaseChecks()), (phase) => phase === 'unchecked');
  };

  const listNumbers = useMemo(() => [1, 2, 3], []);

  const handlePhaseInput = (phaseNumber, check) => {
    const dictValue = {};
    listNumbers.forEach((phase) => {
      if ((check && phase <= phaseNumber) || (!check && phase >= phaseNumber)) {
        const phaseValue = Object.keys(value[phase]).reduce((subphases, subphase) => {
          const isOutput = subphase === 'output';
          let newValue = false;
          // if (isOutput) newValue = find(this.phases, { phaseNumber: phase }).status === PHASE_STATUSES.done && !this.isDatabaseExpired ? check : false
          if (isOutput) newValue = false;
          else
            newValue =
              find(find(phases, { phase_number: phase }).data.fields, { section: subphase }).status !==
                SUBPHASE_STATUSES.void
                ? check
                : false;
          return {
            ...subphases,
            [subphase]: newValue,
          };
        }, {});
        dictValue[phase] = phaseValue;
      } else dictValue[phase] = value[phase];
    });
    setValue(dictValue);
  };

  const clickDuplicateHandler = async () => {
    const duplicateData = {
      1: {
        dates_and_parameters: value[1].datesAndParameters,
        upload_excel: value[1].uploadExcel,
      },
      2: {
        // preprocess_parameters: value[2].preprocessParameters,
        preprocess_parameters: true,
        upload_excel: value[2].uploadExcel,
      },
      3: {
        // optimization_parameters: value[3].optimizationParameters,
        optimization_parameters: true,
      },
    };

    await dispatch(postDuplicateScenario(scenario?.id, duplicateData));

    if (refreshScenarioData) await dispatch(getScenarioList('ALLSTATUS'));
  };

  return (
    <div className="duplicate-wrapper">
      <div className="duplicate-title">
        <WText typo="h3">
          <FormattedMessage id="duplicate.title" />
        </WText>
      </div>
      <div className="duplicate-subtitle">
        <WText typo="bodyLight">
          <FormattedMessage id="duplicate.subtitle" />
        </WText>
      </div>
      <hr className="duplicate-divider" />
      <div className="duplicate-phases">
        {phases?.filter(fgd => !defaultCheckedSubphase(fgd)).map((fgd) => {
          const name = `title-${fgd?.phase_number}`;
          return (
            <div className="duplicate-phase" key={name}>
              <div className="duplicate-phase-title">
                <WCheckboxAll
                  name={name}
                  label={intl.formatMessage({ id: `duplicate.phase${fgd?.phase_number}.title` })}
                  value={phaseChecks()[fgd?.phase_number]}
                  disabled={phaseDisableds()[fgd?.phase_number]}
                  checkHandler={() => handlePhaseInput(fgd?.phase_number, true)}
                  uncheckHandler={() => handlePhaseInput(fgd?.phase_number, false)}
                />
              </div>
              <div className="duplicate-phase-subtitle">
                <WText typo="bodyLight" color="grey06">
                  <FormattedMessage id={`duplicate.phase${fgd?.phase_number}.subtitle`} />
                </WText>
              </div>
              {value?.[fgd?.phase_number] && (
                <div className="duplicate-phase-subphases">
                  {fgd?.data?.fields.filter(fi => !defaultCheckedSubphase(fgd, fi)).map((fi) => {
                    return (
                      <div className="duplicate-phase" key={fi?.section}>
                        <WCheckBox
                          className="duplicate-phase-subphase"
                          value
                          phaseNumber={fgd?.phase_number}
                          section={fi.section}
                          modelValue={value?.[fgd?.phase_number][fi?.section] || false}
                          name={`title-${fi?.section}`}
                          label={intl.formatMessage({ id: `scenario.inputs.${fi?.section}.title` })}
                          disabled={fi?.status === SUBPHASE_STATUSES.void}
                          changeHandler={handleInput}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="duplicate-buttons">
        {isDatabaseExpired() ? (
          <div className="duplicate-warning">
            <img src={StatusWarningIcon} alt="status icon" />
            <WText typo="smallLight" color="grey07">
              {intl.formatMessage({ id: 'duplicate.help' })}
            </WText>
          </div>
        ) : null}
        <button type="button" className="WButton secondary small" disabled={false} onClick={() => openHandler(false)}>
          {intl.formatMessage({ id: 'duplicate.cancel' })}
        </button>
        {duplicatePostLoading ? (
          <button type="button" className="WButton primary small">
            <div className="duplicate-loading">
              <WSpinnerExtraSmall />
            </div>
            <WText typo="small" color="grey07">
              {intl.formatMessage({ id: 'duplicate.loading' })}
            </WText>
          </button>
        ) : (
          <button
            type="button"
            className={`WButton primary small ${clsx({
              disabled: allUncheck(),
            })}`}
            disabled={allUncheck()}
            onClick={clickDuplicateHandler}
          >
            {intl.formatMessage({ id: 'duplicate.duplicate' })}
          </button>
        )}
      </div>
    </div>
  );
};

export default DuplicateModal;
