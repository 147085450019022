import './WLoader.scss';
import { WSpinnerLarge } from './WSpinner';

const WLoader = () => {
  return (
    <div className="loading-background">
      <div className="loading-bar">
        <WSpinnerLarge />
      </div>
    </div>
  );
};

export default WLoader;
