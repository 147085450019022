/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */
// Add third-party dependencies.
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';

// Add local dependencies.
import store from './redux/store';
import LOCALES from './i18n/locale';
import messages from './i18n/messages';
import WNotification from './components/WNotification/WNotification';

import Site from './Site';
import SessionContextProvider from './utils/SessionContext';

const App = () => {
  const locale = LOCALES.ENGLISH;
  return (
    <Provider store={store}>
      <IntlProvider messages={flatten(messages[locale])} locale={locale} defaultLocale={LOCALES.ENGLISH}>
        <WNotification>
          <SessionContextProvider>
            <Site />
          </SessionContextProvider>
        </WNotification>
      </IntlProvider>
    </Provider>
  );
};

export default App;
