import { useSelector } from 'react-redux';
import { isNumber } from 'lodash';
import './PopoverContainer.scss';
import PopoverContainerItem from '../PopoverContainerItem/PopoverContainerItem';
import WLoader from '../../WLoader/WLoader';

const renderItems = (data) => {
  let itemComp = [];
  if (typeof data !== 'undefined') {
    itemComp = data.map((row, index) => {
      return <PopoverContainerItem row={row} key={`KEY_${index * 0.234}`} />;
    });
  }
  return itemComp;
};

const PopoverContainer = () => {
  const { loading, notificationListData, count } = useSelector((state) => state.notification);

  if (loading === true) {
    return <WLoader />;
  }

  return (
    <>
      <div className="popover-heading">
        Activity {isNumber(count.unread) && count.unread > 0 ? `(${count.unread})` : ''}
      </div>
      <div className="subheading-container">
        <div className="popover-subheading">ALL</div>
      </div>
      <div className="item-container">{renderItems(notificationListData)}</div>
    </>
  );
};

export default PopoverContainer;
