import { usePopperTooltip } from 'react-popper-tooltip';
import { useNavigate } from 'react-router-dom';
import 'react-popper-tooltip/dist/styles.css';
import './TooltipAvator.scss';
import { useDispatch } from 'react-redux';
import { useState, useContext } from 'react';
import { SessionContext } from '../../../utils/SessionContext';
import { logOut } from '../../../redux/actions';
import WText from '../../WText/Wtext';

const TooltipAvator = (props) => {
  // eslint-disable-next-line
  const { tooltipText, tooltipEmail, children } = props;
  const [tooltipmounted, setToolTipMounted] = useState(true);
  const { setRemainingSessionTime } = useContext(SessionContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeMount = () => {
    if (tooltipmounted) {
      setToolTipMounted(true);
    } else {
      setToolTipMounted(false);
    }
  };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    onVisibleChange: changeMount,
    placement: 'auto',
  });

  return (
    <div className="tooltip-avator">
      {tooltipmounted && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container',
            style: visible ? { visibility: 'visible' } : { visibility: 'hidden', pointerEvents: 'none' },
          })}
        >
          <WText>
            <span className="tooltip-name">{tooltipText}</span>
          </WText>

          <div className="tooltip-email">{tooltipEmail}</div>

          <button
            type="button"
            className="WButton small logoutbtn"
            onClick={() => {
              setRemainingSessionTime(-1);
              dispatch(logOut());
            }}
          >
            Log out
          </button>

          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
      <div ref={setTriggerRef}>{children}</div>
    </div>
  );
};

export default TooltipAvator;
