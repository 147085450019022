import Lottie from 'lottie-react';
import LoaderAnimation from '../../assets/lottie/loading.json';

const WSpinnerExtraSmall = () => {
  const style = {
    height: 50,
    width: 50,
  };

  return <Lottie animationData={LoaderAnimation} loop style={style} />;
};

const WSpinnerSmall = () => {
  const style = {
    height: 100,
    width: 100,
  };

  return <Lottie animationData={LoaderAnimation} loop style={style} />;
};

const WSpinnerMedium = () => {
  const style = {
    height: 200,
    width: 200,
  };

  return <Lottie animationData={LoaderAnimation} loop style={style} />;
};

const WSpinnerLarge = () => {
  const style = {
    height: 300,
    width: 300,
  };

  return <Lottie animationData={LoaderAnimation} loop style={style} />;
};

export { WSpinnerSmall, WSpinnerMedium, WSpinnerLarge, WSpinnerExtraSmall };
