/**
 Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// local dependencies
import './Login.scss';
import { Field, Form, Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Navigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
// import Logo from '../../../assets/images/logos/Logo-VBR.png';
import Logo from '../../../assets/images/logos/Logo-VBR-new-bg.png';
import WText from '../../../components/WText/Wtext';
import WCard from '../../../components/WCard/WCard';
import { loginUser, logOut } from '../../../redux/actions';
import { calculateRemainingSessionTime } from '../../../utils/sessionUtils';
import { SessionContext } from '../../../utils/SessionContext';

const Login = () => {
  const alert = useAlert();
  const [loginRequested, setLoginRequested] = useState(false);
  const { setRemainingSessionTime, setSessionModalOpen } = useContext(SessionContext);
  const dispatch = useDispatch();
  const { data: userData, isAuthenticated, loginError } = useSelector((state) => state.users);

  useEffect(() => {
    if (loginError) {
      if (loginError?.response.status === 401) {
        alert.error({ text: 'Invalid Credentials' }, { position: 'top center' });
      } else {
        alert.error({ text: 'Something went wrong' }, { position: 'top center' });
      }
    }
  }, [loginError]);

  useEffect(() => {
    if (isAuthenticated && loginRequested) {
      let welcomeMessage = 'Welcome!';
      if (userData?.name) {
        welcomeMessage = `Welcome ${userData?.name}!`;
      }
      alert.success({ text: welcomeMessage }, { position: 'top center' });
      const remainingSessionTime = calculateRemainingSessionTime();
      setRemainingSessionTime(remainingSessionTime);
      if (remainingSessionTime < 300) {
        setSessionModalOpen(true);
      } else {
        const sessionModalOpenTime = (remainingSessionTime - 295) * 1000;
        setTimeout(() => {
          setSessionModalOpen(true);
        }, sessionModalOpenTime);
      }
    }
  }, [isAuthenticated, loginRequested]);

  if (isAuthenticated) return <Navigate to="/" />;

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('is required'),
    password: Yup.string().required('is required'),
  });

  return (
    <div className="center-content">
      <WCard backgroundColor="dark">
        <div className="Logo-login-page">
          <img src={Logo} alt="logo" />
        </div>
        <div className="mt-2">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
              // eslint-disable-next-line react/prop-types
              dispatch(loginUser(values.email, values.password));
              setLoginRequested(true);
            }}
            validateOnChange
          >
            {({ errors, touched, values }) => (
              <Form>
                <div className="login-box">
                  <div className="login-body">
                    <div
                      className={`login-input ${clsx({
                        error: touched.email && errors.email,
                      })}`}
                    >
                      <label htmlFor="email" className="input-label">
                        Email
                      </label>
                      <Field name="email" value={values.email} type="email" autoComplete="false" />
                      {touched.email && errors.email && (
                        <div className="message">
                          <WText typo="caption">{errors.email}</WText>
                        </div>
                      )}
                    </div>
                    <div
                      className={`login-input ${clsx({
                        error: touched.password && errors.password,
                      })}`}
                    >
                      <label htmlFor="password" className="input-label">
                        Password
                      </label>
                      <Field name="password" value={values.password} type="password" autoComplete="false" />
                      {touched.password && errors.password && (
                        <div className="message">
                          <WText typo="caption">{errors.password}</WText>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="login-btn-container">
                    <button type="submit" className="WButton primary login-btn">
                      Sign In
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </WCard>
    </div>
  );
};

export default Login;
