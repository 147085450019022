import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line } from 'recharts';
import { formatNumber, convertToTitleCase } from '../../utils/utils';
import '../../screens/ReportCharts/Phase3/Optimization/Detail.scss';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '10px', borderRadius: '5px' }}>
        <table>
          <tbody>
            {Object.keys(data).map((key) => (
              <tr key={key}>
                <td>
                  <strong>{convertToTitleCase(key)}:</strong>
                </td>
                <td>{typeof data[key] === 'number' ? formatNumber(data[key]) : data[key]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

const LineChartComponent = ({ data, title }) => {
  return (
    <>
      <h6>{title}</h6>
      <LineChart
        width={1200}
        height={580}
        data={data}
        margin={{
          top: 60,
          right: 110,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#333" />
        <XAxis
          dataKey="actionCost"
          stroke="#ccc"
          label={{ value: 'Accumulated Capex', position: 'insideBottom', offset: -10, fill: '#ccc' }}
          tickFormatter={formatNumber}
          interval={50}
        />
        <YAxis
          stroke="#ccc"
          label={{ value: 'Revenue Generated', angle: -90, offset: -10, position: 'insideLeft', fill: '#ccc' }}
          tickFormatter={formatNumber}
        />
        <Tooltip
          content={<CustomTooltip />}
          //  formatter={(value) => formatNumber(value)}
        />
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="revenueGenerated"
          stroke="#82ca9d"
          // dot={{ stroke: '#82ca9d', strokeWidth: 2, fill: '#82ca9d' }}
          dot={false}
          tension={0.1}
          // activeDot={{ r: 8 }}
        />
      </LineChart>
    </>
  );
};

export default LineChartComponent;
