/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useContext, useState, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isEmpty, isEqual, isNumber } from 'lodash';
import PropTypes from 'prop-types';

// local dependencies
import ValidationModal from '../../../../../components/ValidationModal/ValidationModal';
import {
  getInputFilePhase2,
  getScenario,
  postInputFilePhase2,
  updateScenarioInput,
} from '../../../../../redux/actions';
import SubPhaseHeader from '../../../../../components/SubPhaseHeader/SubPhaseHeader';
import { PHASE_STATUSES, STATUSES, SUBPHASE_STATUSES } from '../../../../../constants/data';
import PhaseItem from '../../../../../components/PhaseItem/PhaseItem';
import ScenarioContext from '../../../ScenarioContext';
import WText from '../../../../../components/WText/Wtext';
import UploadExcel from '../forms/UploadExcel';
import { updateScenarioContext } from '../../AutoSubmit';
import './phase2.scss';
import { disablePhaseInputs, isValNumeric, numericOnlyCheck, prepareServerURL } from '../../../../../utils/utils';
import { useAlert } from 'react-alert';
// eslint-disable-next-line no-unused-vars
const Phase2 = ({ phase, currentPhase, readOnly }) => {
  // local state
  const [iconState, setIconState] = useState('INITIAL');
  const [modalOpen, setModalOpen] = useState(false);
  const alert = useAlert();
  // dispatch
  const dispatch = useDispatch();

  // redux state
  const { loadingPhase2, phase2ExcelData, errorPhase2, loadingPhase2Excel } = useSelector((state) => state.inputFiles);
  const { data: scenario } = useSelector((state) => state.scenarios);

  // context
  const scenarioContext = useContext(ScenarioContext);

  // eslint-disable-next-line react/prop-types

  const pData = phase && phase?.data.fields[0];

  useEffect(() => {
    if (!isEmpty(errorPhase2) && errorPhase2 !== null) {
      switch (errorPhase2?.response?.status) {
        case 200:
        case 422:
          setModalOpen(true);
          break;
        case (500):
          alert.error({ text: `Unable to upload the file, Please contact admin` }, { position: 'top center' });
          break;
        default:
          setModalOpen(false);
          break;
      }
    }
  }, [errorPhase2]);

  useEffect(() => {
    if (isValNumeric(phase2ExcelData?.id) && !phase2ExcelData?.is_valid) {
      setIconState('ERROR');
    } else if (isNumber(phase2ExcelData.id) && phase2ExcelData.id > 0) {
      if (errorPhase2 && phase2ExcelData?.errors) {
        setIconState('ERROR');
      } else if (!phase2ExcelData?.is_valid && phase2ExcelData?.is_uploaded) {
        setIconState('ERROR');
      } else if (phase2ExcelData?.is_valid && phase2ExcelData?.is_uploaded) {
        setIconState('SUCCESS');
      } else if (loadingPhase2) {
        setIconState('LOADING');
      }
    } else {
      setIconState('INITIAL');
    }
  }, [
    loadingPhase2,
    phase2ExcelData?.id,
    phase2ExcelData?.is_uploaded,
    phase2ExcelData?.is_valid,
    phase2ExcelData?.errors,
  ]);

  useEffect(() => {
    if (!isEmpty(phase) && phase !== undefined && phase !== null) {
      dispatch(getInputFilePhase2(phase.id, phase?.scenario_id));
    }
  }, [
    phase2ExcelData?.upload_url,
    phase2ExcelData?.local_path,
    phase2ExcelData?.is_valid,
    phase2ExcelData?.is_uploaded,
  ]);

  const initialValues = {
    partitionLevel: pData?.value?.partitionLevel || 1,
  };

  const validationSchema = Yup.object().shape({
    partitionLevel: Yup.number()
      .required('is required')
      .typeError('has to be a number')
      .test('Numeric only', 'has to be a number', (value) => numericOnlyCheck(value))
      .test('one of required', 'Either 0 or 1 is expected', (item) => item === 0 || item === 1),
  });

  const handleUpload = async (acceptedFiles) => {
    const postData = new FormData();
    postData.append('local_path', acceptedFiles[0]);
    // eslint-disable-next-line react/prop-types
    await dispatch(postInputFilePhase2(phase.id, phase?.scenario_id, postData, phase2ExcelData));
    await dispatch(getScenario(phase?.scenario_id, false));
  };

  const phase2InputIsDisabled = () => {
    if (scenarioContext.edit) return disablePhaseInputs(phase, scenario);
    return true;
  };

  return (
    <PhaseItem title="Phase 2" id="phase2">
      <div className="mt-2" title="Preprocess Parameter" id="preprocessParameters" style={{ display: "none" }}>
        <SubPhaseHeader title="Preprocess Parameter" index={1} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange
          initialTouched={false}
          onSubmit={(values) => {
            const phase2Data = {
              ...phase,
              status: PHASE_STATUSES.valid,
              data: JSON.stringify({
                fields: [
                  {
                    ...pData,
                    status: SUBPHASE_STATUSES.valid,
                    value: values,
                  },
                ],
              }),
            };
            dispatch(updateScenarioInput(phase?.id, phase?.scenario_id, phase2Data));
            dispatch(getScenario(phase?.scenario_id));
          }}
        >
          {({ errors, touched, isValid, values, submitForm, setFieldTouched, handleChange }) => (
            <Form>
              <div style={{ display: 'flex' }}>
                <div className="phase2-body">
                  <div
                    className={`date-input phase2-input ${clsx({
                      error: touched.partitionLevel && errors.partitionLevel,
                    })}`}
                  >
                    <label htmlFor="partitionLevel" className="input-label">
                      Partition Level
                    </label>
                    <Field
                      name="partitionLevel"
                      type="text"
                      placeholder="0"
                      className={phase2InputIsDisabled() ? 'scenario-disable-cursor' : ''}
                      autoComplete="false"
                      disabled={phase2InputIsDisabled()}
                      onChange={(e) => {
                        setFieldTouched(e.target.name);
                        handleChange(e);
                      }}
                    />
                    {touched.partitionLevel && errors.partitionLevel && (
                      <div className="message">
                        <WText typo="caption">{errors.partitionLevel}</WText>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                ref={scenarioContext.phaseSubmitBtnref.phase2}
                type="button"
                className="scenarion-submit-button-hide"
                onClick={() => {
                  if (isEqual(initialValues, values) || !isEqual(initialValues, values)) {
                    updateScenarioContext(2, isValid, touched, scenarioContext);
                    if (isValid && (isEmpty(touched) || !isEmpty(touched))) submitForm();
                  }
                }}
              >
                &nbsp;
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <UploadExcel
        id="uploadExcelPhase2"
        disabled={phase2InputIsDisabled()}
        index={2}
        templNum={2}
        value={phase2ExcelData && phase2ExcelData?.local_path?.split('/input_files/')[1]}
        errors={errorPhase2}
        handleUpload={handleUpload}
        downloadLink={prepareServerURL(scenarioContext?.phase2ExcelData?.upload_url)}
        uploadState={iconState}
        fileLoader={loadingPhase2Excel}
        phase={2}
      />
      <ValidationModal
        isOpen={modalOpen}
        openHandler={setModalOpen}
        excelErrors={errorPhase2?.response?.data}
        name="phase-02-template-error.csv"
      />
    </PhaseItem>
  );
};

Phase2.propTypes = {
  phase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    file_id: PropTypes.number,
    scenario_id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    phase_number: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    status: PropTypes.oneOf(Object.values(PHASE_STATUSES)).isRequired,
    // eslint-disable-next-line react/require-default-props
    data: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        section: PropTypes.string,
        status: PropTypes.oneOf(Object.values(PHASE_STATUSES)),
        value: PropTypes.shape({
          coverageQualityThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          minimumMicrosegmentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          percentageOfClients: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          qualityConsistencyThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          targetMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          trafficTailSitesNonConsidered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          userName: PropTypes.string,
          userRole: PropTypes.string,
          kmeasnModelScenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      })),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // eslint-disable-next-line react/no-unused-prop-types
    created_at: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    updated_at: PropTypes.string,
  }).isRequired,
  currentPhase: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default Phase2;
