/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';

// local dependencies
import WText from '../WText/Wtext';
import './PhaseItem.scss';

const PhaseItem = ({ title, id, children, display = true }) => {
  return (
    <div className="phaseitem" id={id} style={display ? {} : { display: "none" }}>
      <div className="phase-item-header">
        <WText typo="h4">{title}</WText>
      </div>
      {children}
    </div>
  );
};

export default PhaseItem;

PhaseItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
