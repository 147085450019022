// third dependencies
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// local dependencies
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// 3rd party css libraries
import 'react-popper-tooltip/dist/styles.css';
import 'font-awesome/css/font-awesome.min.css';
import 'primereact/resources/themes/lara-dark-blue/theme.css';
import 'leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
