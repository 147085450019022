/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies

const PhaseSkeleton = () => {
  return (
    <div className="subnav-skeleton">
      <div className="subnavbtn-skeleton" />
      <div className="subnavbtn-skeleton" />
      <div className="subnavbtn-skeleton" />
      <div className="subnavbtn-skeleton" />
      <div className="subnavbtn-skeleton" />
    </div>
  );
};

export default PhaseSkeleton;
