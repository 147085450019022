/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './WIcon.scss';

const WIcon = ({ icon, color, size, clickable, disabled, marRight }) => {
  const getStyles = size && { fontSize: `${size}px` };
  const getMarStyle = marRight && { marginRight: '6px' };
  const classes = clsx({ clickable, disabled });

  return <i className={`icon-${icon} WIcon ${color} ${classes}`} style={{ ...getStyles, ...getMarStyle }} />;
};

export default WIcon;

WIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'dark',
    'grayMedium',
    'black',
    'greenDark',
    'warning',
    'danger',
    'grey06',
  ]),
  size: PropTypes.number,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  marRight: PropTypes.bool,
};

WIcon.defaultProps = {
  color: 'white',
  size: 0,
  clickable: false,
  disabled: false,
  marRight: false,
};
