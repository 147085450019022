import { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import BarChartComponent from '../../../components/Chart/BarChartComponent';
import axios from '../../../utils/axios';
import Filters from '../../../components/Filters/Filters';
import { WSpinnerLarge } from '../../../components/WLoader/WSpinner';
import { areObjectsEqual, convertToTitleCase, formatNumber } from '../../../utils/utils';
import '../Phase3/Optimization/Summary.scss';

const QualityImpact = ({ scenarioID }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);

  const handleFilterChange = (key, type, selectedValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        type,
        values: selectedValues,
      },
    }));
  };

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/phase1-qualityimpact/';
    axios
      .post(url, filters, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setFilterData(response.data.filters);
        Object.keys(response.data.filters).forEach((key) => {
          const type = response.data.filters[key].type;
          const values = response.data.filters[key].values;
          handleFilterChange(key, type, values);
        });
        setLoading(false);
      });
  }, []);

  const applyFilters = () => {
    setLoading(true);
    const url = '/api/charts/phase1-qualityimpact/';
    const filtersBody = areObjectsEqual(filters, filterData) ? {} : filters;
    axios
      .post(url, filtersBody, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        // setFilterData(response.data.filters);
        setLoading(false);
      });
  };

  return (
    <div className="container1">
      {/* {isOpen && <FiltersModal isOpen={isOpen} openHandler={openHandler} filterData={filterData} />} */}
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0 0 10px' }}>
            <Filters
              data={filterData}
              filters={filters}
              handleFilterChange={handleFilterChange}
              applyFilters={applyFilters}
            />
          </div>
          <div style={{ height: '75vh', overflowY: 'scroll' }}>
            <div className="p-grid p-nogutter stats-container">
              <div className="p-col-12 p-md-6 cards">
                {Object.keys(data.stats[0]).map((key) => (
                  <Card className="custom-card">
                    <div className="card-content">
                      <h2>{convertToTitleCase(key)}</h2>
                      <h3>{formatNumber(data.stats[0][key])}</h3>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {data.chart_data.map((item, index) => (
                <div
                  style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', width: 'calc(50% - 40px)' }}
                >
                  <div>
                    <BarChartComponent phase={2} data={item} index={index} stacked={false} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QualityImpact;
