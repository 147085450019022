/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

import PropTypes from 'prop-types';
import Lottie from 'lottie-react';

const LottieStateManager = ({ file, width, height, start, stop }) => {
  const style = {
    height,
    width,
  };

  const interactivity = {
    mode: 'cursor',
    actions: [
      {
        position: { x: [0, 1], y: [0, 1] },
        type: 'loop',
        frames: start === undefined ? [0, 0] : start,
      },
      {
        position: { x: -1, y: -1 },
        type: 'stop',
        frames: stop,
      },
    ],
  };

  return <Lottie animationData={file} loop={false} autoplay={false} style={style} interactivity={interactivity} />;
};

export default LottieStateManager;

LottieStateManager.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  start: PropTypes.arrayOf(PropTypes.number),
  stop: PropTypes.arrayOf(PropTypes.number).isRequired,
};
