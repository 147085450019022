export default {
  selectAll: 'Select All',
  next: 'Next',
  discard: 'Discard',
  close: 'Close',
  back: 'Back',
  cancel: 'Cancel',
  search: 'Search',
  apply: 'Apply',
  confirm: 'Confirm',
  viewAll: 'View all',
  viewLess: 'View less',
  show: 'Show all',
  hide: 'Hide',
  logout: 'Log out',
};
