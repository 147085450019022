/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useState } from 'react';
// import { Routes, Route } from "react-router-dom";

// local dependencies
import './layout.scss';
import NavBar from '../Navbar/navbar';
import SideBar from '../Sidebar/sidebar';
import ScenarioCreate from '../ScenarioCreate/ScenarioCreate';
import Footer from '../Footer/Footer';

// eslint-disable-next-line
const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ScenarioCreate isOpen={isOpen} setIsOpen={setIsOpen} />
      <NavBar />
      <div className="main-content">
        <SideBar setIsOpen={setIsOpen} />
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
