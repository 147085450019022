/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party dependencies
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';

// local dependencies
import './scenario.scss';
import WText from '../WText/Wtext';
import WIcon from '../WIcon/WIcon';
import WAvatar from '../WAvatar/WAvatar';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { DB_STATUSES, PHASE_STATUSES, STATUSES_OBJ } from '../../constants/data';
import ScenarioHeader from '../ScenarioHeader/scenarioHeader';
import ScenarioProgress from './components/ScenarioProgress/ScenarioProgress';
// eslint-disable-next-line import/named
import {
  pinScenario,
  unpinScenario,
  updateScenario,
  launchScenario,
  pauseScenario,
  refreshScenarioData,
  deleteScenario
} from '../../redux/actions';
import PopOverDuplicateWrapper, { PopOverStopWrapper } from '../PopoverConfirm/PopoverConfirm';
import DuplicateModalFetch from '../DuplicateModalFetch/DuplicateModalFetch';
import ViewAll from '../ViewAll/ViewAll';
import SkeletonWrapper from '../SkeletonWrapper/SkeletonWrapper';
import TooltipWrapper from '../Tooltip/TooltipWrapper/TooltipWrapper';

import {
  SCENARIO_UPDATE_MSG_RESET,
  SCENARIO_PIN_CHECK_RESET,
  SCENARIO_LAUNCH_CHECK_RESET,
  SCENARIO_PAUSE_CHECK_RESET,
  SCENARIO_DELETE
} from '../../redux/actionTypes';
import { enableLaunchButton, enableSaveButton, isDatabaseExpired, prepareServerURL } from '../../utils/utils';
import usePrevious from '../../utils/usePrevious';
import { scenarioActionButton } from '../../utils/scenarioActions';

const Scenario = ({ header, listItem, listNo, viewMore, linkTo, isWorkspace, showClusterRunningStatus}) => {
  // local state
  const [mounted, setMounted] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [scenariosLoaded, setScenariosLoaded] = useState(false);
  const { loading } = useSelector((state) => state.scenarios);
  const { data: authUser, authCheckComplete } = useSelector((state) => state.users);

  const userRole = authCheckComplete ? authUser?.role?.name : null

  // i18n
  const intl = useIntl();

  // alert
  const alert = useAlert();

  // navigate
  const navigate = useNavigate();

  // dispatch
  const dispatch = useDispatch();

  // ref
  const popref = useRef();

  const { error, scenarioUpdateMsg, scenarioPinCheck, scenarioLaunchCheck, scenarioPauseCheck } = useSelector(
    (state) => state.scenarios
  );

  const previousSecnarioPauseCheck = usePrevious(scenarioPauseCheck)

  useEffect(() => {
    if (scenarioUpdateMsg != null && !isWorkspace) {
      if (scenarioUpdateMsg.response.status === 200) {
        alert.success({ text: scenarioUpdateMsg.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_UPDATE_MSG_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioUpdateMsg]);

  useEffect(() => {
    if (error != null && !isWorkspace) {
      alert.error({ text: [error.response.data[Object.keys(error.response.data)[0]]][0] }, { position: 'top center' });
    }
  }, [error]);

  useEffect(() => {
    if (scenarioPinCheck != null && !isWorkspace) {
      if (scenarioPinCheck.response.status === 200) {
        alert.success({ text: scenarioPinCheck.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_PIN_CHECK_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioPinCheck]);

  useEffect(() => {
    if (scenarioLaunchCheck != null && header == intl.formatMessage({ id: 'workspace.running' })) {
      if (scenarioLaunchCheck.response.status === 200) {
        alert.success({ text: scenarioLaunchCheck.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_LAUNCH_CHECK_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioLaunchCheck]);

  useEffect(() => {
    if (scenarioPauseCheck != null && scenarioPauseCheck != previousSecnarioPauseCheck && header == intl.formatMessage({ id: 'workspace.pending' })) {
      if (scenarioPauseCheck.response.status === 200) {
        alert.success({ text: scenarioPauseCheck.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_PAUSE_CHECK_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioPauseCheck]);

  const date = (updatedAt) => {
    return moment(updatedAt, 'YYYY-MM-DD HH:mm').format('YYYY/MM/DD');
  };

  const time = (updatedAt) => {
    return moment(updatedAt, 'YYYY-MM-DD HH:mm').format('HH:mm');
  };

  const isRunning = (item) => {
    return item?.status === STATUSES_OBJ.running;
  };

  const canArchive = (item) => {
    return (
      // checks scenario status is not running
      item?.status !== STATUSES_OBJ.running &&
      // checks scenario status is not queued
      item?.status !== STATUSES_OBJ.queued &&
      // checks scenario is not archived
      !item?.archived
    );
  };

  const canUnarchive = (item) => {
    return (
      // checks scenario is archived
      item?.archived
    );
  };

  const canEdit = (item) => {
    return !isRunning(item) && enableSaveButton(item) && !item?.archived;
  };

  const canLaunch = (item) => {
    return enableLaunchButton(item) && !isDatabaseExpired(item) && !item?.archived;
  };

  const canPause = (item) => {
    return (
      // checks the scenario status is running
      item?.status === STATUSES_OBJ.running ||
      // checks the scenario status is queued
      item?.status === STATUSES_OBJ.queued
    );
  };

  const canViewReport = (item) => {
    return item?.inputs.some((ip) => ip.status === PHASE_STATUSES.done);
  };

  const onClickView = (id) => {
    navigate(`/scenario/${id}`, { replace: false, state: { edit: false } });
  };

  const onClickEdit = (id) => {
    navigate(`/scenario/${id}/edit/`, { replace: false, state: { edit: true } });
  };

  const clickPinHandler = (scenario) => {
    const id = scenario?.id;
    const pinned = scenario?.pinned;
    if (pinned) {
      dispatch(unpinScenario(id));
    } else {
      dispatch(pinScenario(id));
    }
    dispatch(refreshScenarioData(scenario, pinned, null, null));
  };

  const clickArchiveHandler = (scenario) => {
    const id = scenario?.id;
    const archived = scenario?.archived;
    if (archived) {
      dispatch(updateScenario(id, { archived: false }));
    } else {
      dispatch(updateScenario(id, { archived: true }));
    }
    dispatch(refreshScenarioData(scenario, null, archived, null));
  };

  const clickReporthandler = (item) => {
    const currentPhaseID = item?.inputs
      .filter((ip) => ip.status === PHASE_STATUSES.done)
      .sort((a, b) => b.phase_number - a.phase_number)[0]?.phase_number;
    navigate(`/reports/list/${item?.id}/phase/${currentPhaseID}`);
  };

  const clickLaunchHandler = (id) => {
    dispatch(launchScenario({ id }));
    // window.location.reload();
  };

  const deleteScenarios = (id) =>{
    dispatch(deleteScenario(id));
  }

  const clickLaunchAllhandler = (id) => {
    dispatch(launchScenario({ id, allPhases: true }));
  }

  const clickPauseHandler = (id) => {
    dispatch(pauseScenario(id));
    // window.location.reload();
  };

  const hasError = (item) => {
    return item?.inputs.map((ip) => ip.status === PHASE_STATUSES.invalid);
  };

  const hasWarning = (item) => {
    return item?.etl_data_status === DB_STATUSES.expired;
  };

  const clickOpenDialogHandler = (item) => {
    setMounted(true);
    setSelectedItem(item);
  };

  useEffect(() => {
    if (listItem && !scenariosLoaded) {
      setScenariosLoaded(true);
    }
  }, [listItem]);

  const ScenarioItems = listItem.slice(0, listNo).map((item) => {
    return (
      <div className={`scenario ${clsx({ running: isRunning(item) })}`} key={item?.id}>
        <TooltipWrapper tooltipText={intl.formatMessage({ id: 'scenario.notifications.outdatedDatabase' })}>
          {hasWarning() && (
            <div className="error-notification-icon">
              <WIcon icon="warning-fill" color="warning" />{' '}
            </div>
          )}
        </TooltipWrapper>

        <TooltipWrapper tooltipText={intl.formatMessage({ id: `scenario.notifications.error` })}>
          {hasError() && (
            <div className="error-notification-icon">
              <WIcon icon="error-fill" color="danger" />
            </div>
          )}
        </TooltipWrapper>

        <TooltipWrapper tooltipText={intl.formatMessage({ id: `scenario.actions.${item?.pinned ? 'unpin' : 'pin'}` })}>
          <ButtonIcon
            key="pin"
            small
            lottie
            icon="pin"
            active={item?.pinned}
            clickHandler={() => clickPinHandler(item)}
          />
        </TooltipWrapper>

        <TooltipWrapper tooltipText={item?.user_details?.name}>
          {item && (
            <WAvatar
              name={item?.user_details?.name}
              pic={prepareServerURL(item?.user_details?.photo_url)}
              clickable={false}
              medium
            />
          )}
        </TooltipWrapper>

        <div className="title-section">
          <WText typo="body">
            <Link className="special-link" to={`/scenario/${item?.id}`} state={{ edit: false }}>
              {item?.name}
            </Link>
          </WText>
          <WText color="gray" typo="smallLight">
            {item?.description}
          </WText>
        </div>
        <div className="date-section">
          <WText typo="small" color={hasWarning() ? 'warning' : undefined}>
            {date(item?.updated_at)}
          </WText>
          <WText typo="smallLight" color="gray">
            {time(item?.updated_at)}
          </WText>
        </div>
        <div className="default-scenario-progress">
          <ScenarioProgress inputs={item?.inputs} item={item} isRunning={isRunning(item)} />
        </div>
        <PopOverDuplicateWrapper
          title={intl.formatMessage({ id: 'scenario.popovers.copy.title' })}
          content={intl.formatMessage({ id: 'scenario.popovers.copy.text' })}
          placement="bottom"
          setOpen={() => clickOpenDialogHandler(item)}
        >
          <ButtonIcon
            ref={popref}
            key="duplicate"
            icon="duplicate"
            disabled={!scenarioActionButton.duplicate.includes(userRole)}
            lottie
            text={intl.formatMessage({ id: 'scenario.actions.copy' })}
          />
        </PopOverDuplicateWrapper>
        {item?.archived ? (
          <ButtonIcon
            lottie
            key="unarchive"
            icon="unarchive"
            text="unarchive"
            disabled={!canUnarchive(item) || !scenarioActionButton.unarchive.includes(userRole)}
            clickHandler={() => clickArchiveHandler(item)}
          />
        ) : (
          <ButtonIcon
            lottie
            key="archive"
            icon="archive"
            text="archive"
            disabled={!canArchive(item) || !scenarioActionButton.archive.includes(userRole)}
            clickHandler={() => clickArchiveHandler(item)}
          />
        )}
        <ButtonIcon
          icon="edit"
          key="edit"
          lottie
          text={intl.formatMessage({ id: 'scenario.actions.edit' })}
          disabled={!canEdit(item) || !scenarioActionButton.edit.includes(userRole)}
          clickHandler={() => onClickEdit(item?.id)}
        />
        <ButtonIcon
          icon="view"
          key="view"
          lottie
          text="View"
          disabled={!scenarioActionButton.view.includes(userRole)}
          id={item?.id}
          clickHandler={() => onClickView(item?.id)}
        />
        {!canPause(item) ? (
          <></>
          // <ButtonIcon
          //   icon="launch"
          //   key="launch"
          //   lottie
          //   text={intl.formatMessage({ id: 'scenario.actions.launch' })}
          //   disabled={!canLaunch(item) || !canEdit(item)}
          //   clickHandler={() => clickLaunchHandler(item?.id)}
          // />
        ) : (
          <PopOverStopWrapper
            title={intl.formatMessage({ id: 'scenario.popovers.stop.title' })}
            content={intl.formatMessage({ id: 'scenario.popovers.stop.text' })}
            setOpen={() => clickPauseHandler(item?.id)}
          >
            <ButtonIcon
              lottie
              icon={isRunning(item) ? 'stop' : 'cancel'}
              key="stop"
              disabled={!scenarioActionButton.run.includes(userRole)}
              text={isRunning(item) ? 'stop' : 'cancel'}
            // clickHandler={() => clickPauseHandler(item?.id)}
            />
          </PopOverStopWrapper>
        )}
        {!canPause(item) ? (
          <ButtonIcon
            icon="play"
            lottie
            text={intl.formatMessage({ id: 'scenario.actions.launchAll' })}
            clickHandler={() => clickLaunchAllhandler(item?.id)}
            disabled={!canLaunch(item) || !canEdit(item) || !scenarioActionButton.run.includes(userRole)}
          />
        ) : null}
        {/* <ButtonIcon
          icon={!canViewReport(item) ? 'view-less' : 'reports'}
          lottie
          text={intl.formatMessage({ id: 'scenario.actions.reports' })}
          clickHandler={() => clickReporthandler(item)}
          disabled={!canViewReport(item)}
        /> */}
        <ButtonIcon
            icon="delete"
            key="delete"
            lottie
            text={intl.formatMessage({ id: 'scenario.actions.delete' })}
            disabled={!scenarioActionButton.delete.includes(userRole)}
            clickHandler={() => deleteScenarios(item?.id)}
          />
      </div>
    );
  });

  return (
    <div>
      <div className="header">
        <div style={{ marginRight: '4px', display: 'inline-flex' }}>
          <WText typo="h5">{header}</WText>
        </div>
        <div style={{ display: 'inline-flex' }}>
          <WText typo="small" color="gray">
            {`(${listItem?.length > listNo ? listNo : listItem?.length})`}
          </WText>&nbsp;{showClusterRunningStatus??""}
        </div>
      </div>
      <div className="scenario-container">
        <ScenarioHeader />
        {(loading && !scenariosLoaded && <SkeletonWrapper expanded={false} skeletonNumber={15} />) ||
          (listItem.length === 0 && (
            <div className="empty-box">{intl.formatMessage({ id: 'scenarios.emptyList' })}</div>
          )) ||
          ScenarioItems}
      </div>
      {viewMore && listItem.length > listNo && <ViewAll opened={false} link={linkTo} />}
      <DuplicateModalFetch open={mounted} setOpen={setMounted} selectedItem={selectedItem} refreshScenarioData />
    </div>
  );
};

Scenario.propTypes = {
  header: PropTypes.string.isRequired,
  listItem: PropTypes.instanceOf(Array).isRequired,
  listNo: PropTypes.number,
  viewMore: PropTypes.bool,
  linkTo: PropTypes.string,
  isWorkspace: PropTypes.bool,
  isShowLoader: PropTypes.bool, // eslint-disable-line
};

Scenario.defaultProps = {
  listNo: 15,
  viewMore: false,
  linkTo: '',
  isWorkspace: false,
  isShowLoader: false,
};

export default Scenario;
