import {
  NOTIFICATION_ERROR,
  NOTIFICATION_BEGIN,
  NOTIFICATION_GET,
  NOTIFICATION_POST,
  NOTIFICATION_COUNT_GET,
  NOTIFICATION_READ_POST,
} from '../actionTypes';

const initialState = {
  loading: false,
  error: {},
  notificationListData: [],
  count: { read: 0, unread: 0 },
};

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATION_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_GET:
      return {
        ...state,
        loading: false,
        notificationListData: payload.data,
      };

    case NOTIFICATION_POST:
      return {
        ...state,
        loading: false,
        notificationListData: payload.data,
      };

    case NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case NOTIFICATION_COUNT_GET:
      return {
        ...state,
        loading: false,
        count: payload.data,
      };

    case NOTIFICATION_READ_POST:
      return {
        ...state,
        count: payload.data,
      };
    default:
      return state;
  }
};

export default notificationReducer;
