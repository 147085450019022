/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party
import { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// local dependencies
import './workspace.scss';
import { useAlert } from 'react-alert';
import { getScenarioList,getClusterStatus,getAllScenariosStatus, setScenarioCheckIntervalValue ,setClusterStatusCheckIntervalValue} from '../../redux/actions';
import Scenario from '../../components/Scenario/scenario';
import { SCENARIO_PIN_CHECK_RESET, SCENARIO_UPDATE_MSG_RESET } from '../../redux/actionTypes';
import TooltipWrapper from '../../../src/components/Tooltip/TooltipWrapper/TooltipWrapper';
import StatusErrorIcon from '../../../src/assets/icons/status-error.svg';

const Workspace = () => {
  // i18n
  const intl = useIntl();
  // Redux hooks.
  const dispatch = useDispatch();
  // alert
  const alert = useAlert();
  const { data: userData,isAuthenticated } = useSelector((state) => state.users);
  const { loadCounter, running, queued, pending, completed, scenarioCheckIntervalValue, clusterStatusCheckIntervalValue } = useSelector((state) => state.scenarios);
  const { error, scenarioUpdateMsg, scenarioPinCheck } = useSelector((state) => state.scenarios);
  const { isClusterRunning } = useSelector((state) => state.cluster);
  const { loading, launch, pause } = useSelector((state) => {
    return state.scenarios
  });

function getList() {
    // dispatch(getScenarioList('RUNNING', false, false));
    // dispatch(getScenarioList('QUEUED', false, false));
    // dispatch(getScenarioList('IDLE', false, false));
    // dispatch(getScenarioList('DONE'));
    dispatch(getAllScenariosStatus());
  }

  useEffect(() => {
    let inter
    if(isAuthenticated){
    document.title = 'Workspace | NEXO by BCG';
    // dispatch(getClusterStatus())
    getList();
     inter = setInterval(() => {
      getList();
      // console.log('Load counter', loadCounter, loadCounter === 0);
    }, 1800000);
  }
    return () => clearInterval(inter);
  }, [isAuthenticated]);

  useEffect(() => {
    if(!isClusterRunning){
      let clusterStatusinterval = setInterval(() => {
        // dispatch(getClusterStatus())
      }, 25000)
      clearInterval(clusterStatusCheckIntervalValue);
      dispatch(setClusterStatusCheckIntervalValue(clusterStatusinterval))
    }
    else if(isClusterRunning && clusterStatusCheckIntervalValue){
      getList();
      dispatch(getScenarioList('RUNNING', false, false));
      clearInterval(clusterStatusCheckIntervalValue)
      dispatch(setClusterStatusCheckIntervalValue(null))
    }
  }, [isClusterRunning]);
  

  useEffect(() => {
    if(pause){
      clearInterval(scenarioCheckIntervalValue);
      dispatch(setScenarioCheckIntervalValue(null));
      dispatch(getScenarioList('RUNNING', false, false));
    }
    
    if(launch || loading || pause || error){
        getList();
    }
  }, [launch,loading, pause, error])

  useEffect(() => {
    //This useEffect will clear the interval after the scenario is no longer in running state
    //scenarioCheckIntervalValue is set when user clicks on run scenario or if there is a scenario already running on page load
    if(running.length===0 && scenarioCheckIntervalValue){
      clearInterval(scenarioCheckIntervalValue);
      dispatch(setScenarioCheckIntervalValue(null));
      getList();
      dispatch(getScenarioList('RUNNING', false, false));
    }
  }, [running])

  useEffect(() => {
   let interval;
    if( launch && !scenarioCheckIntervalValue){
      getList();
      dispatch(getScenarioList('RUNNING', false, false));
      interval = setInterval(() => {
      dispatch(getScenarioList('RUNNING', false, false));
      getList();
    }, 7000)
    clearInterval(scenarioCheckIntervalValue);
    dispatch(setScenarioCheckIntervalValue(interval));
  }
  }, [launch])

  useEffect(() => {
    let interval;
     if( !scenarioCheckIntervalValue && running.length>0 ){
      dispatch(getScenarioList('RUNNING', false, false));
       getList();
       interval = setInterval(() => {
        dispatch(getScenarioList('RUNNING', false, false));
        getList();
     }, 7000)
     clearInterval(scenarioCheckIntervalValue);
     dispatch(setScenarioCheckIntervalValue(interval));
   }
   }, [running])

  useEffect(() => {
    if (scenarioUpdateMsg != null) {
      if (scenarioUpdateMsg.response.status === 200) {
        getList();
        alert.success({ text: scenarioUpdateMsg.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_UPDATE_MSG_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioUpdateMsg]);

  useEffect(() => {
    if (error?.response?.data) {
      alert.error({ text: [error.response.data[Object.keys(error.response.data)[0]]][0] }, { position: 'top center' });
    }
  }, [error]);

  useEffect(() => {
    if (scenarioPinCheck != null) {
      if (scenarioPinCheck.response.status === 200) {
        alert.success({ text: scenarioPinCheck.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_PIN_CHECK_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioPinCheck]);

  return (
    <div className="workspace">
      <Scenario header={intl.formatMessage({ id: 'workspace.running' })} listItem={running} isWorkspace showClusterRunningStatus={isClusterRunning?false:<TooltipWrapper tooltipText={"Cluster is down"} style={{marginLeft:"5px"}}>
    <img src={StatusErrorIcon} style={{ height: '20px', width: '22px' }} alt="img" /></TooltipWrapper>} />
      <Scenario
        header={intl.formatMessage({ id: 'workspace.queued' })}
        listItem={queued}
        listNo={5}
        viewMore
        linkTo="/scenario/queued"
        isWorkspace
      />
      <Scenario
        header={intl.formatMessage({ id: 'workspace.pending' })}
        listItem={pending}
        listNo={15}
        viewMore
        linkTo="/scenario/pending"
        isWorkspace
      />
      <Scenario
        header={intl.formatMessage({ id: 'workspace.completed' })}
        listItem={completed}
        listNo={15}
        viewMore
        linkTo="/scenario/completed"
        isWorkspace
      />
    </div>
  );
};

export default Workspace;
