import { isString, isUndefined, isNull, isNumber, some, isEmpty, isEqual, cloneDeep, forOwn, sortBy } from 'lodash';
import { DB_STATUSES, PHASE_STATUSES, STATUSES_OBJ } from '../constants/data';

const getServerHostURL = () => {
  const { REACT_APP_HTTP, REACT_APP_API } = process.env;
  const API_BASE_URL = `${REACT_APP_HTTP}://${REACT_APP_API}`;
  return API_BASE_URL;
};

const prepareServerURL = (URL) => {
  const intialValuesToCheck = ['https://', 'http://'];

  // Getting server host url
  const API_BASE_URL = getServerHostURL();

  // Checking the URL is blank, undefined or null
  if (isUndefined(URL) || isNull(URL) || URL.length === 0) return URL;

  // Checking if the URL is not string
  if (!isString(URL)) return URL;

  // Checking if URL has https
  if (
    URL.substring(0, intialValuesToCheck[0].length).toLowerCase() === intialValuesToCheck[0].toLowerCase() ||
    URL.substring(0, intialValuesToCheck[1].length).toLowerCase() === intialValuesToCheck[1].toLowerCase()
  ) {
    return URL;
  }

  // Checking if the URL contains server host address
  if (URL.substring(0, API_BASE_URL.length).toLowerCase() === API_BASE_URL.toLowerCase()) return URL;

  // Returning URL with server host address
  return `${API_BASE_URL}${URL}`;
};

const isValNumeric = (value) => {
  // Checking if value is undefined, null or blank
  if (isUndefined(value) || isNull(value) || value.length === 0) return false;

  // Checking if value is numeric only
  if (isNumber(value * 1)) return true;

  // If anything other value
  return false;
};

const isDecimalNumber = (value) => {
  if (isValNumeric(value)) return value - Math.floor(value) !== 0;
  return false;
};

const numericOnlyCheck = (value, includeDecimal = true) => {
  try {
    if (includeDecimal && `${value}`.trim().slice(-1) === '.') return false;
    if (!includeDecimal && isDecimalNumber(value)) return false;
    return isValNumeric(value);
  } catch (error) {
    return false;
  }
};

const rangeCheck = (value, min, max) => {
  if (numericOnlyCheck(value)) {
    if (numericOnlyCheck(min) && numericOnlyCheck(max)) return value >= min && value <= max;
    if (numericOnlyCheck(min) && !numericOnlyCheck(max)) return value >= min;
    if (!numericOnlyCheck(min) && numericOnlyCheck(max)) return value <= max;
    if (!numericOnlyCheck(min) && !numericOnlyCheck(max)) return false;
  }
  return false;
};

const getCurrentPhaseNo = (scenario) => {
  let currentPhaseNo = 0;
  try {
    const dataRev = scenario?.inputs;
    if (dataRev) {
      currentPhaseNo = 0;
      // eslint-disable-next-line
      for (let i = dataRev.length - 1; i >= 0; i--) {
        if (dataRev[i].status === PHASE_STATUSES.done) {
          currentPhaseNo = i + 1;
          break;
        }
        currentPhaseNo = i;
      }

      if (currentPhaseNo > dataRev.length) currentPhaseNo = dataRev.length;
    }
  } catch (error) {
    currentPhaseNo = 0;
  }

  return currentPhaseNo + 1;
};

const getCurrentPhase = (scenario) => {
  let currPhase;
  try {
    currPhase = scenario?.inputs[getCurrentPhaseNo(scenario) - 1];
  } catch (err) {
    currPhase = undefined;
  }

  return currPhase;
};

const checkIsCurrentPhase = (phase, scenario) => {
  try {
    return phase.id === getCurrentPhase(scenario)?.id;
  } catch (error) {
    return false;
  }
};

const disablePhaseInputs = (phase, scenario) => {
  try {
    const scenarioRunningStatusCheck =
      phase?.status === PHASE_STATUSES.done || phase?.status === PHASE_STATUSES.running;
    const phase1QueuedStatusCheck = phase?.status === PHASE_STATUSES.valid && scenario?.status === STATUSES_OBJ.queued;

    if (checkIsCurrentPhase(phase, scenario) && (scenarioRunningStatusCheck || phase1QueuedStatusCheck)) return true;

    return false;
  } catch (error) {
    return false;
  }
};

const isDatabaseExpired = (scenario) => {
  return scenario?.etl_data_status === DB_STATUSES.expired;
};

const enableLaunchButton = (scenario) => {
  let enableLaunch = false;

  const inputs = scenario?.inputs;

  if (inputs) {
    if (inputs.slice(0, -1).every((x) => x.status == PHASE_STATUSES.done)) {
      return true;
    }
  }

  const checkIfInputDataIsValid = (data) => {
    let result = false;
    try {
      if (typeof data === 'string') {
        result = JSON.parse(data)?.fields[0]?.status === PHASE_STATUSES.valid;
      } else if (typeof data === 'object' && !isEmpty(data)) {
        result = data?.fields[0]?.status === PHASE_STATUSES.valid;
      }
    } catch (error) {
      result = false;
    }
    return result;
  };

  // checking if scenario state is idle
  try {
    if (scenario) {
      if (scenario?.status === STATUSES_OBJ.idle) {
        if (
          scenario?.inputs[0]?.status === PHASE_STATUSES.valid &&
          checkIfInputDataIsValid(scenario?.inputs[0]?.data) &&
          scenario?.inputs[0]?.is_xl_valid &&
          isValNumeric(scenario?.inputs[0]?.file_id)
        ) {
          enableLaunch = true;
        } else if (
          scenario?.inputs[0]?.status === PHASE_STATUSES.done &&
          scenario?.inputs[1]?.status === PHASE_STATUSES.valid &&
          checkIfInputDataIsValid(scenario?.inputs[1]?.data) &&
          scenario?.inputs[1]?.is_xl_valid &&
          isValNumeric(scenario?.inputs[1]?.file_id)
        ) {
          enableLaunch = true;
        } else if (
          scenario?.inputs[1]?.status === PHASE_STATUSES.done &&
          scenario?.inputs[2]?.status === PHASE_STATUSES.valid &&
          checkIfInputDataIsValid(scenario?.inputs[2]?.data)
        ) {
          enableLaunch = true;
        }
      }
    }
  } catch (error) {
    enableLaunch = false;
  }
  return enableLaunch;
};

const enableSaveButton = (scenario) => {
  let enableSave = false;
  // checking if scenario state is not done
  if (scenario?.status !== STATUSES_OBJ.done) {
    enableSave = some(
      scenario?.inputs,
      (item) => ![PHASE_STATUSES.done, PHASE_STATUSES.running].includes(item.status)
    );
  }
  return enableSave;
};

const getPhaseFolderKey = (scenarioDetails, phaseNumber) => {
  let folder_name = '';
  switch (String(phaseNumber)) {
    case '1':
      folder_name = scenarioDetails['tableau_phase_1_folder'];
      break;
    case '2':
      folder_name = scenarioDetails['tableau_phase_2_folder'];
      break;
    case '3':
      folder_name = scenarioDetails['tableau_phase_3_folder'];
      break;
    default:
      folder_name = '';
      break;
  }
  return folder_name;
};

const formatNumber = (number) => {
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}M`;
    // eslint-disable-next-line no-else-return
  } else if (number >= 1000) {
    return `${(number / 1000).toFixed(1)}K`;
  } else if (number < 0.01 && number > -0.01 && number !== 0) {
    return 0;
  }
  return number.toFixed(2);
};

const convertToTitleCase = (input) => {
  // Convert camelCase to space-separated words
  let spacedString = input.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Convert snake_case to space-separated words
  spacedString = spacedString.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  return spacedString.replace(/\b\w/g, (char) => char.toUpperCase());
};

const normalizeObject = (obj) => {
  // Clone the object to avoid mutating the original
  const clonedObj = cloneDeep(obj);

  // Iterate over each property of the object
  forOwn(clonedObj, (value, key) => {
    if (value.type === 'select' && Array.isArray(value.values)) {
      // Sort the values array
      value.values = sortBy(value.values, ['code']);
    }
  });

  return clonedObj;
};

const areObjectsEqual = (obj1, obj2) => {
  // return JSON.stringify(obj1) === JSON.stringify(obj2);
  const normalizedObj1 = normalizeObject(obj1);
  const normalizedObj2 = normalizeObject(obj2);
  return isEqual(normalizedObj1, normalizedObj2);
};

export {
  getServerHostURL,
  prepareServerURL,
  isValNumeric,
  numericOnlyCheck,
  rangeCheck,
  getCurrentPhaseNo,
  disablePhaseInputs,
  enableLaunchButton,
  enableSaveButton,
  isDatabaseExpired,
  checkIsCurrentPhase,
  getPhaseFolderKey,
  formatNumber,
  convertToTitleCase,
  areObjectsEqual,
};
