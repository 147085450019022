/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';

// local dependencies
import './OptimizationParametersInput.scss';
import SubPhaseHeader from '../../../../../../components/SubPhaseHeader/SubPhaseHeader';
import WText from '../../../../../../components/WText/Wtext';
import { updateScenarioContext } from '../../../AutoSubmit';
import { getScenario, updateScenarioInput } from '../../../../../../redux/actions';
import { PHASE_STATUSES, STATUSES, SUBPHASE_STATUSES } from '../../../../../../constants/data';
import ScenarioContext from '../../../../ScenarioContext';
import { numericOnlyCheck } from '../../../../../../utils/utils';

// eslint-disable-next-line no-unused-vars
const OptimizationParametersInput = ({ phaseData, disabled, readOnly }) => {
  // dispatch
  const dispatch = useDispatch();

  // context
  const scenarioContext = useContext(ScenarioContext);

  const sectionData = phaseData && phaseData?.data?.fields[0];

  const initialValues = {
    numberOfIterations: sectionData?.value.numberOfIterations || '200',
    numberOfActionsPerLoop: sectionData?.value.numberOfActionsPerLoop || '50',
    numberOfActionsConsInEachLoop: sectionData?.value.numberOfActionsConsInEachLoop || '50',
    minimumDisBwNeighbouringSites: sectionData?.value.minimumDisBwNeighbouringSites || '0',
    proportionClients: sectionData?.value.proportionClients || '0.01',
    proxyType: sectionData?.value.proxyType,
  };

  const validationSchema = Yup.object().shape({
    numberOfIterations: Yup.number()
      .required('is required')
      .typeError('has to be a number')
      .test('Numeric only', 'has to be a whole number (no decimal)', (value) => numericOnlyCheck(value, false))
      .min(1, 'has to be above 0'),
    numberOfActionsPerLoop: Yup.number()
      .required('is required')
      .typeError('has to be a number')
      .test('Numeric only', 'has to be a whole number (no decimal)', (value) => numericOnlyCheck(value, false))
      .min(1, 'has to be above 0'),
    numberOfActionsConsInEachLoop: Yup.number()
      .required('is required')
      .typeError('has to be a number')
      .min(1, 'has to be above 0')
      .test('Numeric only', 'has to be a whole number (no decimal)', (value) => numericOnlyCheck(value, false))
      .test({
        name: 'within range',
        message: ({ numberOfActionsPerLoop }) => `has to be between 1 and ${numberOfActionsPerLoop}`,
        test: (value, ctx) => value >= 1 && value <= ctx.parent.numberOfActionsPerLoop,
        params: { numberOfActionsPerLoop: Yup.ref('numberOfActionsPerLoop') },
      }),
    minimumDisBwNeighbouringSites: Yup.number()
      .required('is required')
      .typeError('has to be a number')
      .min(0, 'has to be a positive number'),
    proportionClients: Yup.number()
      .required('is required')
      .typeError('has to be a number')
      .min(0, 'has to be between 0 and 1')
      .max(1, 'has to be between 0 and 1'),
    proxyType: Yup.number().required('is required'),
  });

  const isDisabled = () => {
    // return (
    //   (disabled && readOnly) || !scenarioContext?.edit || (phaseData && phaseData?.status === PHASE_STATUSES.done)
    // );
    return disabled;
  };

  return (
    <div title="OptimizationParameters" id="optimizationParameters" className="mb-100 OptimizationParameters">
      <SubPhaseHeader title="Optimization Parameters" index={1} />
      <div className="opi-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange
          initialTouched={false}
          onSubmit={(values) => {
            const phase3Data = {
              ...phaseData,
              status: PHASE_STATUSES.valid,
              data: JSON.stringify({
                fields: [
                  {
                    ...sectionData,
                    status: SUBPHASE_STATUSES.valid,
                    value: values,
                  },
                ],
              }),
            };
            dispatch(updateScenarioInput(phaseData?.id, phaseData?.scenario_id, phase3Data));
            dispatch(getScenario(phaseData?.scenario_id));
          }}
        >
          {({ errors, touched, isValid, values, submitForm, setFieldTouched, handleChange }) => (
            <Form>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div
                  className={`date-input opi-input ${touched.numberOfIterations && errors.numberOfIterations ? 'error' : ''
                    }`}
                >
                  <label htmlFor="numberOfIterations" className="input-label">
                    Number of iterations
                  </label>
                  <Field
                    id="numberOfIterations"
                    name="numberOfIterations"
                    type="text"
                    placeholder="0"
                    autoComplete="false"
                    className={isDisabled() ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.numberOfIterations && errors.numberOfIterations && (
                    <div className="message">
                      <WText typo="caption">{errors.numberOfIterations}</WText>
                    </div>
                  )}
                </div>
                <div
                  className={`date-input opi-input ${touched.numberOfActionsPerLoop && errors.numberOfActionsPerLoop ? 'error' : ''
                    }`}
                >
                  <label htmlFor="numberOfActionsPerLoop" className="input-label">
                    No. of actions per loop
                  </label>
                  <Field
                    id="numberOfActionsPerLoop"
                    name="numberOfActionsPerLoop"
                    type="text"
                    placeholder="0"
                    autoComplete="false"
                    className={isDisabled() ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.numberOfActionsPerLoop && errors.numberOfActionsPerLoop && (
                    <div className="message">
                      <WText typo="caption">{errors.numberOfActionsPerLoop}</WText>
                    </div>
                  )}
                </div>
                <div
                  className={`date-input opi-input ${touched.numberOfActionsConsInEachLoop && errors.numberOfActionsConsInEachLoop ? 'error' : ''
                    }`}
                >
                  <label htmlFor="numberOfActionsConsInEachLoop" className="input-label">
                    No. of actions consolidated in each loop
                  </label>
                  <Field
                    id="numberOfActionsConsInEachLoop"
                    name="numberOfActionsConsInEachLoop"
                    type="text"
                    placeholder="0.0"
                    autoComplete="false"
                    className={isDisabled() ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.numberOfActionsConsInEachLoop && errors.numberOfActionsConsInEachLoop && (
                    <div className="message">
                      <WText typo="caption">{errors.numberOfActionsConsInEachLoop}</WText>
                    </div>
                  )}
                </div>
                <div
                  className={`date-input opi-input ${touched.minimumDisBwNeighbouringSites && errors.minimumDisBwNeighbouringSites ? 'error' : ''
                    }`}
                >
                  <label htmlFor="minimumDisBwNeighbouringSites" className="input-label">
                    Min. dist. between neighbouring sites in same loop (KM)
                  </label>
                  <Field
                    id="minimumDisBwNeighbouringSites"
                    name="minimumDisBwNeighbouringSites"
                    type="text"
                    placeholder="0.0"
                    autoComplete="false"
                    className={isDisabled() ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.minimumDisBwNeighbouringSites && errors.minimumDisBwNeighbouringSites && (
                    <div className="message">
                      <WText typo="caption">{errors.minimumDisBwNeighbouringSites}</WText>
                    </div>
                  )}
                </div>
                <div
                  className={`date-input opi-input ${touched.proportionClients && errors.proportionClients ? 'error' : ''
                    }`}
                >
                  <label htmlFor="proportionClients" className="input-label">
                    Proportion Clients
                  </label>
                  <Field
                    id="proportionClients"
                    name="proportionClients"
                    type="text"
                    placeholder="0.0"
                    autoComplete="false"
                    className={isDisabled() ? 'scenario-disable-cursor' : ''}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  />
                  {touched.proportionClients && errors.proportionClients && (
                    <div className="message">
                      <WText typo="caption">{errors.proportionClients}</WText>
                    </div>
                  )}
                </div>
                <div className={`date-input opi-input ${touched.proxyType && errors.proxyType ? 'error' : ''}`}>
                  <label htmlFor="proxyType" className="input-label">
                    Proxy Type
                  </label>
                  <Field
                    name="proxyType"
                    as="select"
                    placeholder="Proxy Type"
                    className={`opi-input-select ${isDisabled() ? 'scenario-disable-cursor' : ''}`}
                    disabled={isDisabled()}
                    onChange={(e) => {
                      setFieldTouched(e.target.name);
                      handleChange(e);
                    }}
                  >
                    <option value={0} className="opi-input-select-option">
                      Select
                    </option>
                    <option value={1} className="opi-input-select-option">
                      Standard
                    </option>
                    <option value={2} className="opi-input-select-option">
                      Revenue
                    </option>
                  </Field>
                  {touched.proxyType && errors.proxyType && (
                    <div className="message">
                      <WText typo="caption">{errors.proxyType}</WText>
                    </div>
                  )}
                </div>
              </div>
              <button
                ref={scenarioContext.phaseSubmitBtnref.phase3}
                type="button"
                className="scenarion-submit-button-hide"
                onClick={() => {
                  if (isEqual(initialValues, values) || !isEqual(initialValues, values)) {
                    updateScenarioContext(3, isValid, touched, scenarioContext);
                    if (isValid && (isEmpty(touched) || !isEmpty(touched))) submitForm();
                  }
                }}
              >
                &nbsp;
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

OptimizationParametersInput.propTypes = {
  phaseData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    file_id: PropTypes.oneOf([PropTypes.number, null]),
    scenario_id: PropTypes.oneOfType([PropTypes.number]).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    phase_number: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    status: PropTypes.oneOf(Object.values(PHASE_STATUSES)).isRequired,
    // eslint-disable-next-line react/require-default-props
    data: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        section: PropTypes.string,
        status: PropTypes.oneOf(Object.values(PHASE_STATUSES)),
        value: PropTypes.shape({
          coverageQualityThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          minimumMicrosegmentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          percentageOfClients: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          qualityConsistencyThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          targetMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          trafficTailSitesNonConsidered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          userName: PropTypes.string,
          userRole: PropTypes.string,
          kmeasnModelScenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      })),
    }),
  }),
  disabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

OptimizationParametersInput.defaultProps = {
  phaseData: {},
};

export default OptimizationParametersInput;
