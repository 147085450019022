export default {
  title: 'Activity',
  unread: 'New',
  all: 'All',
  descriptions: {
    'scenario-queued': 'Added to queue',
    'phase-started': 'Phase {phase} started to simulate',
    'phase-completed': 'Phase {phase} is completed. Data is refreshed',
    'scenario-completed': 'Scenario completed',
    'scenario-duplicated': 'Scenario automatically duplicated',
    'scenario-archived': 'Archived scenario',
  },
  links: {
    output: 'Review output',
    original: 'View original scenario',
  },
  empty: {
    action: 'Start new simulation',
    text: "You don't have any activity yet",
  },
  snackbars: {
    save: {
      title: 'Saved!',
      text: 'Your scenario has been sucessfully saved',
    },
    launch: {
      title: 'Launched!',
      text: 'Your scenario has been sucessfully launched',
    },
    archive: {
      title: 'Archived!',
      text: 'Your scenario has been sucessfully archived',
    },
    unarchive: {
      title: 'Unarchived!',
      text: 'Your scenario has been sucessfully unarchived',
    },
    pause: {
      title: 'Paused!',
      text: 'Your scenario has been sucessfully paused',
    },
  },
};
