/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

// local dependencies
import './Modal.scss';
import WIcon from '../WIcon/WIcon';
import { useRef } from 'react';

const Modal = ({ isOpen, closeBtn, dismissable, openHandler, modalClasses, style, children }) => {
  const nodeRef = useRef(null)
  return (
    <CSSTransition nodeRef={nodeRef} in={isOpen} classNames="modal" timeout={500} unmountOnExit>
      <div ref={nodeRef} className={modalClasses.parent ?? 'custom-modal'}>
        <div className={modalClasses.dialog ?? 'custom-modal-dialog'} style={style}>
          {children?.header && (
            <div className={modalClasses.header ?? 'modal-header'}>
              {children?.header}
              {closeBtn && dismissable && (
                <div className="modal-close-btn" onClick={() => openHandler(false)}>
                  <WIcon icon="close-large" />
                </div>
              )}
            </div>
          )}

          {children?.body && <div className={modalClasses.body ?? 'custom-modal-body'}>{children?.body}</div>}
          {children?.footer && <div className={modalClasses.footer ?? 'modal-footer'}>{children?.footer}</div>}
        </div>
        <div className="modal-backdrop" onClick={dismissable ? () => openHandler(false) : null} />
      </div>
    </CSSTransition>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeBtn: PropTypes.bool.isRequired,
  dismissable: PropTypes.bool.isRequired,
  openHandler: PropTypes.func.isRequired,
  modalClasses: PropTypes.shape({
    parent: PropTypes.string,
    dialog: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.string,
    footer: PropTypes.string,
  }),
  style: PropTypes.shape({
    width: PropTypes.string,
  }),
  children: PropTypes.shape({
    header: PropTypes.element,
    body: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    footer: PropTypes.element,
  }).isRequired,
};
Modal.defaultProps = {
  modalClasses: {
    parent: 'custom-modal',
    dialog: 'custom-modal-dialog',
    header: 'modal-header',
    body: 'custom-modal-body',
    footer: 'modal-footer',
  },
  style: {},
};

export default Modal;
