/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import clsx from 'clsx';
import { useState } from 'react';
import Popover from 'react-bootstrap/Popover';
import { useIntl } from 'react-intl';

import { usePopperTooltip } from 'react-popper-tooltip';

// local dependencies
import WText from '../WText/Wtext';
import './PopoverConfirm.scss';

const PopOverDuplicateWrapper = ({ title, content, placement, setOpen, children }) => {
  const [controlledVisible, setControlledVisible] = useState(false);
  // intl
  const intl = useIntl();

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
    placement,
  });

  // actual content
  const popoverConfirmContent = (
    <Popover id="popover-basic" className={`popover-confirm ${clsx({ top: placement === 'top' })}`}>
      <WText typo="body" align="center">
        {title}
      </WText>
      <WText typo="bodyLight" align="center">
        {content}
      </WText>
      <div className="actions-wrapper">
        <button
          type="button"
          className="WButton popover-action secondary mini"
          onClick={() => setControlledVisible(false)}
        >
          {intl.formatMessage({ id: 'general.cancel' })}
        </button>
        <button
          type="button"
          className="WButton popover-action primary mini"
          onClick={() => {
            setOpen(true);
            setControlledVisible(false);
          }}
        >
          {intl.formatMessage({ id: 'scenario.popovers.copy.confirm' })}
        </button>
      </div>
    </Popover>
  );

  return (
    <div className="popover-wrapper">
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'popover-container' })}>
          <div {...getArrowProps({ className: 'popover-arrow' })} />
          {popoverConfirmContent}
        </div>
      )}
      <div ref={setTriggerRef}>{children}</div>
    </div>
  );
};

export default PopOverDuplicateWrapper;

export const PopOverStopWrapper = ({ title, content, setOpen, children }) => {
  // intl
  const intl = useIntl();
  const [controlledVisible, setControlledVisible] = useState(false);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
    placement: 'bottom',
  });

  // actual content
  const popoverConfirmContent = (
    <Popover id="popover-basic" className="popover-confirm">
      <WText typo="body" align="center">
        {title}
      </WText>
      <WText typo="bodyLight" align="center">
        {content}
      </WText>
      <div className="actions-wrapper">
        <button
          type="button"
          className="WButton popover-action secondary mini"
          onClick={() => setControlledVisible(false)}
        >
          {intl.formatMessage({ id: 'general.cancel' })}
        </button>
        <button
          type="button"
          className="WButton popover-action primary mini"
          onClick={() => {
            setOpen(true);
            setControlledVisible(false);
          }}
        >
          {intl.formatMessage({ id: 'scenario.popovers.stop.confirm' })}
        </button>
      </div>
    </Popover>
  );

  return (
    <div className="popover-wrapper">
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'popover-container' })}>
          <div {...getArrowProps({ className: 'popover-arrow' })} />
          {popoverConfirmContent}
        </div>
      )}
      <div ref={setTriggerRef}>{children}</div>
    </div>
  );
};
