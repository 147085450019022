export default {
  error: 'Invalid data',
  warning: 'Warning',
  required: '{attribute} is required.',
  integer: '{attribute} has to be a integer.',
  numeric: '{attribute} has to be a number.',
  decimal: '{attribute} has to be a number.',
  minValue: '{attribute} has to be greater than or equal to {min}.',
  maxValue: '{attribute} has to be less than or equal to {max}.',
  minLength: '{attribute} should have more than {min} characters',
  between: '{attribute} has to be between {min} and {max}.',
  isDate: '{attribute} is not a valid date.',
  previousThanDates: '{attribute} has to be a date before {limitDate}.',
  afterThanDates: '{attribute} has to be a date after {limitDate}.',
  betweenDates: '{attribute} has to be a date between Start & EndDate.',
  range: '{attribute} should be between {min} and {max}.',
  multiple: '{attribute} has to be a multiple of {multiple}.',
  largerThanPrevious: 'Must be larger than previous',
  smallerThanNext: 'Must be smaller than next',
  divisor: 'Has to be divisor of bandwith.',
  is: 'Has to be one of {values}.',
  unique: 'has to be unique',
  fromRToOther: 'This value was Rural and you changed it',
  zeroBeforeDecimal: 'Please add 0 before decimal',
};
