/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import {
  FILE_BEGIN_PHASE_1,
  FILE_ERROR_PHASE_1,
  FILE_GET_PHASE_1,
  FILE_POST_PHASE_1,
  FILE_BEGIN_PHASE_2,
  FILE_ERROR_PHASE_2,
  FILE_GET_PHASE_2,
  FILE_POST_PHASE_2,
  FILE_EXCEL_LOADING_PHASE_1,
  FILE_EXCEL_LOADING_PHASE_2,
} from '../actionTypes';

const initialState = {
  loadingPhase1: false,
  errorPhase1: {},
  phase1ExcelData: {},
  loadingPhase1Excel: false,
  loadingPhase2: false,
  errorPhase2: {},
  phase2ExcelData: {},
  loadingPhase2Excel: false,
};

// eslint-disable-next-line default-param-last
const fileReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FILE_BEGIN_PHASE_1:
      return {
        ...state,
        loadingPhase1: true,
        errorPhase1: null,
      };
    case FILE_GET_PHASE_1:
      return {
        ...state,
        loadingPhase1: false,
        phase1ExcelData: payload.data,
        errorPhase1: null,
      };
    case FILE_POST_PHASE_1:
      return {
        ...state,
        loadingPhase1: false,
        phase1ExcelData: payload.data,
        errorPhase1: null,
      };
    case FILE_ERROR_PHASE_1:
      return {
        ...state,
        loadingPhase1: false,
        errorPhase1: payload.error,
      };
    case FILE_BEGIN_PHASE_2:
      return {
        ...state,
        loadingPhase2: true,
        errorPhase2: null,
      };
    case FILE_GET_PHASE_2:
      return {
        ...state,
        loadingPhase2: false,
        phase2ExcelData: payload.data,
        errorPhase2: null,
      };
    case FILE_POST_PHASE_2:
      return {
        ...state,
        loadingPhase2: false,
        phase2ExcelData: payload.data,
        errorPhase2: null,
      };
    case FILE_ERROR_PHASE_2:
      return {
        ...state,
        loadingPhase2: false,
        errorPhase2: payload.error,
      };
    case FILE_EXCEL_LOADING_PHASE_1:
      return {
        ...state,
        loadingPhase1Excel: payload,
      };
    case FILE_EXCEL_LOADING_PHASE_2:
      return {
        ...state,
        loadingPhase2Excel: payload,
      };
    default:
      return state;
  }
};

export default fileReducer;
