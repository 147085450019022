import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  CartesianGrid,
  Label,
  LabelList,
  ReferenceLine,
  Text,
} from 'recharts';
import { formatNumber, convertToTitleCase } from '../../utils/utils';
import '../../screens/ReportCharts/Phase3/Optimization/Detail.scss';

// const CustomBarLabel = ({ x, y, width, height, value, index }) => (
//   const capex = data[index].capex;
//   return (

//     <g>
//     <text
//       x={x + width + 5}
//     y={y + height / 2}
//   fill="#fff"
// textAnchor="start"
// dominantBaseline="middle"
// style={{ fontSize: '10px' }}
// >
//       {value}
//     </text>
//   </g>
//     )
// );

const CustomBarLabel = (props) => {
  const { x, y, width, height, value, data, index } = props;
  return (
    <g>
      <text
        x={x + width + 5}
        y={y + height / 2}
        fill="#fff"
        textAnchor="start"
        dominantBaseline="middle"
        style={{ fontSize: '10px' }}
      >
        {`${value}`}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { noOfActions, specificActionType, capex } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: '#ffffff', padding: '10px', borderRadius: '5px', color: '#000000' }}
      >
        <table
          style={{
            borderCollapse: 'collapse',
            color: '#000000',
            fontSize: '12px',
          }}
        >
          <tbody>
            <tr>
              <td style={{ paddingRight: '10px', color: '#000000' }}>
                <strong>{convertToTitleCase('noOfActions')}:</strong>
              </td>
              <td style={{ color: '#000000' }}>{noOfActions}</td>
            </tr>
            <tr>
              <td style={{ paddingRight: '10px', color: '#000000' }}>
                <strong>{convertToTitleCase('specificActionType')}:</strong>
              </td>
              <td style={{ color: '#000000' }}>{specificActionType}</td>
            </tr>
          </tbody>
        </table>
        {/* <p>{`${convertToTitleCase('noOfActions')}: ${noOfActions}`}</p>
        <p>{`${convertToTitleCase('specificActionType')}: ${specificActionType}`}</p>
        <p>{`Capex: ${formatNumber(capex)}`}</p> */}
      </div>
    );
  }

  return null;
};

const CustomYAxisTick = ({ x, y, payload }) => {
  const MAX_LINE_LENGTH = 40; // Adjust the maximum line length as needed
  const words = payload.value.split('/');
  const lines = [];
  let line = '';

  words.forEach((word) => {
    if ((line + word).length > MAX_LINE_LENGTH) {
      lines.push(line);
      line = word;
    } else {
      line += (line ? ' /' : '') + word;
    }
  });
  lines.push(line);

  return (
    <g transform={`translate(${x},${y})`}>
      {lines.map((line1, index) => (
        <text key={index} x={0} y={index * 12} dy={4} textAnchor="end" fill="#fff" fontSize={10}>
          {line1}
        </text>
      ))}
    </g>
  );
};

const Phase3BarChart = ({ data }) => {
  const categoryColors = {
    NEWBUILDSC: '#B06811', // Orange background: #B06811;

    NEWBUILD_NEAR: '#3E8838', // Green #3E8838
    NEWTECHFREQ: '#C64947', // Red
  };

  <div>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ padding: '10px', textAlign: 'center' }}>Chart</th>
          <th style={{ padding: '10px', textAlign: 'center' }}>Capex</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>
            <BarChart
              layout="vertical"
              data={data}
              width={800}
              height={600}
              margin={{
                top: 60,
                right: 120,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
              <XAxis type="number" axisLine={false} tickLine={false} domain={[0, 450]} />
              <YAxis
                type="category"
                dataKey="name"
                axisLine={false}
                tickLine={false}
                width={150}
                tick={CustomYAxisTick}
              >
                <Label value="MBoU (MB/Month)" angle={0} position="insideTopLeft" offset={-10} fill="#fff" />
              </YAxis>
              <Tooltip content={CustomTooltip} />
              <Legend
                payload={Object.keys(categoryColors).map((key) => ({
                  id: key,
                  type: 'square',
                  value: key,
                  color: categoryColors[key],
                }))}
              />
              <Bar dataKey="value" isAnimationActive={false}>
                <LabelList dataKey="value" content={CustomBarLabel} />
                {data.actionTypeChart.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={categoryColors[entry.category]} />
                ))}
              </Bar>
            </BarChart>
          </td>
          <td style={{ padding: '10px', textAlign: 'center' }}></td>
        </tr>
      </tbody>
    </table>
  </div>;

  const getColor = (index) => {
    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#00c49f'];
    return colors[index % colors.length];
  };

  return (
    // <BarChart
    //   layout="vertical"
    //   data={data}
    //   width={800}
    //   height={600}
    //   margin={{
    //     top: 60,
    //     right: 120,
    //     left: 20,
    //     bottom: 5,
    //   }}
    // >
    //   <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
    //   <XAxis type="number" axisLine={false} tickLine={false} domain={[0, 450]} />
    //   <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} width={150} tick={CustomYAxisTick}>
    //     <Label value="MBoU (MB/Month)" angle={0} position="insideTopLeft" offset={-10} fill="#fff" />
    //   </YAxis>
    //   <Tooltip content={CustomTooltip} />
    //   <Legend
    //     payload={Object.keys(categoryColors).map((key) => ({
    //       id: key,
    //       type: 'square',
    //       value: key,
    //       color: categoryColors[key],
    //     }))}
    //   />
    //   <Bar dataKey="value" isAnimationActive={false}>
    //     <LabelList dataKey="value" content={CustomBarLabel} />
    //     {data.map((entry, index) => (
    //       <Cell key={`cell-${index}`} fill={categoryColors[entry.category]} />
    //     ))}
    //   </Bar>
    // </BarChart>
    <div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            {/* <th style={{ padding: '10px', textAlign: 'center' }}>Chart</th> */}
            {/* <th style={{ padding: '10px', textAlign: 'center' }}>Capex</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ paddingLeft: '50px', textAlign: 'center' }}>
              <BarChart
                layout="vertical"
                data={data.actionTypeChart}
                width={900}
                height={600}
                margin={{
                  top: 10,
                  // right: 20,
                  left: 70,
                  // bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
                <XAxis type="number" axisLine={false} tickLine={false} />
                <YAxis
                  type="category"
                  dataKey="specificActionType"
                  axisLine={false}
                  tickLine={false}
                  width={150}
                  tick={CustomYAxisTick}
                >
                  <Label
                    value="# of Actions By Type (Capex)"
                    angle={0}
                    position="insideTopLeft"
                    offset={-10}
                    fill="#fff"
                  />
                  {/* <Label value="Capex" angle={0} position="insideTopRight" offset={-10} fill="#fff" /> */}
                </YAxis>
                <Tooltip content={CustomTooltip} />
                <Legend
                  payload={Object.keys(categoryColors).map((key) => ({
                    id: key,
                    type: 'square',
                    value: key,
                    color: categoryColors[key],
                  }))}
                />
                <Bar dataKey="noOfActions" isAnimationActive={false} barSize={40} barGap={30}>
                  <LabelList dataKey="noOfActions" content={<CustomBarLabel data={data} />} />
                  {data.actionTypeChart.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={categoryColors[entry.category]} />
                  ))}
                </Bar>
              </BarChart>
            </td>
            <td style={{ paddingLeft: '50px', textAlign: 'center' }}>
              <BarChart
                layout="vertical"
                data={data.actionTypeChart}
                width={100}
                height={600}
                margin={{
                  // top: 10,
                  right: 120,
                  bottom: 5,
                }}
              >
                <YAxis type="category" dataKey="capex" axisLine={false} tickLine={false} tickFormatter={formatNumber}>
                  <Label value="Capex" angle={0} position="insideTop" fill="#fff" />
                </YAxis>
              </BarChart>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const colors = [
  { rect: '#3E883833', top: '#3E8838' },
  { rect: '#C6A24633', top: '#C6A246' },
  { rect: '#A4548B33', top: '#A4548B' },
  { rect: '#D77C0D33', top: '#B06811' },
  { rect: '#4A90E233', top: '#4A90E2' },
  { rect: '#50E3C233', top: '#50E3C2' },
  { rect: '#F5A62333', top: '#F5A623' },
  { rect: '#B8E98633', top: '#B8E986' },
  { rect: '#7ED32133', top: '#7ED321' },
  { rect: '#BD10E033', top: '#BD10E0' },
  { rect: '#F8E71C33', top: '#F8E71C' },
  { rect: '#9013FE33', top: '#9013FE' },
];

const CustomBar = (props) => {
  const { fill, x, y, width, height, value, category, index } = props;

  // console.log('props', props);
  const topSegmentHeight = 7; // Height of the top bright segment
  const isNegative = value < 0;
  return (
    <g>
      <rect
        x={x}
        y={isNegative ? y - Math.abs(height) : y}
        width={width}
        height={Math.abs(height)}
        fill={colors[index].rect}
      />
      <rect
        x={x}
        y={isNegative ? y - topSegmentHeight : y - topSegmentHeight}
        width={width}
        height={topSegmentHeight}
        fill={colors[index].top}
      />
      <text
        x={x + width / 2}
        y={isNegative ? y + topSegmentHeight + 5 : y - topSegmentHeight - 5}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: '12px', fontWeight: 'lighter' }}
      >
        {formatNumber(value)}
      </text>
    </g>
  );
};

// const CustomBar = (props) => {
//   const { fill, x, y, width, height, value, category, index } = props;

//   // Height of the top bright segment
//   const topSegmentHeight = 7;
//   // Determine if the bar is negative
//   const isNegative = value < 0;
//   // Adjust the top segment's y position for negative values
//   const topSegmentY = isNegative ? y : y - topSegmentHeight;
//   // Adjust text y position for negative values
//   const textY = isNegative ? y + height + topSegmentHeight + 5 : y - topSegmentHeight - 5;

//   return (
//     <g>
//       <rect x={x} y={isNegative ? y : y - height} width={width} height={Math.abs(height)} fill={colors[index].rect} />
//       <rect x={x} y={topSegmentY} width={width} height={topSegmentHeight} fill={colors[index].top} />
//       <text
//         x={x + width / 2}
//         y={textY}
//         fill="#fff"
//         textAnchor="middle"
//         dominantBaseline="middle"
//         style={{ fontSize: '12px', fontWeight: 'lighter' }}
//       >
//         {formatNumber(value)}
//       </text>
//     </g>
//   );
// };

const CustomTooltipPhase2 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { value } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: 'rgba(50, 50, 50, 0.85)', padding: '10px', borderRadius: '5px', color: '#fff' }}
      >
        <p>{`Value: ${formatNumber(value)}`}</p>
      </div>
    );
  }

  return null;
};

const CustomXAxisTick = (props) => {
  const { x, y, payload } = props;
  const words = payload.value.split(' ');
  const midIndex = Math.floor(words.length / 2);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={-5} dy={16} textAnchor="middle" fill="#666" fontSize="10px">
        {words.slice(0, midIndex).join(' ')}
        <tspan x="0" dy="1.2em">
          {words.slice(midIndex).join(' ')}
        </tspan>
      </text>
    </g>
  );
};

const Phase2BarChart = ({ data, index }) => {
  return (
    <>
      <h6>{data.title}</h6>
      <BarChart width={500} height={400} data={data.series} margin={{ top: 20, right: 30, left: 25, bottom: 5 }}>
        <XAxis dataKey="category" tick={<CustomXAxisTick />} angle={-25} />
        <YAxis tickFormatter={formatNumber} domain={['auto', 'auto']} />
        {/* <Tooltip content={CustomTooltipPhase2} /> */}
        <Bar dataKey="value" fill={colors[index]} shape={<CustomBar fill={colors[index]} />} />
      </BarChart>
    </>
  );
};

const Phase1BarChart = ({ data, index, xAxisKey, yAxisKey }) => {
  return (
    <>
      <h6>{data.title}</h6>
      <BarChart width={500} height={400} data={data.series} margin={{ top: 20, right: 30, left: 25, bottom: 5 }}>
        <XAxis dataKey={xAxisKey} angle={-25} tick={false} axisLine={false} />
        <YAxis tickFormatter={formatNumber} tickLine={false} />
        {/* <Tooltip content={CustomTooltipPhase2} /> */}
        <ReferenceLine y={0} stroke="grey" strokeDasharray="3 3" />
        <Bar dataKey={yAxisKey} fill={colors[index]} shape={<CustomBar fill={colors[index]} />} />
      </BarChart>
    </>
  );
};

const transformData = (data) => {
  if (!data || data.length === 0) {
    return [{ name: 'Quality Levels' }];
  }

  const transformedData = data.reduce(
    (acc, item) => {
      if (!acc[0][item.cell_or_azimuth_quality_level]) {
        acc[0][item.cell_or_azimuth_quality_level] = 0;
      }
      acc[0][item.cell_or_azimuth_quality_level] += item.value;
      return acc;
    },
    [{ name: 'Quality Levels' }]
  );

  console.log('transformedData', transformedData);
  return transformedData;
};

const CustomLabel = ({ x, y, width, height, labelData, ...props }) => {
  const centerX = x + width / 2;
  const centerY = y + height / 2;
  console.log('labelData', labelData);
  return (
    <Text
      x={centerX}
      y={centerY}
      fill="#fff"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{ fontSize: '12px', fontWeight: 'bold' }}
    >
      {labelData}
    </Text>
  );
};

const StackedBarChart = ({ data, colors, title }) => {
  const transformedData = transformData(data);
  const bars = Object.keys(transformedData[0])
    .filter((key) => key !== 'name')
    .map((key, index) => (
      <Bar
        key={key}
        dataKey={key}
        stackId="a"
        fill={colors[index % colors.length]}
        label={<CustomLabel labelData={transformedData[0][key]} />}
      />
    ));

  return (
    <>
      <h6>{title}</h6>
      <BarChart width={196} height={250} data={transformedData} margin={{ top: 10, right: 10, left: 50, bottom: 5 }}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis type="category" dataKey="cell_or_azimuth_quality_level" />
        {/* eslint-disable-next-line react/jsx-boolean-value */}
        <YAxis hide={true} />
        <Tooltip />
        {/* <Legend /> */}
        {bars}
      </BarChart>
    </>
  );
};

const BarChartComponent = ({ phase, data, ...props }) => {
  if (phase === 3) {
    return <Phase3BarChart data={data} />;
    // eslint-disable-next-line no-else-return
  } else if (phase === 2) {
    const { index, stacked, colors, title } = props;
    return stacked === false ? (
      <Phase2BarChart data={data} index={index} />
    ) : (
      <StackedBarChart data={data} colors={colors} title={title} />
    );
  } else if (phase === 1) {
    const { index, xAxisKey, yAxisKey } = props;
    return <Phase1BarChart data={data} index={index} xAxisKey={xAxisKey} yAxisKey={yAxisKey} />;
  }
  //return null;
};

export default BarChartComponent;
