/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import WText from '../../components/WText/Wtext';
import './NotFoundScreen.scss';

const NotFoundScreen = () => {
  return (
    <div className="not-found-screen">
      <WText tag="h1" color="gray" style={{ fontSize: '124px', lineHeight: '128px' }}>
        404
      </WText>
      <WText tag="h1" style={{ fontSize: '60px', lineHeight: '64px' }}>
        Page Not Found
      </WText>
    </div>
  );
};

export default NotFoundScreen;
