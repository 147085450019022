import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import "./TooltipWrapper.scss"

const TooltipWrapper = (props) => {
  const { tooltipText, children } = props;
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  return (
    <div className='tooltip-wrapper'>
      {/* {cloneElement(children, { ref: setTriggerRef })} */}
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          {tooltipText}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
      <div ref={setTriggerRef}>
        {children}
      </div>
    </div>
  );
};

export default TooltipWrapper;