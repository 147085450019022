import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Popup, Circle } from 'react-leaflet';
import * as d3 from 'd3';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '../Phase3/Optimization/Summary.scss';
import '../Phase3/Optimization/Detail.scss';
import Filters from '../../../components/Filters/Filters';
import axios from '../../../utils/axios';
import { WSpinnerLarge } from '../../../components/WLoader/WSpinner';
import { formatNumber, convertToTitleCase, areObjectsEqual } from '../../../utils/utils';

// Fix default icon issue
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Define categories and their icons
const categories = {
  CategoryA: L.icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
  CategoryB: L.icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
  CategoryC: L.icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
};

// Example data with categories
// const points = [
//   { id: 1, name: 'Point 1', position: [51.505, -0.09], category: 'CategoryA' },
//   { id: 2, name: 'Point 2', position: [51.515, -0.1], category: 'CategoryB' },
//   { id: 3, name: 'Point 3', position: [51.52, -0.12], category: 'CategoryC' },
// ];

// const filterData = {
//   color: { type: 'select', values: ['Red', 'Green', 'Blue'] },
//   size: { type: 'select', values: ['Small', 'Medium', 'Large'] },
//   price: { type: 'range', values: [0, 100] }, // Range from 0 to 100
// };

const Phase1DemandMap = ({ scenarioID }) => {
  const [points, setPoints] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);

  const handleFilterChange = (key, type, selectedValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        type,
        values: selectedValues,
      },
    }));
  };

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/phase1-demandmap/';
    axios
      .post(url, filters, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setPoints(response.data.data);
        setFilterData(response.data.filters);
        Object.keys(response.data.filters).forEach((key) => {
          const type = response.data.filters[key].type;
          const values = response.data.filters[key].values;
          handleFilterChange(key, type, values);
        });
        setLoading(false);
      });
  }, []);

  const applyFilters = () => {
    setLoading(true);
    const url = '/api/charts/phase1-demandmap/';
    const filtersBody = areObjectsEqual(filters, filterData) ? {} : filters;
    axios
      .post(url, filtersBody, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setPoints(response.data.data);
        // setFilterData(response.data.filters);
        setLoading(false);
      });
  };

  const renderTable = (data) => {
    if (!data) return null;
    return (
      <div className="custom-tooltip">
        <table
          style={{
            borderCollapse: 'collapse',
            fontSize: '10px',
            // backgroundColor: '#000',
            // margin: '10px 0',
            width: 'max-content',
          }}
        >
          <tbody>
            {Object.keys(data)
              .filter((key) => key !== 'id' && key !== 'position' && key !== 'lat' && key !== 'lon')
              .map((key) => (
                <tr key={key} style={{ padding: '0px', margin: '0px' }}>
                  <td style={{ color: '#000000' }}>
                    <strong>{convertToTitleCase(key)}:</strong>
                  </td>
                  <td style={{ color: '#000000' }}>
                    {typeof data[key] === 'number' ? formatNumber(data[key]) : data[key]}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  const maxValue = points?.max_values?.max_avg_mbou_latent;
  const maxTotalRevenue = points?.max_values?.max_yearly_revenue;

  const radiusScale = d3.scaleSqrt().domain([0, maxValue]).range([0, 20000]);
  // const opacityScale = d3.scaleLinear().domain([0, maxTotalRevenue]).range([0.2, 1]);
  const colorScale = d3.scaleSequential(d3.interpolateBlues).domain([0, maxTotalRevenue]);

  // Bounding box for Spain
  const bounds = [
    [43.791356, -1.389385], // top-left corner (north-west)
    [35.94685, 4.316111], // bottom-right corner (south-east)
  ];

  return (
    <div className="container1">
      {/* {isOpen && <FiltersModal isOpen={isOpen} openHandler={openHandler} filterData={filterData} />} */}
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div style={{ flex: 1 }}>
            <Filters
              data={filterData}
              handleFilterChange={handleFilterChange}
              filters={filters}
              applyFilters={applyFilters}
            />
          </div>
          <div style={{ flex: 10, paddingRight: '10px' }}>
            <MapContainer
              bounds={bounds}
              style={{ height: '80vh', width: '100%' }}
              preferCanvas={true} // Enable canvas rendering
              updateWhenZooming={false} // Optimize zoom updates
              updateWhenIdle={true} // Optimize idle updates
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {points.chart_data.map((point) => (
                <Circle
                  key={point.id}
                  center={[point.lat, point.lon]}
                  radius={radiusScale(point.avg_mbou_of_site)} // Adjust size as needed
                  fillColor={colorScale(point.sum_yearly_revenue_potential_total_site)}
                  // fillOpacity={opacityScale(point.yearly_revenue)}
                  // opacity={1}
                  fillOpacity={1}
                  weight={0.5}
                  className="circle-shadow"
                >
                  <Popup>{renderTable(point)}</Popup>
                </Circle>
              ))}
            </MapContainer>
            <div className="table-container" style={{ height: 'fit-content', scroller: 'none' }}>
              <table>
                <thead>
                  <tr>
                    <th>Technology Customer</th>
                    <th>Count Customer</th>
                    <th>Yearly Revenue Potential</th>
                    <th>Average MBoU Latent</th>
                  </tr>
                </thead>
                <tbody>
                  {points.stats.map((row, index) => (
                    <tr key={index}>
                      <td>{row.technology_customer}</td>
                      <td>{formatNumber(row.count_customer)}</td>
                      <td>{formatNumber(row.yearly_revenue_potential)}</td>
                      <td>{formatNumber(row.average_mbou_latent)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Phase1DemandMap;
