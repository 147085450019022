/**
 Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */
import Workspace from '../screens/Workspace/workspace';
import Login from '../screens/Auth/Login/Login';
import Scenarios from '../screens/Scenarios/Scenarios';
import ScenarioScreen from '../screens/ScenarioScreen/ScenarioScreen';
import ReportsScreen from '../screens/Reports/ReportScreen';
import ReportsPhaseList from '../screens/Reports/ReportsPhaseList/ReportsPhaseList';
import ReportDetailScreen from '../screens/ReportDetailScreen/ReportDetailScreen';
import NotFoundScreen from '../screens/Error/NotFoundScreen';
import userRoles from './userRoles';
import ReportPopOutDetail from '../screens/ReportDetailScreen/ReportPopOutDetail';
import UserScreen from '../components/UserScreen/UserScreen';

const { ADMIN_1, ADMIN_2, EDITOR, VIEWER, ACTIVE, INACTIVE, REPORTS_ONLY } = userRoles;

 export const routesMain = [
  {
    path: '/',
    element: <Workspace />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
    label: 'workspace',
    icon: 'icon-workspace',
  },
  {
    path: '/reports',
    element: <ReportsScreen />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, REPORTS_ONLY, VIEWER],
    label: 'reports',
    icon: 'icon-view',
  },
]

export const routesStatus = [
  {
    path: '/scenario/queued',
    element: <Scenarios name="Queued" />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
    label: 'queued',
    icon: 'icon-scenarios',
  },
  {
    path: '/scenario/pending',
    element: <Scenarios name="Pending" />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
    label: 'pending',
    icon: 'icon-pause',
  },
  {
    path: '/scenario/completed',
    element: <Scenarios name="Completed" />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
    label: 'completed',
    icon: 'icon-success'
  },
]

export const routesAction = [
  {
    path: '/scenario/pinned',
    element: <Scenarios name="Pinned" />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
    label: 'pinned',
    icon: 'icon-unselected',
  },
  {
    path: '/scenario/archive',
    element: <Scenarios name="Archive" />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
    label: 'archive',
    icon: 'icon-archive'
  },
]

export const routesUser = [
  {
    path: '/users',
    element: <UserScreen />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
    label: 'users',
    icon: ''
  },
]



const routes = [
  ...routesMain, 
  ...routesAction,
  ...routesStatus,
  ...routesUser,
  
  {
    path: '/login',
    element: <Login />,
    blankLayout: true,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER, ACTIVE, INACTIVE, REPORTS_ONLY],
  },
  {
    path: '/scenario/:id',
    element: <ScenarioScreen />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
  },
  {
    path: '/scenario/:id/edit/',
    element: <ScenarioScreen />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
  },
  {
    path: '/reports/list/:id/phase/:phaseID',
    element: <ReportsPhaseList />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
  },
  {
    path: '/report/detail/scenario/:scenarioID/worksheet/:workbookID/phase/:phaseID/:tableaurefname',
    element: <ReportDetailScreen />,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
  },
  {
    path: '/report/:scenarioID/worksheet/:workbookID/phase/:phaseID/:tableaurefname',
    element: <ReportPopOutDetail />,
    blankLayout: true,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
  },
  {
    path: 'not-found',
    element: <NotFoundScreen />,
    blankLayout: true,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER, ACTIVE, INACTIVE],
  },
  {
    path: '*',
    element: <NotFoundScreen />,
    blankLayout: true,
    allowedRoles: [ADMIN_1, ADMIN_2, EDITOR, VIEWER, ACTIVE, INACTIVE],
  },
];
export default routes;
