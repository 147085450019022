/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { some, every } from 'lodash';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useAlert } from 'react-alert';

// local dependencies
import './ActionBar.scss';
import WText from '../WText/Wtext';
import SubPhase from '../SubPhase/SubPhase';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ScenarioProgressPhaseExpanded from '../Scenario/components/ScenarioProgressPhaseExpanded/ScenarioProgressPhaseExpanded';
import ScenarioContext from '../../screens/ScenarioScreen/ScenarioContext';
import { updateScenario, launchScenario, pauseScenario, getScenario } from '../../redux/actions';
import { PHASE_STATUSES, STATUSES_OBJ, SUBPHASE_STATUSES, PHASES_TRANSFORMER } from '../../constants/data';
import PopOverDuplicateWrapper from '../PopoverConfirm/PopoverConfirm';
import { SCENARIO_LAUNCH_CHECK_RESET, SCENARIO_PAUSE_CHECK_RESET } from '../../redux/actionTypes';
import {
  checkIsCurrentPhase,
  enableLaunchButton,
  enableSaveButton,
  getCurrentPhaseNo,
  isDatabaseExpired,
  isValNumeric,
} from '../../utils/utils';
import { scenarioActionButton } from '../../utils/scenarioActions';

// import scenarioAlert from '../../i18n/locale/en/scenario';

// eslint-disable-next-line no-unused-vars
const ActionBar = ({ scenario, phases }) => {
  // local state
  const [scroll, setScroll] = useState(0);

  // alert
  const alert = useAlert();

  // i18n
  const intl = useIntl();

  // context
  const scenarioContext = useContext(ScenarioContext);

  // dispatch
  const dispatch = useDispatch();

  // selector
  const { error, scenarioLaunchCheck, scenarioPauseCheck } = useSelector((state) => state.scenarios);
  const { loadingPhase1Excel, loadingPhase2Excel } = useSelector((state) => state.inputFiles);
  const { data: authUser, authCheckComplete } = useSelector((state) => state.users);

  const userRole = authCheckComplete ? authUser?.role?.name : null

  useEffect(() => {
    const progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      // eslint-disable-next-line no-shadow
      const scroll = `${totalScroll / windowHeight}`;
      setScroll(scroll);
    };

    window.addEventListener('scroll', progressBarHandler);

    return () => window.removeEventListener('scroll', progressBarHandler);
  });

  /* useEffect(() => {
     if (scenarioUpdateMsg != null) {
       if (scenarioUpdateMsg.response.status === 200) {
         alert.success({ text: scenarioUpdateMsg.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_UPDATE_MSG_RESET,
           payload: { data: null },
         });
       }
     }
  }, [scenarioUpdateMsg]); */

  useEffect(() => {
    if (error != null) {
      alert.error({ text: [error.response.data[Object.keys(error.response.data)[0]]][0] }, { position: 'top center' });
    }
  }, [error]);

  useEffect(() => {
    if (scenarioLaunchCheck != null) {
      if (scenarioLaunchCheck.response.status === 200) {
        alert.success({ text: scenarioLaunchCheck.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_LAUNCH_CHECK_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioLaunchCheck]);

  useEffect(() => {
    if (scenarioPauseCheck != null) {
      if (scenarioPauseCheck.response.status === 200) {
        alert.success({ text: scenarioPauseCheck.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_PAUSE_CHECK_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioPauseCheck]);

  // checks the phase status is done and returns the phase accordingly
  const currentPhase = () => {
    let currPhase;
    try {
      currPhase = scenario?.inputs[getCurrentPhaseNo(scenario) - 1];
    } catch (err) {
      currPhase = undefined;
    }

    return currPhase;

    // return scenario?.inputs
    //   .slice()
    //   .reverse()
    //   .reduce((cp, phase) => (phase.status !== PHASE_STATUSES.done ? phase : cp), {});
  };

  // checks the current phase is filled or not by checking the sub phase status is valid or warning
  const isCurrentPhaseFilled = () => {
    return (
      scenario?.status === STATUSES_OBJ.idle &&
      currentPhase()?.status !== PHASE_STATUSES.running &&
      every(
        currentPhase()?.data?.fields,
        (subphase) => subphase?.status === SUBPHASE_STATUSES.valid || subphase.status === SUBPHASE_STATUSES.warning
      )
    );
  };

  // checks for scenario status is running
  const isCurrentPhaseRunning = () => {
    return scenario?.status === STATUSES_OBJ.running;
  };

  // checks for scenario status is queued
  const isCurrentPhaseQueued = () => {
    return scenario?.status === STATUSES_OBJ.queued;
  };

  // checks for any of the scenario phase status is not valid
  const isCurrentPhaseEdited = () => {
    return some(
      currentPhase()?.data?.fields,
      (subphase) => subphase.status === SUBPHASE_STATUSES.void || subphase.status === SUBPHASE_STATUSES.valid
    );
  };

  // checks for any of the scenario phase status is invalid
  // eslint-disable-next-line
  const hasCurrentPhaseErrors = () => {
    return some(currentPhase()?.data?.fields, (subphase) => subphase.status === SUBPHASE_STATUSES.error);
  };

  // modifies the etl date in desired format
  const formatedDate = () => {
    return moment(scenario?.etl_data_updated_at).format('DD/MM/YY');
  };

  // returns the actual progress w.r 1 to 100
  const actualProgress = (item) => {
    return Math.floor(item.model_progress * 100);
  };

  // returns what is the current phase status
  const currentPhaseStatus = () => {
    if (isCurrentPhaseRunning()) return 'running';
    if (isCurrentPhaseQueued()) return 'queue';
    if (isCurrentPhaseEdited()) return 'edit';
    if (isCurrentPhaseFilled()) return 'filled';
    return 'empty';
  };

  // will be able to copy only if the scenario has atleast one phase
  // const canCopy = () => {
  //   return currentPhase().phase_number !== 1;
  // };

  const canArchive = () => {
    if (loadingPhase1Excel || loadingPhase2Excel) return false;
    return (
      // scenario status is running
      scenario?.status !== STATUSES_OBJ.running &&
      // scenario is archived and
      !scenario?.archived
    );
  };

  const canUnarchive = () => {
    if (loadingPhase1Excel || loadingPhase2Excel) return false;
    return scenario?.archived;
  };

  const canLaunch = () => {
    if (loadingPhase1Excel || loadingPhase2Excel) return false;
    return enableLaunchButton(scenario) && !isDatabaseExpired(scenario) && !scenario?.archived;
  };

  const canPause = () => {
    if (loadingPhase1Excel || loadingPhase2Excel) return false;
    return isCurrentPhaseRunning() || isCurrentPhaseQueued();
  };

  const canSave = () => {
    if (loadingPhase1Excel || loadingPhase2Excel) return false;
    return enableSaveButton(scenario) && !isDatabaseExpired();
  };

  const canCopy = () => {
    if (loadingPhase1Excel || loadingPhase2Excel) return false;
    return true;
  };

  const clickArchiveHandler = () => {
    if (scenario?.archived) {
      dispatch(updateScenario(scenario?.id, { archived: false }));
      // dispatch(getScenario(scenario?.id));
    } else {
      dispatch(updateScenario(scenario?.id, { archived: true }));
      // dispatch(getScenario(scenario?.id));
    }
    // window.location.reload();
  };

  const clickLaunchHandler = async () => {
    await dispatch(launchScenario({ id: scenario?.id }));
    await dispatch(getScenario(scenario?.id));
    // alert.success({ text: scenarioAlert.alertMsg.launch.launch }, { position: 'top center' });
    // window.location.reload();
  };

  const clickLaunchAllhandler = async () => {
    dispatch(launchScenario({ id: scenario?.id, allPhases: true }));
    dispatch(getScenario(scenario?.id));
  }

  const clickPauseHandler = () => {
    dispatch(pauseScenario(scenario?.id));
    dispatch(getScenario(scenario?.id));
    // alert.success({ text: scenarioAlert.alertMsg.pause.pause }, { position: 'top center' });
    // window.location.reload();
  };

  const handleScrollTo = (id) => document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });

  const phaseProgressValidation = (phase) => {
    if (checkIsCurrentPhase(phase, scenario)) {
      if (
        phase.status === PHASE_STATUSES.running ||
        (phase.status === PHASE_STATUSES.valid && isCurrentPhaseQueued())
      ) {
        if (phase.phase_number === 1 || phase.phase_number === 2) {
          return isValNumeric(phase.file_id);
        }
        return true;
      }
    }
    return false;
  };

  const handleSave = () => {
    try {
      scenarioContext.phaseSubmitBtnref.name.current.click();
      scenarioContext.phaseSubmitBtnref.phase1.current.click();
      scenarioContext.phaseSubmitBtnref.phase2.current.click();
      scenarioContext.phaseSubmitBtnref.phase3.current.click();
      alert.success({ text: 'Scenario has been saved' }, { position: 'top center' });
    } catch (error) {
      console.error(error);
      alert.error({ text: `Oops, Something is wrong` }, { position: 'top center' });
    }
  }

  return (
    <div className="action-bar">
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ transform: `scale(${scroll}, 1)` }} />
      </div>
      <div className="scenario-content-text-statuses">
        {/* TODO: fade transistion */}
        <WText typo="h4">
          {scenario?.status === STATUSES_OBJ.done ? (
            <FormattedMessage id="scenario.title" />
          ) : (
            <FormattedMessage id="scenario.phase" values={{ phase: currentPhase()?.phase_number }} />
          )}
          <span
            className={`scenario-status-action ${clsx({
              done: scenario?.status === STATUSES_OBJ.done,
              running: scenario?.status !== STATUSES_OBJ.done,
              expired: isDatabaseExpired(),
            })}`}
          >
            <FormattedMessage
              id={
                scenario?.status === STATUSES_OBJ.done
                  ? 'scenario.statuses.done'
                  : `scenario.statuses.${currentPhaseStatus()}`
              }
            />
          </span>
        </WText>

        {/* TODO: fade transistion */}
        <div className="scenario-phase-summary">
          <WText color="grey06" typo="bodyLight">
            {(isDatabaseExpired() && currentPhaseStatus() === 'queue' && (
              <>
                <FormattedMessage id="scenario.requests.reset.before" />
                {/* TODO: onclick reset */}
                <a href="/">
                  <FormattedMessage id="scenario.requests.reset.link" />
                </a>
              </>
            )) ||
              (isDatabaseExpired() && scenario?.status === STATUSES_OBJ.done && (
                <FormattedMessage id="scenario.requests.expiredCompleted" values={{ date: formatedDate() }} />
              )) ||
              (isDatabaseExpired() && (
                <>
                  {/* TODO: onclick reset */}
                  <a href="/">
                    <FormattedMessage id="scenario.requests.expired.before" />;
                  </a>
                  <FormattedMessage
                    id="scenario.requests.expired.link"
                    values={{ phase: currentPhase().phase_number }}
                  />
                </>
              )) ||
              (scenario?.status === STATUSES_OBJ.done && null) ||
              (currentPhaseStatus() === 'empty' && <FormattedMessage id="scenario.requests.empty" />) ||
              (currentPhaseStatus() === 'edit' && <FormattedMessage id="scenario.requests.edit" />) ||
              (currentPhaseStatus() === 'queue' && <FormattedMessage id="scenario.requests.queue" />) ||
              (currentPhaseStatus() === 'running' && <FormattedMessage id="scenario.requests.running" />) ||
              (currentPhaseStatus() === 'filled' && null)}
          </WText>
        </div>
      </div>
      <div className="phases-wrapper">
        {phases?.map((phase, pIdx) => {
          // return phase.status === PHASE_STATUSES.running ||
          //   (phase.status === PHASE_STATUSES.valid && isCurrentPhaseQueued()) ? (
          return phaseProgressValidation(phase) ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div className="phase-running-action-bar" onClick={() => handleScrollTo(`phase${phase.phase_number}`)}>
              <ScenarioProgressPhaseExpanded
                id={scenario?.id}
                phase={phase.phase_number}
                progress={actualProgress(scenario) || null}
                status="running"
              />
            </div>
          ) : (
            <div
              key={phase.id}
              className={`phase-action-bar ${clsx({
                done: phase.status === PHASE_STATUSES.done,
                void: phase.id === currentPhase()?.id,
                expired: isDatabaseExpired(),
              })}`}
            >
              <div className="phase-item-action-bar" onClick={() => handleScrollTo(`phase${phase.phase_number}`)}>
                <WText typo="small" color="grey06">
                  {`P${phase.phase_number}`}
                </WText>
              </div>
              {phase?.data.fields.map((fl, idx) => {
                const newOne = scenarioContext.statuses[PHASES_TRANSFORMER.toString[phase?.phase_number]];
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={`${phase.phase_number}--${idx}`}>
                    <div className="sub-phase-line" />
                    <SubPhase
                      id={idx + 1}
                      phaseStatus={newOne?.status}
                      status={newOne?.subPhase?.one}
                      expired={isDatabaseExpired()}
                      isCurrentPhase={phase.id === currentPhase()?.id}
                      // isCurrentPhase="true"
                      clickHandler={() =>
                        handleScrollTo(
                          fl.section === 'uploadExcel' ? `uploadExcelPhase${phase.phase_number}` : fl.section
                        )
                      }
                    />
                    {idx === 0 && pIdx !== 2 && (
                      <>
                        <div className="sub-phase-line" />
                        <SubPhase
                          id={2}
                          status={newOne?.subPhase?.two}
                          phaseStatus={newOne?.status}
                          expired={isDatabaseExpired()}
                          isCurrentPhase={phase.id === currentPhase()?.id}
                          // isCurrentPhase="true"
                          clickHandler={() => handleScrollTo(`uploadExcelPhase${phase.phase_number}`)}
                        />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
      <div style={{ display: 'flex' }}>
        {/* TODO: onclick */}
        {scenarioContext.edit && (
          <ButtonIcon
            lottie
            icon="save"
            text={intl.formatMessage({ id: 'scenario.actions.save' })}
            disabled={!canSave() || !scenarioActionButton.save.includes(userRole)}
            clickHandler={handleSave}
          />
        )}
        <PopOverDuplicateWrapper
          title={intl.formatMessage({ id: 'scenario.popovers.copy.title' })}
          content={intl.formatMessage({ id: 'scenario.popovers.copy.text' })}
          setOpen={() => scenarioContext.setMounted(true)}
          placement="top"
        >
          <ButtonIcon
            lottie
            icon="duplicate"
            focused="visible"
            text={intl.formatMessage({ id: 'scenario.actions.copy' })}
            disabled={!canCopy() || !scenarioActionButton.duplicate.includes(userRole)}
          />
        </PopOverDuplicateWrapper>
        {!scenario?.archived ? (
          <ButtonIcon
            lottie
            icon="archive"
            text={intl.formatMessage({ id: 'scenario.actions.archive' })}
            clickHandler={clickArchiveHandler}
            disabled={!canArchive() || !scenarioActionButton.archive.includes(userRole)}
          />
        ) : (
          <ButtonIcon
            lottie
            icon="unarchive"
            text={intl.formatMessage({ id: 'scenario.actions.unarchive' })}
            clickHandler={clickArchiveHandler}
            disabled={!canUnarchive() || !scenarioActionButton.unarchive.includes(userRole)}
          />
        )}
        {!canPause() ? (
          <ButtonIcon
            lottie
            icon="launch"
            text={intl.formatMessage({ id: 'scenario.actions.launch' })}
            disabled={!canLaunch() || !scenarioActionButton.run.includes(userRole)}
            clickHandler={clickLaunchHandler}
          />
        ) : (
          <ButtonIcon
            lottie
            icon={isCurrentPhaseRunning() ? 'stop' : 'pause'}
            text={intl.formatMessage({
              id: `scenario.actions.${isCurrentPhaseRunning() ? 'stop' : 'pause'}`,
            })}
            clickHandler={clickPauseHandler}
            disabled={!scenarioActionButton.run.includes(userRole)}
          />
        )}
        {!canPause() ? (
          <ButtonIcon
            icon="play"
            lottie
            text={intl.formatMessage({ id: 'scenario.actions.launchAll' })}
            clickHandler={clickLaunchAllhandler}
            disabled={!canLaunch() || !scenarioActionButton.run.includes(userRole)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ActionBar;
