/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// local dependencies
import { getPhaseWorkBooks, getPhaseWorksheets } from '../../redux/actions';
import WorkSheetsList from '../WorkSheetsList/WorkSheetsList';
import './WorkBooksList.scss';

const WorkBooksList = ({ selectedPhaseID,scenarioDetails }) => {
  // dispatch
  const dispatch = useDispatch();

  // global state
  const { workbooks, worksheets } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(getPhaseWorkBooks(selectedPhaseID));
    dispatch(getPhaseWorksheets(selectedPhaseID));
  }, [selectedPhaseID]);

  return (
    <div className="border-box">
      <div className="row row-custom">
        {workbooks
          ?.filter((pwb) => pwb.is_active === true)
          .map((workBookItem) => {
            return (
              <div className="column" key={workBookItem.name}>
                <h2 className="title-custom">{workBookItem.name}</h2>
                <p>
                  <WorkSheetsList workBookID={workBookItem.id} worksheets={worksheets} phaseID={selectedPhaseID} scenarioDetails={scenarioDetails} />
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

WorkBooksList.propTypes = {
  selectedPhaseID: PropTypes.number.isRequired,
};

export default WorkBooksList;
