/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { filter, includes } from 'lodash';

// local dependencies
import WIcon from '../WIcon/WIcon';
import './WCheckBox.scss';

const WCheckBox = ({ modelValue, value, label, name, phaseNumber, section, disabled, changeHandler, error }) => {
  // local state
  const [hover, setHover] = useState(false);
  const [checkedProxy, setCheckedProxy] = useState(null);

  // ref
  const checkBoxRef = useRef();

  const containsValue = () => {
    return includes(modelValue, value);
  };

  const isSingleCheckbox = () => {
    return typeof modelValue === 'boolean';
  };

  useEffect(() => {
    setCheckedProxy(containsValue());
  }, [modelValue, value]);

  const emitBooleanValue = () => {
    setCheckedProxy(!checkedProxy);
    changeHandler(phaseNumber, section, checkedProxy);
  };

  const emitArrayValue = () => {
    let newValues = [];
    if (checkedProxy) {
      setCheckedProxy(false);
      newValues = filter(modelValue, (val) => val !== value);
    } else {
      setCheckedProxy(true);
      newValues = [...modelValue, value];
    }
    return changeHandler(phaseNumber, section, newValues);
  };

  const emitChange = () => {
    if (!disabled) {
      if (isSingleCheckbox()) emitBooleanValue();
      else emitArrayValue();
    }
  };

  const isChecked = () => {
    if (isSingleCheckbox()) return modelValue;
    const isValueInCheckedProxy = includes(checkedProxy, value);
    return checkedProxy || isValueInCheckedProxy;
  };

  const computedClasses = clsx({
    disabled,
    error,
    hover,
    checked: isChecked(),
  });

  return (
    <div className="checkbox" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className={`checkbox-icon checkbox-ride-icon ${computedClasses}`} onClick={emitChange}>
        {/* TODO: fade transition */}
        {isChecked() ? <WIcon icon="checked" size={16} color={error ? 'danger' : 'white'} /> : null}
      </div>
      <input
        name={name}
        ref={checkBoxRef}
        value={value}
        checked={isChecked()}
        disabled="disabled"
        className="checkbox-input"
        type="checkbox"
        onChange={changeHandler}
      />
      {label && (
        <label htmlFor="value" className={`checkbox-label ${computedClasses}`} onClick={emitChange}>
          {label}
        </label>
      )}
    </div>
  );
};

export default WCheckBox;
