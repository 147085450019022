/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

// local dependencies
import './ReportScreen.scss';
import WText from '../../components/WText/Wtext';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import { getScenarioList,  getReportScenario, getPhaseWorkBooks, getPhaseWorksheets  } from '../../redux/actions';
import SubPhaseHeader from '../../components/SubPhaseHeader/SubPhaseHeader';
import { WSpinnerLarge } from '../../components/WLoader/WSpinner';
import { getPhaseFolderKey } from '../../utils/utils';

const ReportsScreen = () => {
  // global state
  const { loading, all, latestCompleted } = useSelector((state) => state.scenarios);
  const { loading: loading1, reportScenario, workbooks, worksheets } = useSelector((state) => state.reports);

  // local state
  const [reportPhaseSelected, setReportPhaseSelected] = useState({
    pinned: [],
    completed: [],
  });
  const [viewSelected, setViewSelected] = useState(1);
  const [pinPhaseOptions, setPinPhaseOptions] = useState([]);
  const [completedPhaseOptions, setCompletedPhaseOptions] = useState([]);
  const [filterQuery, setFilterQuery] = useState('');
  const [completedFiltered, setCompletedFiltered] = useState([...latestCompleted]);

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // formik initial values
  const initialValues = {
    searchQuery: '',
  };

  // formik validation
  const validationSchema = Yup.object().shape({
    searchQuery: Yup.string(),
  });

  useEffect(() => {
    dispatch(getScenarioList('DONE', false, true));

  }, []);

  useEffect(() => {
    setCompletedFiltered(latestCompleted);
    console.log('received latestCompleted', latestCompleted);
  }, [latestCompleted]);

  useEffect(() => {
    let filteredCompleted = [];
    if (filterQuery !== '') {
      filteredCompleted = latestCompleted?.filter((cd) => {
        return String(cd.name).toLowerCase().includes(filterQuery.toLowerCase());
      });
      setCompletedFiltered(filteredCompleted);
    } else {
      filteredCompleted = [...latestCompleted];
      setCompletedFiltered(latestCompleted);
    }
  }, [filterQuery, latestCompleted]);

  useEffect(() => {
    // phase options
    const dt = completedFiltered
      ?.filter((cc) => cc.pinned === false)
      .map((c) =>
        c.inputs?.map((ip) => {
          return {
            value: ip.phase_number,
            label: `Phase ${ip.phase_number}`,
            status: ip.status,
          };
        })
      );

    const ppo = completedFiltered
      ?.filter((cc) => cc.pinned === true)
      .map((c) =>
        c.inputs?.map((ip) => {
          return {
            value: ip.phase_number,
            label: `Phase ${ip.phase_number}`,
            status: ip.status,
          };
        })
      );

    const ppr = completedFiltered
      ?.filter((cc) => cc.pinned === true)
      .map((c) => {
        return {
          id: c.id,
          value: 0,
        };
      });

    const cpr = completedFiltered
      ?.filter((cc) => cc.pinned === false)
      .map((c) => {
        return {
          id: c.id,
          value: 0,
        };
      });

    setReportPhaseSelected({
      pinned: ppr,
      completed: cpr,
    });

    setPinPhaseOptions(ppo);
    setCompletedPhaseOptions(dt);
  }, [completedFiltered]);

  useEffect(() => {
    dispatch(getPhaseWorkBooks(viewSelected));
    dispatch(getPhaseWorksheets(viewSelected));
  }, [viewSelected]);

  const getFirstWorkbookID = () => {
    return workbooks?.filter((pwb) => pwb.is_active === true)[0].id;
  };
  const getRefName = () => {
    const workBookID = getFirstWorkbookID();
    return worksheets?.filter((ws) => ws.workbook === workBookID)?.filter((wsi) => wsi.is_active === true)[0]
      .tableau_ref_name;
  };
  const navigateToReportDetailScreen = (id) => {
    dispatch(getReportScenario(id));
    const refname = getRefName();
    if (
      reportScenario?.tableau_phase_1_folder ||
      reportScenario?.tableau_phase_2_folder ||
      reportScenario?.tableau_phase_3_folder
    ) {
      navigate(
        `/report/detail/scenario/${id}/worksheet/${getPhaseFolderKey(
          reportScenario,
          viewSelected
        )}/phase/${viewSelected}/${refname}`,
        {
          replace: false,
        }
      );
    } else {
      navigate(`/report/detail/scenario/${id}/worksheet/${getFirstWorkbookID()}/phase/${viewSelected}/${refname}`, {
        replace: false,
      });
    }
  };

  const clickViewHandler = (id, group) => {
    if (group === 'pinned') {
      // navigate(`/reports/list/${id}/phase/${viewSelected}`);
      navigateToReportDetailScreen(id);
    } else {
      // navigate(`/reports/list/${id}/phase/${viewSelected}`);
      navigateToReportDetailScreen(id);
    }
  };

  const onSelectPhaseHandler = (id, group, value) => {
    if (group === 'pinned') {
      const newPinned = reportPhaseSelected?.pinned.map((p) => {
        if (p.id === id) {
          return { ...p, value: parseInt(value, 10) };
        }
        return p;
      });
      setReportPhaseSelected({
        ...reportPhaseSelected,
        pinned: newPinned,
      });
    } else {
      const newComp = reportPhaseSelected?.completed.map((c) => {
        if (c.id === id) {
          return { ...c, value: parseInt(value, 10) };
        }
        return c;
      });
      setReportPhaseSelected({
        ...reportPhaseSelected,
        completed: newComp,
      });
    }
  };

  return (
    <div className="report-screen">
      {(loading && loading1 && (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      )) || (
        <>
          <div className="report-header">
            <div className="report-title">
              <WText typo="h5" weight="light">
                Report Lists
              </WText>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize>
              {() => (
                <Form className="formik-input-report">
                  <div className="date-input input-report">
                    <Field
                      name="searchQuery"
                      type="text"
                      placeholder="Search"
                      value={filterQuery}
                      onChange={(e) => setFilterQuery(e.target.value)}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <SubPhaseHeader title="Pinned" index={1} />
          <div className="border-box report-border-box">
            <div className="row row-custom">
              {!isEmpty(completedFiltered?.filter((cc) => cc.pinned === true)) ? (
                completedFiltered
                  ?.filter((cc) => cc.pinned === true)
                  .map((p, pIdx) => {
                    return (
                      <div className="column phase-column" key={`scenario-${p.name}`}>
                        <h2 className="report-title-custom">{p.name}</h2>
                        <div className="date-select dapi-input-reports phase-select d-ib">
                          <select
                            name="phase"
                            component="select"
                            className="date-input-select"
                            onClick={(e) => onSelectPhaseHandler(p?.id, 'pinned', e.target.value)}
                            onChange={(e) => onSelectPhaseHandler(p?.id, 'pinned', e.target.value)}
                            value={
                              reportPhaseSelected?.pinned[pIdx]?.value ||
                              pinPhaseOptions[pIdx]
                                ?.filter((newPO) => newPO.status === 'DONE')
                                .sort((a, b) => a.value - b.value)[0]?.value
                            }
                          >
                            {pinPhaseOptions[pIdx]?.map((po) => {
                              return (
                                <option
                                  value={po.value}
                                  className="date-input-select-option"
                                  key={po.value}
                                  disabled={po.status !== 'DONE'}
                                >
                                  {po.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div
                          className="d-ib"
                          onMouseEnter={() => {
                            setViewSelected(
                              reportPhaseSelected?.pinned?.find((pi) => pi.id === p?.id).value ||
                                pinPhaseOptions[pIdx]
                                  ?.filter((newPO) => newPO.status === 'DONE')
                                  .sort((a, b) => a.value - b.value)[0]?.value
                            );
                          }}
                        >
                          <ButtonIcon
                            lottie
                            large
                            active
                            icon="view"
                            text="view"
                            clickHandler={() => clickViewHandler(p?.id, 'pinned')}
                          />
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="empty-reports">
                  <FormattedMessage id="reports.empty" />
                </div>
              )}
            </div>
          </div>
          <SubPhaseHeader title="Completed" index={2} />
          <div className="border-box report-border-box">
            <div className="row row-custom">
              {!isEmpty(completedFiltered?.filter((cc) => cc.pinned === false)) ? (
                completedFiltered
                  ?.filter((cc) => cc.pinned === false)
                  .map((p, pIdx) => {
                    return (
                      <div className="column phase-column" key={`scenario-${p.name}`}>
                        <h2 className="report-title-custom">{p.name}</h2>
                        <div className="date-select dapi-input-reports phase-select d-ib">
                          <select
                            name="phase"
                            component="select"
                            className="date-input-select"
                            onClick={(e) => onSelectPhaseHandler(p?.id, 'completed', e.target.value)}
                            onChange={(e) => onSelectPhaseHandler(p?.id, 'completed', e.target.value)}
                            value={
                              reportPhaseSelected?.completed[pIdx]?.value ||
                              completedPhaseOptions[pIdx]
                                ?.filter((newPO) => newPO.status === 'DONE')
                                .sort((a, b) => a.value - b.value)[0]?.value
                            }
                          >
                            {completedPhaseOptions[pIdx]?.map((po) => {
                              return (
                                <option
                                  value={po.value}
                                  className="date-input-select-option"
                                  key={po.value}
                                  disabled={po.status !== 'DONE'}
                                >
                                  {po.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div
                          className="d-ib"
                          onMouseEnter={() =>
                            setViewSelected(
                              reportPhaseSelected?.completed?.find((pi) => pi.id === p?.id).value ||
                                completedPhaseOptions[pIdx]
                                  ?.filter((newPO) => newPO.status === 'DONE')
                                  .sort((a, b) => a.value - b.value)[0]?.value
                            )
                          }
                        >
                          <ButtonIcon
                            lottie
                            large
                            active
                            icon="view"
                            text="view"
                            clickHandler={() => clickViewHandler(p?.id, 'completed')}
                          />
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="empty-reports">
                  <FormattedMessage id="reports.empty" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportsScreen;
