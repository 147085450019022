/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies

// User Types
export const USER_BEGIN = 'USER_BEGIN';
export const USER_ERROR = 'USER_ERROR';
export const USER_RETRIEVE = 'USER_RETRIEVE';
export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_AUTH = 'USER_AUTH';
export const USER_AUTH_ERROR = 'USER_AUTH_ERROR';

// SCENARIO Types
export const SCENARIO_BEGIN = 'SCENARIO_BEGIN';
export const SCENARIO_LOAD_COUNTER = 'SCENARIO_LOAD_COUNTER';
export const SCENARIO_GET_LIST = 'SCENARIO_GET_LIST';
export const SCENARIO_GET_PENDING_LIST = 'SCENARIO_GET_PENDING_LIST';
export const SCENARIO_GET_QUEUED_LIST = 'SCENARIO_GET_QUEUED_LIST';
export const SCENARIO_GET_RUNNING_LIST = 'SCENARIO_GET_RUNNING_LIST';
export const SCENARIO_GET_COMPLETED_LIST = 'SCENARIO_GET_COMPLETED_LIST';
export const SCENARIO_GET_LATEST_COMPLETED_LIST = 'SCENARIO_GET_LATEST_COMPLETED_LIST';
export const SCENARIO_GET_PINNED_LIST = 'SCENARIO_GET_PINNED_LIST';
export const SCENARIO_GET_ARCHIVE_LIST = 'SCENARIO_GET_ARCHIVE_LIST';
export const SCENARIO_ADD_LOADING = 'SCENARIO_ADD_LOADING';
export const SCENARIO_ADD = 'SCENARIO_ADD';
export const SCENARIO_ADD_ERROR = 'SCENARIO_ADD_ERROR';
export const SCENARIO_GET = 'SCENARIO_GET';
export const SCENARIO_PIN = 'SCENARIO_PIN';
export const SCENARIO_UNPIN = 'SCENARIO_UNPIN';
export const SCENARIO_ERROR = 'SCENARIO_ERROR';
export const SCENARIO_UPDATE = 'SCENARIO_UPDATE';
export const SCENARIO_FETCH_INPUTS = 'SCENARIO_FETCH_INPUTS';
export const SCENARIO_FETCH_INPUT = 'SCENARIO_FETCH_INPUT';
export const SCENARIO_UPDATE_INPUT = 'SCENARIO_UPDATE_INPUT';
export const SCENARIO_DUPLICATE_PHASE = 'SCENARIO_DUPLICATE_PHASE';
export const SCENARIO_DOWNLOAD_FILE = 'SCENARIO_DOWNLOAD_FILE';
export const SCENARIO_LAUNCH = 'SCENARIO_LAUNCH';
export const SCENARIO_PAUSE = 'SCENARIO_PAUSE';
export const SCENARIO_GET_DUPLICATE_LOADING = 'SCENARIO_GET_DUPLICATE_LOADING';
export const SCENARIO_GET_DUPLICATE = 'SCENARIO_GET_DUPLICATE';
export const SCENARIO_GET_DUPLICATE_ERROR = 'SCENARIO_GET_DUPLICATE_ERROR';
export const SCENARIO_DUPLICATE_POST_LOADING = 'SCENARIO_DUPLICATE_POST_LOADING';
export const SCENARIO_DUPLICATE_POST = 'SCENARIO_DUPLICATE_POST';
export const SCENARIO_DUPLICATE_POST_ERROR = 'SCENARIO_DUPLICATE_POST_ERROR';
export const SCENARIO_DUPLICATE_NEW = 'SCENARIO_DUPLICATE_NEW';
export const SCENARIO_REFRESH_DATA = 'SCENARIO_REFRESH_DATA';
export const SCENARIO_UPDATE_MSG = 'SCENARIO_UPDATE_MSG';
export const SCENARIO_UPDATE_MSG_RESET = 'SCENARIO_UPDATE_MSG_RESET';
export const SCENARIO_PIN_CHECK = 'SCENARIO_PIN_CHECK ';
export const SCENARIO_PIN_CHECK_RESET = 'SCENARIO_PIN_CHECK_RESET';
export const SCENARIO_LAUNCH_CHECK = 'SCENARIO_LAUNCH_CHECK';
export const SCENARIO_LAUNCH_CHECK_RESET = 'SCENARIO_LAUNCH_CHECK_RESET';
export const SCENARIO_PAUSE_CHECK = 'SCENARIO_PAUSE_CHECK';
export const SCENARIO_PAUSE_CHECK_RESET = 'SCENARIO_PAUSE_CHECK_RESET';
export const SCENARIO_SCREEN_MOUNTED = 'SCENARIO_SCREEN_MOUNTED';
export const SCENARIO_DELETE = 'SCENARIO_DELETE';

// Entities
export const USER_FUNCTIONS_GET = 'USER_FUNCTIONS_GET';
export const USER_FUNCTIONS_ERROR = 'USER_FUNCTIONS_ERROR';
export const USER_FUNCTIONS_BEGIN = 'USER_FUNCTIONS_BEGIN';

// file Types
export const FILE_BEGIN_PHASE_1 = 'FILE_BEGIN_PHASE_1';
export const FILE_GET_PHASE_1 = 'FILE_GET_PHASE_1';
export const FILE_POST_PHASE_1 = 'FILE_POST_PHASE_1';
export const FILE_ERROR_PHASE_1 = 'FILE_ERROR_PHASE_';
export const FILE_BEGIN_PHASE_2 = 'FILE_BEGIN_PHASE_2';
export const FILE_GET_PHASE_2 = 'FILE_GET_PHASE_2';
export const FILE_POST_PHASE_2 = 'FILE_POST_PHASE_2';
export const FILE_ERROR_PHASE_2 = 'FILE_ERROR_PHASE_2';
export const FILE_EXCEL_LOADING_PHASE_1 = 'FILE_EXCEL_LOADING_PHASE_1';
export const FILE_EXCEL_LOADING_PHASE_2 = 'FILE_EXCEL_LOADING_PHASE_2';

// report Types
export const REPORT_BEGIN = 'REPORT_BEGIN';
export const REPORT_SCENARIO_GET = 'REPORT_SCENARIO_GET';
export const REPORT_PHASE_GET = 'REPORT_PHASE_GET';
export const REPORT_WORKBOOK_GET = 'REPORT_WORKBOOK_GET';
export const REPORT_WORKSHEET_GET = 'REPORT_WORKSHEET_GET';
export const REPORT_ERROR = 'REPORT_ERROR';

// notification Types
export const NOTIFICATION_GET = 'NOTIFICATION_GET';
export const NOTIFICATION_POST = 'NOTIFICATION_POST';
export const NOTIFICATION_BEGIN = 'NOTIFICATION_BEGIN';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';

// admin types
export const ADMIN_START = 'ADMIN_START';
export const ADMIN_GET = 'ADMIN_GET';
export const ADMIN_POST = 'ADMIN_POST';
export const ADMIN_ERROR = 'ADMIN_ERROR';
export const NOTIFICATION_COUNT_GET = 'NOTIFICATION_COUNT_GET';
export const NOTIFICATION_READ_POST = 'NOTIFICATION_READ_POST';
export const USER_IS_ACTIVE = 'USER_IS_ACTIVE';
export const USER_IS_SUPERUSER = 'USER_IS_SUPERUSER';

export const GET_USER_ROLE = 'GET_USER_ROLE';
export const GET_USER_FUNCTION = 'GET_USER_FUNCTION';
export const POST_USER = 'POST_USER';
export const UDPATE_USER = 'UDPATE_USER';
export const SELECTED_USER_ID = 'SELECTED_USER_ID';
export const SELECTED_USER_DETAILS = 'SELECTED_USER_DETAILS';

export const CLUSTER_STATUS_GET = 'CLUSTER_STATUS_GET';
export const SCENARIO_GET_ALL_STATUS = 'SCENARIO_GET_ALL_STATUS';
export const SCENARIO_CHECK_INTERVAL_VALUE = 'SCENARIO_CHECK_INTERVAL_VALUE';
export const CLUSTER_STATUS_CHECK_INTERVAL_VALUE = 'CLUSTER_STATUS_CHECK_INTERVAL_VALUE';
