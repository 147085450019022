import Lottie from 'react-lottie-player';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

import configs from './lottieList';

// Icon types
const iconTypes = ['notification', 'pin', 'archive', 'unarchive', 'uploadFile', 'cancel'];

// Icon statuss
const iconStatus = [
  'new-notification',
  'no-new-notification',
  'pin',
  'unpin',
  'archive',
  'unarchive',
  'loading',
  'success',
  'error',
  'initial',
  'deleteexcelupload',
];

// To get icon type details
const lottieDetails = (icon) => {
  const config = configs[`${icon}`];
  if (config) {
    const { file, size, states } = config;
    return {
      file,
      width: size?.width || 40,
      height: size?.height || 40,
      states,
      segment: [],
    };
  }
  return undefined;
};

// eslint-disable-next-line
const renderLottie = (loop, play, animationData, style, segment) => {
  return (
    <Lottie
      loop={loop}
      play={play || true}
      speed={0.4}
      animationData={animationData}
      style={style}
      segments={segment}
    />
  );
};

const lottieIconGetDetails = (iconConfig) => {
  const notificatoinLottie = () => {
    const details = lottieDetails(iconConfig.iconType);

    if (isUndefined(details)) return [];

    switch (iconConfig.iconStatus.toLowerCase()) {
      case iconStatus[0].toLowerCase():
        details.segment = details.states.ON.to.ON_HOVER;
        break;
      case iconStatus[1].toLowerCase():
        details.segment = details.states.OFF.to.OFF_HOVER;
        break;
      case iconStatus[2].toLowerCase():
        details.segment = details.states.ON_HOVER.to.ON;
        break;
      case iconStatus[3].toLowerCase():
        details.segment = details.states.OFF.to.OFF_HOVER;
        break;
      case iconStatus[4].toLowerCase():
        details.segment = iconConfig.iconHover ? details.states.OFF.to.OFF_HOVER : details.states.OFF.to.OFF_HOVER;
        break;
      case iconStatus[5].toLowerCase():
        details.segment = iconConfig.iconHover ? details.states.ON.to.ON_HOVER : details.states.OFF.to.OFF_HOVER;
        break;
      case iconStatus[6].toLowerCase():
        details.segment = iconConfig.iconHover ? details.states.LOADING.loop : details.states.LOADING.loop;
        break;
      case iconStatus[7].toLowerCase():
        details.segment = iconConfig.iconHover
          ? details.states.SUCCESS.to.LOADING_STILL
          : details.states.SUCCESS.to.LOADING_STILL;
        break;
      case iconStatus[8].toLowerCase():
        details.segment = iconConfig.iconHover
          ? details.states.ERROR.to.DEFAULT_STILL
          : details.states.ERROR.to.DEFAULT_STILL;
        break;
      case iconStatus[9].toLowerCase():
        details.segment = iconConfig.iconHover ? details.states.DEFAULT.to.INITIAL : details.states.DEFAULT.to.INITIAL;
        break;
      case iconStatus[10].toLowerCase():
        details.segment = details.states.OFF.to.OFF_HOVER;
        break;
      default:
        details.segment = [0, 1000];
        break;
    }

    return renderLottie(
      iconConfig.loop,
      iconConfig.play,
      details.file,
      { height: details.height, width: details.width },
      details.segment
    );
  };

  return notificatoinLottie();
};

const LottieIconWrapper = ({ iconConfig }) => {
  const renderLottieIcon = () => {
    let component = [];
    switch (iconConfig.iconType.toLowerCase()) {
      case iconTypes[0].toLowerCase():
      case iconTypes[1].toLowerCase():
      case iconTypes[2].toLowerCase():
      case iconTypes[3].toLowerCase():
      case iconTypes[4].toLowerCase():
      case iconTypes[5].toLowerCase():
        component = lottieIconGetDetails(iconConfig);
        break;
      default:
        component = [];
        break;
    }
    return component;
  };

  return renderLottieIcon();
};

export { iconTypes, iconStatus, LottieIconWrapper };

LottieIconWrapper.propTypes = {
  iconConfig: PropTypes.shape({
    iconType: PropTypes.string,
    iconStatus: PropTypes.string,
    loop: PropTypes.oneOf([PropTypes.number, PropTypes.bool, PropTypes.any, true, false]),
    play: PropTypes.bool,
    iconHover: PropTypes.bool,
  }).isRequired,
};

// LottieIconWrapper.defaultProps = {
// };
