/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog as faUser } from '@fortawesome/fontawesome-free-solid';

// local dependencies
import './sidebar.scss';
import '../../theme/_icons.scss';
import WDivider from '../WDivider/WDivider';
import routes from '../../utils/routes';
import { routesMain, routesAction, routesStatus, routesUser } from '../../utils/routes';

const activeClassName = 'sidebar-item active router-link-active';

const SideBar = ({ setIsOpen }) => {
  const intl = useIntl();
  const { data: authUser, authCheckComplete } = useSelector((state) => state.users);
  const { isScreenMounted: scenarioScreenMounted } = useSelector((state) => state.scenarios);

  const userRole = authCheckComplete ? authUser?.role?.name : null
  // const [userRole, setUserRole] = useState(null);

  const getRouteRoles = (routePath) => {
    return routes?.filter((route) => route.path === routePath)?.[0]?.allowedRoles;
  };
  // useEffect(() => {
  //   if (authCheckComplete && authUser?.role?.name) {
  //     setUserRole(authUser?.role?.name);
  //   }
  // }, [authUser]);

  return (
    <div className="sidebar">
      <div className="links">
        <button type="button" className="WButton action primary small animated" onClick={() => setIsOpen(true)}>
          <div className="content">
            <div className="add" />
            {intl.formatMessage({ id: 'scenario.newSimulation.title' })}
          </div>
        </button>
        <div className={`${scenarioScreenMounted && 'navigation-item-scroll'}`}>
          
          {routesMain.map((route, index) => {
            if (route.allowedRoles.includes(userRole)){
              return (
                <NavLink to={route.path} activeclassname={activeClassName} className="sidebar-item">
                  <i className={`icon-space icon ${route.icon}`} style={{ fontSize: '1rem' }} /> {' '}
                  {intl.formatMessage({ id: `navigation.${route.label}` })}
                </NavLink>
              )
            }
          })}

          <WDivider withMargin />
          
          {routesStatus.map((route, index) => {
            if (route.allowedRoles.includes(userRole)){
              return (
                <NavLink to={route.path} activeclassname={activeClassName} className="sidebar-item">
                  <i className={`icon-space icon ${route?.icon}`} style={{ fontSize: '1rem' }} /> {' '}
                  {intl.formatMessage({ id: `navigation.${route.label}` })}
                </NavLink>
              )
            }
          })}

          <WDivider withMargin />

          {routesAction.map((route, index) => {
            if (route.allowedRoles.includes(userRole)){
              return (
                <NavLink to={route.path} activeclassname={activeClassName} className="sidebar-item">
                  <i className={`icon-space icon ${route?.icon}`} style={{ fontSize: '1rem' }} /> {' '}
                  {intl.formatMessage({ id: `navigation.${route.label}` })}
                </NavLink>
              )
            }
          })}
          
          {getRouteRoles('/users')?.includes(userRole) && (
            <>
            <WDivider withMargin />
            {routesUser.map((route, index) => {
              if (route.allowedRoles.includes(userRole)){
                return (
                  <NavLink to={route.path} activeclassname={activeClassName} className="sidebar-item">
                    <FontAwesomeIcon
                    icon={faUser}
                    className="icon-space icon"
                    style={{ fontSize: '1rem', marginRight: '11px' }}
                  />
                    {intl.formatMessage({ id: `navigation.${route.label}` })}
                  </NavLink>
                )
              }
            })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;

SideBar.propTypes = {
  setIsOpen: PropTypes.func,
};

SideBar.defaultProps = {
  setIsOpen: () => { },
};
