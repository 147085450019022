export default {
  titles: {
    scenarios: 'Scenarios',
    queue: 'Queue',
  },
  header: {
    name: 'name',
    date: 'date',
    progress: 'progress',
    actions: 'actions',
  },
  viewAll: 'View all',
  emptyList: 'Currently there are no scenarios on this tab',
  error: 'Unexpected error. Please, try again or contact us.',
  retry: 'Retry',
  empty: 'There aren’t scenarios created',
  newSimulation: 'New simulation',
  fields: {
    status: 'Configuration data status',
    ETLDataStatus: 'ETL data status',
    name: 'Name',
    createdAt: 'Date',
    author: 'Author',
    outputs: 'Outputs',
    process: 'Process',
    progress: 'Progress',
    view: 'View',
    duplicate: 'Duplicate',
  },
  filters: {
    statuses: {
      placeholder: 'Status',
      RUNNING: 'Running',
      IDLE: 'Idle',
      DONE: 'Done',
    },
    outputs: {
      1: 'Fase 1',
      2: 'Fase 2',
      3: 'Fase 3',
    },
  },
  process: {
    edit: 'Edit {process}',
    run: 'Run {process}',
  },
  phases: {
    phaseOne: 'F1',
    phaseTwo: 'F2',
    phaseThree: 'F3',
  },
  tooltips: {
    ETLDataStatus: {
      valid: 'This scenario has a valid base',
      expired: 'This scenario has an outdated base',
    },
  },
};
