/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import { find, isEmpty } from 'lodash';
import scenarioAlert from '../../i18n/locale/en/scenario';
import axios from '../../utils/axios';
import {
  SCENARIO_BEGIN,
  SCENARIO_GET_RUNNING_LIST,
  SCENARIO_GET_QUEUED_LIST,
  SCENARIO_GET_PENDING_LIST,
  SCENARIO_GET_COMPLETED_LIST,
  SCENARIO_GET_LATEST_COMPLETED_LIST,
  SCENARIO_GET_PINNED_LIST,
  SCENARIO_GET_ARCHIVE_LIST,
  SCENARIO_ADD,
  SCENARIO_GET,
  SCENARIO_PIN,
  SCENARIO_UNPIN,
  SCENARIO_ERROR,
  SCENARIO_UPDATE,
  SCENARIO_FETCH_INPUTS,
  SCENARIO_UPDATE_INPUT,
  SCENARIO_FETCH_INPUT,
  SCENARIO_LAUNCH,
  SCENARIO_PAUSE,
  SCENARIO_GET_DUPLICATE_LOADING,
  SCENARIO_GET_DUPLICATE,
  SCENARIO_GET_DUPLICATE_ERROR,
  SCENARIO_DUPLICATE_POST_LOADING,
  SCENARIO_DUPLICATE_POST,
  SCENARIO_DUPLICATE_POST_ERROR,
  SCENARIO_ADD_LOADING,
  SCENARIO_ADD_ERROR,
  SCENARIO_GET_LIST,
  SCENARIO_REFRESH_DATA,
  SCENARIO_UPDATE_MSG,
  SCENARIO_PIN_CHECK,
  SCENARIO_LAUNCH_CHECK,
  SCENARIO_PAUSE_CHECK,
  SCENARIO_SCREEN_MOUNTED,
  SCENARIO_GET_ALL_STATUS,
  SCENARIO_CHECK_INTERVAL_VALUE,
  CLUSTER_STATUS_CHECK_INTERVAL_VALUE,
  SCENARIO_DELETE,
} from '../actionTypes';

const getScenarioList = (statusFilter = 'IDLE', archived = false, latest = false) => {
  const filterDataResults = (data, status) => {
    const result = data.map((dt) => {
      const filterData = dt.inputs.filter((ip) => `${ip.status}`.toLowerCase() === status.toLowerCase());
      return !isEmpty(filterData) && dt;
    });
    const finalData = result.filter((rs) => rs !== false);
    return finalData;
  };

  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      // dispatch({ type: SCENARIO_BEGIN });
      let url;

      if (statusFilter === 'ALLSTATUS') {
        url = [
          `/api/scenarios/?status=IDLE&archived=false`,
          `/api/scenarios/?status=DONE&archived=false`,
          `/api/scenarios/?status=RUNNING&archived=false`,
          `/api/scenarios/?status=QUEUED&archived=false`,
          `/api/scenarios/?status=&archived=true`,
        ];
      } else if (statusFilter === 'ALL') {
        url = '/api/scenarios/';
      } else if (latest) {
        url = `/api/scenarios/?status=${statusFilter}&latest=${latest}`;
      } else {
        url = `/api/scenarios/?status=${statusFilter}&archived=${archived}`;
      }

      // calling all the apis for all the status to update the reducer
      if (statusFilter === 'ALLSTATUS') {
        const { data: idleData } = await axios.get(url[0]);
        const { data: doneData } = await axios.get(url[1]);
        const { data: runningData } = await axios.get(url[2]);
        const { data: queuedData } = await axios.get(url[3]);
        const { data: archiveData } = await axios.get(url[4]);

        dispatch({ type: SCENARIO_GET_PENDING_LIST, payload: { data: idleData } });
        dispatch({ type: SCENARIO_GET_COMPLETED_LIST, payload: { data: doneData } });
        dispatch({ type: SCENARIO_GET_RUNNING_LIST, payload: { data: runningData } });
        dispatch({ type: SCENARIO_GET_QUEUED_LIST, payload: { data: queuedData } });
        return dispatch({ type: SCENARIO_GET_ARCHIVE_LIST, payload: { data: archiveData } });

        // axios.spread((idleData, doneData, runningData, queuedData, archiveData, pinData) => {
        //   console.log({ idleData, doneData, runningData, queuedData, archiveData, pinData });
        //   dispatch({ type: SCENARIO_GET_PENDING_LIST, payload: { data: idleData } });
        //   dispatch({ type: SCENARIO_GET_COMPLETED_LIST, payload: { data: doneData } });
        //   dispatch({ type: SCENARIO_GET_RUNNING_LIST, payload: { data: runningData } });
        //   dispatch({ type: SCENARIO_GET_QUEUED_LIST, payload: { data: queuedData } });
        //   return dispatch({ type: SCENARIO_GET_ARCHIVE_LIST, payload: { data: archiveData } });
        // })
      }

      // console.log('API call:', url);
      const { data } = await axios.get(url);

      if (archived) {
        return dispatch({ type: SCENARIO_GET_ARCHIVE_LIST, payload: { data } });
      }
      if (statusFilter === 'ALL') {
        return dispatch({ type: SCENARIO_GET_LIST, payload: { data: filterDataResults(data, 'DONE') } });
      }
      if (statusFilter === 'IDLE') {
        return dispatch({ type: SCENARIO_GET_PENDING_LIST, payload: { data } });
      }
      if (statusFilter === 'DONE') {
        if (latest) return dispatch({ type: SCENARIO_GET_LATEST_COMPLETED_LIST, payload: { data } });
        return dispatch({ type: SCENARIO_GET_COMPLETED_LIST, payload: { data } });
      }
      if (statusFilter === 'RUNNING') {
        return dispatch({ type: SCENARIO_GET_RUNNING_LIST, payload: { data } });
      }
      return dispatch({ type: SCENARIO_GET_QUEUED_LIST, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const getParsedData = (data) => {
  // parsing data from string to json
  const inputsList = data?.inputs.map((dt) => {
    const parsedData = JSON.parse(dt.data);
    return {
      ...dt,
      data: parsedData,
    };
  });

  const getData = {
    ...data,
    inputs: inputsList,
  };

  return getData;
};

const addScenario = (postData) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_ADD_LOADING });

      const url = '/api/scenarios/';
      // console.log('API call:', url);
      const { data } = await axios.post(url, postData);

      return dispatch({ type: SCENARIO_ADD, payload: { data: getParsedData(data) } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ADD_ERROR, payload: { error } });
    }
  };
};

const getScenario = (id, showProgress = true) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      if (showProgress) dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${id}/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);

      return dispatch({ type: SCENARIO_GET, payload: { data: getParsedData(data) } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const updateScenario = (id, updateScenarioData) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      let dataMsg = '';

      if (updateScenarioData?.name) dataMsg = scenarioAlert.alertMsg.name.update;
      if (updateScenarioData?.archived === true) dataMsg = scenarioAlert.alertMsg.archive.archive;
      if (updateScenarioData?.archived === false) dataMsg = scenarioAlert.alertMsg.archive.unarchive;
      if (updateScenarioData?.function) dataMsg = scenarioAlert.alertMsg.function.update;

      dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${id}/`;

      const { data } = await axios.patch(url, updateScenarioData);

      dispatch({ type: SCENARIO_UPDATE, payload: { update: data, data: getParsedData(data) } });

      return dispatch({
        type: SCENARIO_UPDATE_MSG,
        payload: {
          data: {
            response: {
              status: 200,
              data: {
                message: `${dataMsg}`,
              },
            },
          },
        },
      });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const getPinnedListScenario = () => {
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_BEGIN });

      const url = '/api/scenarios/?archived=false';
      // console.log('API call:', url);
      const { data } = await axios.get(url);

      // filtering only the pinned list
      const newData = data.filter((dt) => dt.pinned === true);

      return dispatch({ type: SCENARIO_GET_PINNED_LIST, payload: { data: newData } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const pinScenario = (id) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${id}/pin/`;
      // console.log('API call:', url);
      const { data } = await axios.put(url);

      dispatch({ type: SCENARIO_PIN, payload: { data } });
      return dispatch({
        type: SCENARIO_PIN_CHECK,
        payload: {
          data: {
            response: {
              status: 200,
              data: {
                message: `${
                  data.pinned ? scenarioAlert.alertMsg.pinned.pinned : scenarioAlert.alertMsg.pinned.unpinned
                }`,
              },
            },
          },
        },
      });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const unpinScenario = (id) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${id}/unpin/`;
      // console.log('API call:', url);
      const { data } = await axios.delete(url);

      dispatch({ type: SCENARIO_UNPIN, payload: { data } });
      return dispatch({
        type: SCENARIO_PIN_CHECK,
        payload: {
          data: {
            response: {
              status: 200,
              data: {
                message: `${
                  data.pinned ? scenarioAlert.alertMsg.pinned.pinned : scenarioAlert.alertMsg.pinned.unpinned
                }`,
              },
            },
          },
        },
      });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const getScenarioInputs = (scenarioId) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${scenarioId}/inputs/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      // parsing data from string to json
      const inputsList = data?.map((dt) => JSON.parse(dt.data));

      return dispatch({ type: SCENARIO_FETCH_INPUTS, payload: { data: inputsList } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const getScenarioInput = (inputId, scenarioId) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${scenarioId}/inputs/${inputId}/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: SCENARIO_FETCH_INPUT, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};
const deleteScenario = (id) => {
  return async (dispatch) => {
    dispatch({ type: SCENARIO_DELETE });
    try {
      const url = `/api/scenarios/${id}/drop/`;
      await axios.delete(url);
    } catch (error) {
      return dispatch({ type: SCENARIO_ERROR, payload: { error: 'Error' } });
    }
  };
};

const updateScenarioInput = (inputId, scenarioId, updateData) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${scenarioId}/inputs/${inputId}/`;
      // console.log('API call:', url);
      const { data } = await axios.patch(url, updateData);

      return dispatch({ type: SCENARIO_UPDATE_INPUT, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const launchScenario = ({ id, restart = false, allPhases = false }) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${id}/simulations/`;
      // console.log('API call:', url);
      const { data } = await axios.put(url, { restart, allPhases });

      dispatch({ type: SCENARIO_LAUNCH, payload: { data } });
      return dispatch({
        type: SCENARIO_LAUNCH_CHECK,
        payload: { data: { response: { status: 200, data: { message: scenarioAlert.alertMsg.launch.launch } } } },
      });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const pauseScenario = (id) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_BEGIN });

      const url = `/api/scenarios/${id}/simulations/pause/`;
      // console.log('API call:', url);
      const { data } = await axios.put(url);
      dispatch({ type: SCENARIO_PAUSE, payload: { data } });
      return dispatch({
        type: SCENARIO_PAUSE_CHECK,
        payload: { data: { response: { status: 200, data: { message: scenarioAlert.alertMsg.pause.pause } } } },
      });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const getforDuplicateScenario = (id) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_GET_DUPLICATE_LOADING });

      // getting the detail scenario and phases/inputs
      const url = `/api/scenarios/${id}/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      // parsing data from string to json
      const inputsList = data?.inputs.map((dt) => {
        const parsedData = JSON.parse(dt.data);
        return {
          ...dt,
          data: parsedData,
        };
      });

      const phaseOneId = find(inputsList, { phase_number: 1 }).id;
      const phaseTwoId = find(inputsList, { phase_number: 2 }).id;

      const input1Url = `/api/scenarios/${id}/inputs/${phaseOneId}/files/`;
      // console.log('API call:', input1Url);
      const { data: inputFile1Data } = await axios.get(input1Url);

      const input2Url = `/api/scenarios/${id}/inputs/${phaseTwoId}/files/`;
      // console.log('API call:', input2Url);
      const { data: inputFile2Data } = await axios.get(input2Url);

      const getData = [data, inputsList, inputFile1Data, inputFile2Data];
      return dispatch({ type: SCENARIO_GET_DUPLICATE, payload: { data: getData } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_GET_DUPLICATE_ERROR, payload: { error } });
    }
  };
};

const postDuplicateScenario = (id, postData) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: SCENARIO_DUPLICATE_POST_LOADING });

      const url = `/api/scenarios/${id}/duplicate/`;
      // console.log('API call:', url);
      const { data } = await axios.post(url, postData);

      return dispatch({ type: SCENARIO_DUPLICATE_POST, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_DUPLICATE_POST_ERROR, payload: { error } });
    }
  };
};

const refreshScenarioData = (scenario, pin, archive) => {
  return async (dispatch) => {
    return dispatch({ type: SCENARIO_REFRESH_DATA, payload: { scenario, pin, archive } });
  };
};

const updateScreenMountFlag = (mounted) => {
  return async (dispatch) => {
    return dispatch({ type: SCENARIO_SCREEN_MOUNTED, payload: mounted });
  };
};

const getAllScenariosStatus = () => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      const url = `/api/scenario-status/`;
      const { data } = await axios.get(url);
      return dispatch({ type: SCENARIO_GET_ALL_STATUS, payload: data });
    } catch (error) {
      // Handle error.
      return dispatch({ type: SCENARIO_ERROR, payload: { error } });
    }
  };
};

const setScenarioCheckIntervalValue = (value) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      return dispatch({ type: SCENARIO_CHECK_INTERVAL_VALUE, payload: value });
    } catch (error) {
      console.log(error);
    }
  };
};

const setClusterStatusCheckIntervalValue = (value) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      return dispatch({ type: CLUSTER_STATUS_CHECK_INTERVAL_VALUE, payload: value });
    } catch (error) {
      console.log(error);
    }
  };
};

export {
  getScenarioList,
  addScenario,
  getScenario,
  getPinnedListScenario,
  pinScenario,
  unpinScenario,
  updateScenario,
  getScenarioInputs,
  getScenarioInput,
  updateScenarioInput,
  launchScenario,
  pauseScenario,
  getforDuplicateScenario,
  postDuplicateScenario,
  refreshScenarioData,
  updateScreenMountFlag,
  getAllScenariosStatus,
  setScenarioCheckIntervalValue,
  setClusterStatusCheckIntervalValue,
  deleteScenario,
};
