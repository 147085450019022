/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party
import PropTypes from "prop-types";

// local
import "./WAvatar.scss";
import WText from "../WText/Wtext";

const WAvatar = ({ name, pic, clickable, medium, small, large, customHoverContent, customContent }) => {
  const color = () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const hue = ((name?.charCodeAt(0) - 65) * (90 - 65)) % 360;
    return `hsl(${hue},30%,80%)`;
  };

  const text = () => {
    return name
      ?.split(" ")
      .map((word) => word.slice(0, 1))
      .join("")
      .slice(0, 2)
      .toUpperCase();
  };

  return (
    <div
      className={`wrapper ${clickable ? "clickable" : ""} ${small ? "small" : ""} ${medium ? "medium" : ""} ${large ? "large" : ""}`}>
      {customHoverContent && <div className="custom-hover-content">
        {customHoverContent}
      </div>}
      {customContent && <div className="custom-content">
        {customContent}
      </div>}
      {pic ? (
        <img src={pic}
             className={`${small ? "small" : ""} ${medium ? "medium" : ""} ${large ? "large" : ""} ${customHoverContent ? "custom-content" : ""}`}
             alt="photo_url" />
      ) : (
        <div
          className={`WAvatar ${small ? "small" : ""} ${medium ? "medium" : ""} ${large ? "large" : ""} ${customHoverContent ? "custom-content" : ""}`}
          style={{ backgroundColor: color() }}
        >
          <WText typo={small ? "caption" : "body"} color="grey01">
            {text()}
          </WText>
        </div>
      )}
    </div>
  );
};

export default WAvatar;

WAvatar.propTypes = {
  name: PropTypes.string,
  pic: PropTypes.string,
  clickable: PropTypes.bool,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool
};

WAvatar.defaultProps = {
  name: "",
  pic: "",
  clickable: true,
  large: false,
  medium: false,
  small: false
};
