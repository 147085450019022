/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// local dependencies
import archiveFile from '../../assets/lottie/actions/archive.json';
import archiveConfig from '../../assets/lottie/actions/archiveConfig.json';
import unarchiveConfig from '../../assets/lottie/actions/unarchiveConfig.json';
import cancelFile from '../../assets/lottie/actions/cancel.json';
import cancelConfig from '../../assets/lottie/actions/cancelConfig.json';
import duplicateFile from '../../assets/lottie/actions/duplicate.json';
import duplicateConfig from '../../assets/lottie/actions/duplicateConfig.json';
import editFile from '../../assets/lottie/actions/edit.json';
import editConfig from '../../assets/lottie/actions/editConfig.json';
import launchFile from '../../assets/lottie/actions/launch.json';
import launchConfig from '../../assets/lottie/actions/launchConfig.json';
import pinFile from '../../assets/lottie/actions/pin.json';
import pinConfig from '../../assets/lottie/actions/pinConfig.json';
import saveFile from '../../assets/lottie/actions/save.json';
import saveConfig from '../../assets/lottie/actions/saveConfig.json';
import stopFile from '../../assets/lottie/actions/stop.json';
import stopConfig from '../../assets/lottie/actions/stopConfig.json';
import viewFile from '../../assets/lottie/actions/view.json';
import viewConfig from '../../assets/lottie/actions/viewConfig.json';
import notificationFile from '../../assets/lottie/notifications/notification.json';
import notificationConfig from '../../assets/lottie/notifications/notificationConfig.json';
import viewReport from '../../assets/lottie/actions/reports.json';
import reportsConfig from '../../assets/lottie/actions/reportsConfig.json';
import uploadFile from '../../assets/lottie/uploadFile/uploadFile.json';
import uploadFileConfig from '../../assets/lottie/uploadFile/uploadFileConfig.json';

const configs = {
  archive: {
    file: archiveFile,
    ...archiveConfig,
  },
  unarchive: {
    file: archiveFile,
    ...unarchiveConfig,
  },
  cancel: {
    file: cancelFile,
    ...cancelConfig,
  },
  duplicate: {
    file: duplicateFile,
    ...duplicateConfig,
  },
  edit: {
    file: editFile,
    ...editConfig,
  },
  pin: {
    file: pinFile,
    ...pinConfig,
  },
  launch: {
    file: launchFile,
    ...launchConfig,
  },
  save: {
    file: saveFile,
    ...saveConfig,
  },
  stop: {
    file: stopFile,
    ...stopConfig,
  },
  view: {
    file: viewFile,
    ...viewConfig,
  },
  notification: {
    file: notificationFile,
    ...notificationConfig,
  },
  reports: {
    file: viewReport,
    ...reportsConfig,
  },
  uploadfile: {
    file: uploadFile,
    ...uploadFileConfig,
  }
};

export default configs;
