import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle, Tooltip, useMapEvents } from 'react-leaflet';
import * as d3 from 'd3';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './Summary.scss';
import Filters from '../../../../components/Filters/Filters';
import axios from '../../../../utils/axios';
import { WSpinnerLarge } from '../../../../components/WLoader/WSpinner';
import { formatNumber, convertToTitleCase, areObjectsEqual } from '../../../../utils/utils';

// Fix default icon issue
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Define categories and their icons
const categories = {
  CategoryA: L.icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
  CategoryB: L.icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
  CategoryC: L.icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
};

// Example data with categories
// const points = [
//   { id: 1, name: 'Point 1', position: [51.505, -0.09], category: 'CategoryA' },
//   { id: 2, name: 'Point 2', position: [51.515, -0.1], category: 'CategoryB' },
//   { id: 3, name: 'Point 3', position: [51.52, -0.12], category: 'CategoryC' },
// ];

// const filterData = {
//   color: { type: 'select', values: ['Red', 'Green', 'Blue'] },
//   size: { type: 'select', values: ['Small', 'Medium', 'Large'] },
//   price: { type: 'range', values: [0, 100] }, // Range from 0 to 100
// };

const Map = ({ scenarioID }) => {
  const [points, setPoints] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState(null); // State to track tooltip

  const handleFilterChange = (key, type, selectedValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        type,
        values: selectedValues,
      },
    }));
  };

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/phase3-map/';
    axios
      .post(url, filters, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setPoints(response.data.data);
        setFilterData(response.data.filters);
        Object.keys(response.data.filters).forEach((key) => {
          const type = response.data.filters[key].type;
          const values = response.data.filters[key].values;
          handleFilterChange(key, type, values);
        });
        setLoading(false);
      });
  }, []);

  const applyFilters = () => {
    setLoading(true);
    const url = '/api/charts/phase3-map/';
    const filtersBody = areObjectsEqual(filters, filterData) ? {} : filters;
    axios
      .post(url, filtersBody, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setPoints(response.data.data);
        // setFilterData(response.data.filters);
        setLoading(false);
      });
  };

  const renderTable = (data) => {
    if (!data) return null;
    return (
      <div className="custom-tooltip">
        <table
          style={{
            borderCollapse: 'collapse',
            fontSize: '10px',
            // backgroundColor: '#000',
            // margin: '10px 0',
            width: 'max-content',
          }}
        >
          <tbody>
            {Object.keys(data)
              .filter((key) => key !== 'id' && key !== 'position')
              .map((key) => (
                <tr key={key} style={{ padding: '0px', margin: '0px' }}>
                  <td style={{ color: '#000000' }}>
                    <strong>{convertToTitleCase(key)}:</strong>
                  </td>
                  <td style={{ color: '#000000' }}>
                    {typeof data[key] === 'number' ? formatNumber(data[key]) : data[key]}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  const maxValue = Math.max(...points.map((d) => d.revenue_generated));

  const radiusScale = d3.scaleSqrt().domain([0, maxValue]).range([0, 10000]);

  const colorScale = d3
    .scaleOrdinal()
    .domain(points.map((d) => d.action_type))
    .range(d3.schemeCategory10);

  // Bounding box for Spain
  const bounds = [
    [43.791356, -1.389385], // top-left corner (north-west)
    [35.94685, 4.316111], // bottom-right corner (south-east)
  ];

  // Custom hook to track mouse position
  const MapEvents = () => {
    useMapEvents({
      mousemove(e) {
        setTooltipPosition(e.latlng);
      },
    });
    return null;
  };

  return (
    <div className="container1">
      {/* {isOpen && <FiltersModal isOpen={isOpen} openHandler={openHandler} filterData={filterData} />} */}
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div style={{ flex: 1 }}>
            <Filters
              data={filterData}
              handleFilterChange={handleFilterChange}
              filters={filters}
              applyFilters={applyFilters}
            />
          </div>
          <div style={{ flex: 10, paddingRight: '10px' }}>
            <MapContainer bounds={bounds} style={{ height: '100vh', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {points.map((point) => (
                <Circle
                  key={point.id}
                  center={[point['position'][0], point['position'][1]]}
                  radius={radiusScale(point.revenue_generated)} // Adjust size as needed
                  color={colorScale(point.action_type)}
                  fillOpacity={1}
                  opacity={1}
                  eventHandlers={{
                    mouseover: () => setHoveredPoint(point),
                    mouseout: () => setHoveredPoint(null),
                  }}
                >
                  {/* <Popup>{renderTable(point)}</Popup> */}
                  {hoveredPoint && hoveredPoint.id === point.id && tooltipPosition && (
                    // <Popup position={hoveredPoint.position} autoPan={false}>
                    //   {renderTable(point)}
                    // </Popup>
                    <Tooltip position={tooltipPosition} offset={[0, -10]} direction="top" permanent>
                      {renderTable(point)}
                    </Tooltip>
                  )}
                </Circle>
              ))}
              <MapEvents />
            </MapContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default Map;
