/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

// local dependencies
import WorkBooksList from '../WorkBooksList/WorkBooksList';
import { getReportPhase } from '../../redux/actions';
import PhaseSkeleton from '../PhaseSkeleton/PhaseSkeleton';
import './PhasesList.scss';

const PhasesList = ({scenarioDetails}) => {
  // navigate
  const navigate = useNavigate();

  // params
  const { id, phaseID } = useParams();

  // local state
  const [selectedPhase, setSelectedPhase] = useState(phaseID || 1);

  // global state
  const { loading, phase } = useSelector((state) => state.reports);

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportPhase());
  }, []);

  const phaseSelected = (idx) => {
    setSelectedPhase(idx + 1);
  };

  const phaseElement = phase
    ?.filter((p) => p.is_active === true)
    .filter((p) => p.id <= phaseID)
    .map((phaseItem, idx) => {
      return (
        <div className={`subnav ${clsx({ active: selectedPhase - 1 === idx })}`} key={phaseItem.name}>
          <button type="button" className="subnavbtn" onClick={() => phaseSelected(idx)}>
            {phaseItem.name}
          </button>
        </div>
      );
    });

  return (
    <>
      <div className="phase-navbar">
        {loading && <PhaseSkeleton />}
        {phaseElement}
      </div>
      <WorkBooksList selectedPhaseID={selectedPhase} scenarioDetails={scenarioDetails} />
    </>
  );
};

export default PhasesList;
