/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import React from 'react';

// local dependencies

const ScenarioContext = React.createContext();

export default ScenarioContext;
