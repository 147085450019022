import actions from './actions';
import error from './error';
import general from './general';
import meta from './meta';
import navigation from './navigation';
import validation from './validation';
import scenario from './scenario';
import scenarios from './scenarios';
import auth from './auth';
import modals from './modals';
import phase from './phase';
import workspace from './workspace';
import notifications from './notifications';
import duplicate from './duplicate';
import reports from './reports';

export default {
  actions,
  general,
  error,
  meta,
  navigation,
  validation,
  scenario,
  scenarios,
  auth,
  modals,
  phase,
  workspace,
  notifications,
  duplicate,
  reports,
};
