import React from 'react';
import Chart from 'react-apexcharts';

const HeatmapChart = ({ series, options, title, height, width }) => {
  return (
    <>
      <h6>{title}</h6>
      <Chart options={options} series={series} type="heatmap" height={height} width={width} />
    </>
  );
};

export default HeatmapChart;
