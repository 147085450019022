import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { isNumber, isString } from 'lodash';
import { postUser, updateUser, getSelectedUserDetails } from '../../redux/actions/adminActions';
import './UserModal.scss';
import WAvatar from '../WAvatar/WAvatar';
import { isValNumeric } from '../../utils/utils';

const selectedUserInitialValue = {
  id: null,
  email: '',
  name: '',
  password: '',
  re_password: '',
  role: {
    id: 3,
    name: 'Editor',
  },
  function: 0,
  photo_url: null,
  is_active: false,
  is_superuser: false,
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UserForm = ({ openHandler, isAddNewUser = true }) => {
  const { selectedUserId, selectedUserDetails, userRoles, userFunctions } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [selectedUserData, setSelectedUserData] = useState(selectedUserInitialValue);
  const [userPhotoSrc, setUserPhotoSrc] = useState(false);
  const { data: authUser } = useSelector((state) => state.users);

  const profilePicture = useRef();

  const isAdmin = authUser?.role?.id == 1;

  useEffect(() => {
    if (isNumber(selectedUserId) && selectedUserId > 0) {
      dispatch(getSelectedUserDetails(selectedUserId));
    }
  }, [selectedUserId]);

  useEffect(() => {
    setSelectedUserData(selectedUserDetails);
  }, [selectedUserDetails]);

  useEffect(() => {
    if (isAddNewUser) {
      setSelectedUserData(selectedUserInitialValue);
    }
  }, [isAddNewUser]);

  const handleRoleFunctionChange = (e, isRole = false, isFunction = false) => {
    if (isRole && !isFunction) {
      const data = userRoles.find((row) => `${row.id}` === e.target.value);
      setSelectedUserData({
        ...selectedUserData,
        role: {
          id: data?.id || 3,
          name: data?.name || 'Editor',
        },
      });
    } else if (!isRole && isFunction) {
      const data = userFunctions.find((row) => `${row.id}` === e.target.value);
      setSelectedUserData({
        ...selectedUserData,
        function: data?.id || 0,
      });
    }
  };

  const renderRoles = (data, userRoleId) => {
    let itemComp = [];
    if ((isValNumeric(userRoleId) && userRoleId === 0) || !isValNumeric(userRoleId)) {
      itemComp.push(
        <option key="KEY_ROLE_0" value={0}>
          Select role
        </option>
      );
    }
    if (typeof data !== 'undefined') {
      itemComp = data.map((row, index) => {
        return (
          <option key={`KEY_ROLE_${(index + 1) * 0.2345}`} value={row.id}>
            {row.name}
          </option>
        );
      });
    }
    return itemComp;
  };

  const renderFunctions = (data) => {
    let itemComp = [];
    if (typeof data !== 'undefined') {
      itemComp = data.map((row, index) => {
        return (
          <option key={`KEY_FUNC_${(index + 1) * 0.2345}`} value={row.id}>
            {row.name}
          </option>
        );
      });
    }
    return itemComp;
  };

  const initialValues = {
    userId: selectedUserData?.id || null,
    userName: selectedUserData?.name || '',
    userEmail: selectedUserData?.email || '',
    userRole: selectedUserData?.role || { id: 3, name: 'Editor' },
    userFunction: selectedUserData?.function || 0,
    userPhotoURL: selectedUserData?.photo_url || '',
    userActive: selectedUserData?.is_active || false,
    userSuperUser: selectedUserData?.is_superuser,
    userPassword: selectedUserData?.password || '',
    userRePassword: selectedUserData?.re_password || '',
  };

  const whiteListedDomains = [
    'bcg.com',
    'vbr.com'
  ];

  const validationSchemaCreator = () => {
    const validationSchema = {
      userName: Yup.string().required('is required'),
      userEmail: Yup.string()
        .email()
        .test('is-not-whitelist', 'Cannot use unpermitted domain', (value) => {
          if (value) {
            const currentDomain = value.substring(value.indexOf('@') + 1);

            return whiteListedDomains.includes(currentDomain);
          }
        })
        .required('is required'),
      userRole: Yup.object().shape({
        id: Yup.number().required('id should be passed'),
        name: Yup.string().required('name should be passed'),
      }),
      userFunction: Yup.number().required('is required'),
    };
    if (isAddNewUser) {
      validationSchema.userPassword = Yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, //eslint-disable-line
          'One Uppercase, One Lowercase, One Number and One Special Case Character'
        );
    } else {
      validationSchema.userPassword = Yup.string().nullable()
        .min(8, 'Password is too short - 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, //eslint-disable-line
          'One Uppercase, One Lowercase, One Number and One Special Case Character'
        );
    }
    // validationSchema.userRePassword = Yup.string()
    //   .required('Required field')
    //   .oneOf([Yup.ref('userPassword')], 'Passwords must match');
      validationSchema.userRePassword =Yup.string().when('userPassword', {
        is: (userPassword) => userPassword !== null && userPassword !== undefined,
        then: Yup.string()
          .required('Required field')
          .oneOf([Yup.ref('userPassword')], 'Passwords must match'),
      })

    return validationSchema;
  };

  const validationSchema = Yup.object().shape(validationSchemaCreator());

  const parseValues = (values) => {
    const valueObj = {
      name: values.userName,
      email: values.userEmail,
      role: values.userRole.id,
      function: values.userFunction,
      is_active: values.userActive,
      is_superuser: values.userRole.id === 1,
      password: values.userPassword,
      re_password: values.userRePassword,
      photo_url: values?.userPhotoURL ? values.userPhotoURL : null,
    };

    if (!isNumber(valueObj.role) || (isNumber(valueObj.role) && valueObj.role === 0)) valueObj.role = null;
    if (!isNumber(valueObj.function) || (isNumber(valueObj.function) && valueObj.function === 0))
      valueObj.function = null;

    return valueObj;
  };

  const parseUpdateValues = (values) => {
    const valueObj = {
      name: values.userName,
      email: values.userEmail,
      role: values.userRole.id,
      function: values.userFunction,
      is_active: values.userActive,
      is_superuser: values.userRole.id === 1,
      password: values.userPassword,
      re_password: values.userRePassword,
      photo_url: values?.userPhotoURL ? values.userPhotoURL : null,
    };

    if (!isNumber(valueObj.role) || (isNumber(valueObj.role) && valueObj.role === 0)) valueObj.role = null;
    if (!isNumber(valueObj.function) || (isNumber(valueObj.function) && valueObj.function === 0))
      valueObj.function = null;
    if (!isString(valueObj.password) || valueObj.password === '') delete valueObj.password;
    if (!isString(valueObj.re_password) || valueObj.re_password === '') delete valueObj.re_password;

    return valueObj;
  };

  const submitUserDetails = (values) => {
    let formData = {};
    if (isAddNewUser) {
      formData = parseValues(values);
      dispatch(postUser(formData));
    } else {
      formData = parseUpdateValues(values);
      dispatch(updateUser(selectedUserId, formData, userPhotoSrc));
    }
  };
  const editButton = <i className="fa fa-edit" />;
  return (
    <div className="form-div">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur
        onSubmit={(values) => {
          submitUserDetails(values);
        }}
      >
        {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
          <Form className="form-div">
            <div className="form-div">
              <div className="userform-div">
                <div className="userform-row" style={{ display: 'flex', justifyContent: 'center' }}>
                  <div onClick={() => profilePicture.current.click()}>
                    <WAvatar
                      name={values?.userName}
                      clickable
                      large
                      customHoverContent={editButton}
                      pic={userPhotoSrc || values?.userPhotoURL}
                      customContent={!values?.userName ? editButton : false}
                    />
                  </div>

                  <input
                    ref={profilePicture}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (e.target?.files?.[0]) {
                        setFieldValue('userPhotoURL', e.target?.files?.[0]);
                        setFieldTouched('userPhotoURL');
                        toBase64(e.target.files[0]).then((photoSrc) => {
                          setUserPhotoSrc(photoSrc);
                        });
                      }
                    }}
                  />
                </div>
                <>
                  <div
                    className={`userform-row ${clsx({
                      error: touched.userName && errors.userName,
                    })}`}
                  >
                    <label htmlFor="user_Name_Text">Name</label>
                    <div>
                      <Field
                        id="user_Name_Text"
                        name="user_Name_Text"
                        type="text"
                        {...(!isAddNewUser ? { value: selectedUserData?.name || '' } : {})}
                        placeholder="Name"
                        autoComplete="false"
                        onChange={(e) => setSelectedUserData({ ...selectedUserData, name: e.target.value })}
                      />
                      {touched.userName && errors.userName && (
                        <div className="validation-message">{errors.userName}</div>
                      )}
                    </div>
                  </div>
                  {isAddNewUser && <div
                    className={`userform-row ${clsx({
                      error: touched.userEmail && errors.userEmail,
                    })}`}
                  >
                    <label htmlFor="user_Email_Text">Email</label>
                    <div>
                      <Field
                        id="user_Email_Text"
                        name="user_Email_Text"
                        type="email"
                        // value={values?.userEmail || ''}
                        placeholder="Email"
                        autoComplete="false"
                        disabled={!isAddNewUser || !isAdmin}
                        onChange={(e) => setSelectedUserData({ ...selectedUserData, email: e.target.value })}
                      />
                      {touched.userEmail && errors.userEmail && (
                        <div className="validation-message">{errors.userEmail}</div>
                      )}
                    </div>
                  </div>}
                </>
                <div
                  className={`userform-row ${clsx({
                    error: touched.userPassword && errors.userPassword,
                  })}`}
                >
                  <label htmlFor="user_Password_Text">Password</label>
                  <div>
                    <Field
                      id="user_Password_Text"
                      name="user_Password_Text"
                      type="password"
                      // value={values?.password}
                      placeholder="Password"
                      autoComplete="false"
                      onChange={(e) => setSelectedUserData({ ...selectedUserData, password: e.target.value })}
                    />
                    {touched.userPassword && errors.userPassword && (
                      <div className="validation-message">{errors.userPassword}</div>
                    )}
                  </div>
                </div>
                <div
                  className={`userform-row ${clsx({
                    error: touched.userRePassword && errors.userRePassword,
                  })}`}
                >
                  <label htmlFor="user_RePassword_Text">Password Confirmation</label>
                  <div>
                    <Field
                      id="user_RePassword_Text"
                      name="user_RePassword_Text"
                      type="password"
                      placeholder="Confirm password"
                      autoComplete="false"
                      onChange={(e) => setSelectedUserData({ ...selectedUserData, re_password: e.target.value })}
                    />
                    {touched.userRePassword && errors.userRePassword && (
                      <div className="validation-message">{errors.userRePassword}</div>
                    )}
                  </div>
                </div>
                {isAdmin && (
                  <div
                    className={`userform-row ${clsx({
                      error: touched.userRole && errors.userRole,
                    })}`}
                  >
                    <label htmlFor="user_Role_List">Role</label>
                    <div>
                      <Field
                        id="user_Role_List"
                        name="user_Role_List"
                        as="select"
                        value={values?.userRole.id || 3}
                        placeholder="Role"
                        autoComplete="false"
                        onChange={(e) => handleRoleFunctionChange(e, true, false)}
                      >
                        {/* <option key="KEY_ROLE_0" value={0}>
                          Select role
                        </option> */}
                        {renderRoles(userRoles, values?.userRole.id)}
                      </Field>
                      {touched.userRole && errors.userRole && (
                        <div className="validation-message">{errors.userRole}</div>
                      )}
                    </div>
                  </div>
                )}
                {isAdmin && (
                  <div
                    className={`userform-row ${clsx({
                      error: touched.userFunction && errors.userFunction,
                    })}`}
                  >
                    <label htmlFor="user_Function_List">Function</label>
                    <div>
                      <Field
                        id="user_Function_List"
                        name="user_Function_List"
                        as="select"
                        value={selectedUserData?.function || 0}
                        placeholder="Function"
                        autoComplete="false"
                        onChange={(e) => handleRoleFunctionChange(e, false, true)}
                      >
                        <option key="KEY_FUNC_0" value={0}>
                          Select function
                        </option>
                        {renderFunctions(userFunctions)}
                      </Field>
                      {touched.userFunction && errors.userFunction && (
                        <div className="validation-message">{errors.userFunction}</div>
                      )}
                    </div>
                  </div>
                )}
                {/* <div className="userform-row">
              <label htmlFor="user_PhotoURL_File">Photo URL</label>
              <Field
                id="user_PhotoURL_File"
                name="user_PhotoURL_File"
                type="text"
                value={values?.userPhotoURL || ''}
                placeholder="Photo URL"
                autoComplete="false"
              />
            </div> */}
                {isAdmin && (
                  <div className="userform-row">
                    <label htmlFor="user_Active_Check">Active</label>
                    <Field
                      id="userActive"
                      name="userActive"
                      type="checkbox"
                      checked={selectedUserData?.is_active}
                      onChange={() => {
                        setSelectedUserData({ ...selectedUserData, is_active: !selectedUserData.is_active });
                      }}
                    />
                  </div>
                )}
                {/* <div className="userform-row">
              <label htmlFor="userSuperUser">Superuser</label>
              <Field id="userSuperUser" name="userSuperUser" type="checkbox" className="check-box-check" />
            </div> */}
              </div>
              <div>
                <div className="userform-row userform-button">
                  <div
                    className="custom-modal-buttons custom-modal-buttons-outside-footer"
                    style={{ borderTop: '1px solid white' }}
                  >
                    <button type="button" className="WButton primary ok-button" onClick={() => openHandler(false)}>
                      Cancel
                    </button>
                    <button type="submit" className="WButton primary ok-button">
                      {isAddNewUser ? 'Add' : 'Update'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;

UserForm.propTypes = {
  openHandler: PropTypes.func,
  isAddNewUser: PropTypes.bool,
};

UserForm.defaultProps = {
  openHandler: () => { },
  isAddNewUser: true,
};
