/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// local dependencies
import './ReportsPhaseList.scss';
import WText from '../../../components/WText/Wtext';
import { getReportScenario } from '../../../redux/actions';
import PhasesList from '../../../components/PhasesList/PhasesList';

const ReportsPhaseList = () => {
  // params
  const { id } = useParams();

  // dispatch
  const dispatch = useDispatch();

  // state
  const { loading, reportScenario } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(getReportScenario(id));
  }, []);

  return (
    <div className="reports-phase-screen">
      <div className="scenarios-fetch">
        <div className="reports-phase-header">
          {loading && <span className="scenario-name-skeleton" />}
          <div className="d-ib report-phase-title">
            <WText typo="h5" weight="light">
              {reportScenario?.name} 
            </WText>
          </div>
          <div className="d-ib">
            <WText typo="small" color="gray">
              Reports
            </WText>
          </div>
        </div>
        <div className="">
          <div className="virtual-scroller">
            <PhasesList scenarioDetails={reportScenario} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsPhaseList;
