/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import {
  SCENARIO_ADD,
  SCENARIO_ADD_ERROR,
  SCENARIO_ADD_LOADING,
  SCENARIO_BEGIN,
  SCENARIO_DUPLICATE_POST,
  SCENARIO_DUPLICATE_POST_ERROR,
  SCENARIO_DUPLICATE_POST_LOADING,
  SCENARIO_ERROR,
  SCENARIO_GET,
  SCENARIO_GET_ARCHIVE_LIST,
  SCENARIO_GET_COMPLETED_LIST,
  SCENARIO_GET_LATEST_COMPLETED_LIST,
  SCENARIO_GET_DUPLICATE,
  SCENARIO_GET_DUPLICATE_ERROR,
  SCENARIO_GET_DUPLICATE_LOADING,
  SCENARIO_GET_LIST,
  SCENARIO_GET_PENDING_LIST,
  SCENARIO_GET_PINNED_LIST,
  SCENARIO_GET_QUEUED_LIST,
  SCENARIO_GET_RUNNING_LIST,
  SCENARIO_LAUNCH,
  SCENARIO_PAUSE,
  SCENARIO_DUPLICATE_NEW,
  SCENARIO_PIN,
  SCENARIO_REFRESH_DATA,
  SCENARIO_UNPIN,
  SCENARIO_UPDATE,
  SCENARIO_UPDATE_INPUT,
  SCENARIO_UPDATE_MSG,
  SCENARIO_UPDATE_MSG_RESET,
  SCENARIO_PIN_CHECK,
  SCENARIO_PIN_CHECK_RESET,
  SCENARIO_LAUNCH_CHECK,
  SCENARIO_LAUNCH_CHECK_RESET,
  SCENARIO_PAUSE_CHECK,
  SCENARIO_PAUSE_CHECK_RESET,
  SCENARIO_SCREEN_MOUNTED,
  SCENARIO_GET_ALL_STATUS,
  SCENARIO_CHECK_INTERVAL_VALUE,
  CLUSTER_STATUS_CHECK_INTERVAL_VALUE,
  SCENARIO_DELETE,
} from '../actionTypes';

const scenarioStatusMap = {
  IDLE: 'pending',
  QUEUED: 'queued',
  RUNNING: 'running',
  DONE: 'completed',
};

const initialState = {
  loading: false,
  error: null,
  all: [],
  pending: [],
  running: [],
  completed: [],
  latestCompleted: [],
  queued: [],
  archived: [],
  pinned: [],
  data: null,
  update: null,
  addLoading: false,
  addData: null,
  addError: null,
  fetchInputs: [],
  fetchInput: null,
  updateInput: null,
  duplicateInput: null,
  sendFile: null,
  getFile: null,
  launch: null,
  pause: null,
  duplicateLoading: false,
  duplicateData: null,
  duplicateError: null,
  duplicatePostLoading: false,
  duplicatePostData: null,
  duplicatePostError: null,
  scenarioUpdateMsg: null,
  scenarioPinCheck: null,
  scenarioLaunchCheck: null,
  scenarioPauseCheck: null,
  isScreenMounted: false,
  scenarioCheckIntervalValue: null,
  clusterStatusCheckIntervalValue: undefined,
};

// eslint-disable-next-line default-param-last
const scenarioReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SCENARIO_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SCENARIO_GET_LIST:
      return {
        ...state,
        loading: false,
        all: payload.data,
        error: null,
      };
    case SCENARIO_GET_RUNNING_LIST:
      return {
        ...state,
        loading: false,
        running: payload.data,
        error: null,
      };
    case SCENARIO_GET_QUEUED_LIST:
      return {
        ...state,
        loading: false,
        queued: payload.data,
        error: null,
      };
    case SCENARIO_GET_PENDING_LIST:
      return {
        ...state,
        loading: false,
        pending: payload.data,
        error: null,
      };
    case SCENARIO_GET_COMPLETED_LIST:
      return {
        ...state,
        loading: false,
        completed: payload.data,
        error: null,
      };
    case SCENARIO_GET_LATEST_COMPLETED_LIST:
      return {
        ...state,
        loading: false,
        latestCompleted: payload.data,
        error: null,
      };
    case SCENARIO_GET_PINNED_LIST:
      return {
        ...state,
        loading: false,
        pinned: payload.data,
        error: null,
      };
    case SCENARIO_GET_ARCHIVE_LIST:
      return {
        ...state,
        loading: false,
        archived: payload.data,
        error: null,
      };
    case SCENARIO_ADD_LOADING:
      return {
        ...state,
        addLoading: false,
        addError: null,
      };
    case SCENARIO_ADD:
      return {
        ...state,
        addLoading: false,
        addData: payload.data,
      };
    case SCENARIO_ADD_ERROR:
      return {
        ...state,
        addLoading: false,
        addError: payload.error,
      };
    case SCENARIO_GET:
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    case SCENARIO_PIN:
      return {
        ...state,
        loading: false,
        pin: payload.data,
      };
    case SCENARIO_UNPIN:
      return {
        ...state,
        loading: false,
        pin: payload.data,
      };

    case SCENARIO_PIN_CHECK:
      return {
        ...state,
        scenarioPinCheck: payload.data,
      };
    case SCENARIO_PIN_CHECK_RESET:
      return {
        ...state,
        scenarioPinCheck: payload.data,
      };
    case SCENARIO_UPDATE_INPUT:
      return {
        ...state,
        loading: false,
        updateInput: payload.data,
      };
    case SCENARIO_UPDATE:
      return {
        ...state,
        loading: false,
        update: payload.update,
        data: payload.data,
      };

    case SCENARIO_UPDATE_MSG:
      return {
        ...state,
        scenarioUpdateMsg: payload.data,
      };
    case SCENARIO_UPDATE_MSG_RESET:
      return {
        ...state,
        scenarioUpdateMsg: payload.data,
      };
    case SCENARIO_LAUNCH:
      return {
        ...state,
        loading: false,
        launch: payload.data,
      };

    case SCENARIO_LAUNCH_CHECK:
      return {
        ...state,
        scenarioLaunchCheck: payload.data,
      };
    case SCENARIO_DELETE:
      return {
        ...state,
      };
    case SCENARIO_LAUNCH_CHECK_RESET:
      return {
        ...state,
        scenarioLaunchCheck: payload.data,
      };
    case SCENARIO_PAUSE:
      return {
        ...state,
        loading: false,
        pause: payload.data,
      };

    case SCENARIO_PAUSE_CHECK:
      return {
        ...state,
        scenarioPauseCheck: payload.data,
      };

    case SCENARIO_PAUSE_CHECK_RESET:
      return {
        ...state,
        scenarioPauseCheck: payload.data,
      };
    case SCENARIO_ERROR:
      return {
        ...state,
        loading: false,
        pending: [],
        running: [],
        completed: [],
        latestCompleted: [],
        queued: [],
        archived: [],
        pinned: [],
        data: null,
        error: payload.error,
        fetchInputs: [],
        fetchInput: null,
        updateInput: null,
        duplicateInput: null,
      };
    case SCENARIO_GET_DUPLICATE_LOADING:
      return {
        ...state,
        duplicateLoading: true,
        duplicateError: null,
      };
    case SCENARIO_GET_DUPLICATE:
      return {
        ...state,
        duplicateLoading: false,
        duplicateData: payload.data,
        duplicateError: null,
      };
    case SCENARIO_GET_DUPLICATE_ERROR:
      return {
        ...state,
        duplicateLoading: false,
        duplicateError: payload.error,
      };
    case SCENARIO_DUPLICATE_POST_LOADING:
      return {
        ...state,
        duplicatePostLoading: true,
        duplicatePostError: null,
      };
    case SCENARIO_DUPLICATE_POST:
      return {
        ...state,
        duplicatePostLoading: false,
        duplicatePostData: payload.data,
        duplicatePostError: null,
      };
    case SCENARIO_DUPLICATE_POST_ERROR:
      return {
        ...state,
        duplicatePostLoading: false,
        duplicatePostError: payload.error,
      };

    case SCENARIO_DUPLICATE_NEW:
      return {
        ...state,
        duplicatePostData: null,
      };
    case SCENARIO_REFRESH_DATA:
      const scenarioStatus = payload?.scenario?.status; // eslint-disable-line
      const pin = payload?.pin; // eslint-disable-line
      const archive = payload?.archive; // eslint-disable-line

      // eslint-disable-next-line
      const scenarioIndexToUpdate = state[scenarioStatusMap[scenarioStatus]].findIndex(
        (s) => s?.id === payload?.scenario?.id
      );
      let newScenarios = JSON.parse(JSON.stringify(state[scenarioStatusMap[scenarioStatus]])); // eslint-disable-line

      let newPinned = null; // eslint-disable-line
      let newArchived = null; // eslint-disable-line
      if (archive !== null) {
        // archive
        // handling all status pages like queued/pending/workspace etc
        newScenarios = newScenarios.filter((s) => (s ? s?.id !== payload?.scenario?.id : false));

        if (!archive) {
          // to archive
          // handling in pin page
          const oldPinned = JSON.parse(JSON.stringify(state.pinned));
          newPinned = oldPinned.filter((s) => (s ? s.id !== payload?.scenario?.id : false));
        } else {
          // to unarchive
          // handling in archive page
          const oldArchived = JSON.parse(JSON.stringify(state.archived));
          newArchived = oldArchived.filter((s) => (s ? s.id !== payload?.scenario?.id : false));
        }
      } else if (pin !== null) {
        // pin
        // handling all status pages like queued/pending/workspace etc
        if (scenarioIndexToUpdate !== -1 && newScenarios.length) {
          newScenarios[scenarioIndexToUpdate]['pinned'] = !pin; // eslint-disable-line
        }
        if (pin) {
          // to unpin
          // handling in pin page
          const oldPinned = JSON.parse(JSON.stringify(state.pinned));
          newPinned = oldPinned.filter((s) => (s ? s.id !== payload?.scenario?.id : false));
        }
        // handling in archive page
        const scenarioArchiveIndex = state.archived.findIndex((s) => s?.id === payload?.scenario?.id);
        if (scenarioArchiveIndex !== -1) {
          newArchived = JSON.parse(JSON.stringify(state.archived));
          newArchived[scenarioArchiveIndex]['pinned'] = !pin; // eslint-disable-line
        }
      }

      // eslint-disable-next-line
      const newState = {
        ...state,
        [scenarioStatusMap[scenarioStatus]]: newScenarios,
      };
      if (newArchived) {
        newState.archived = newArchived;
      }
      if (newPinned) {
        newState.pinned = newPinned;
      }
      return newState;
    case SCENARIO_SCREEN_MOUNTED:
      return {
        ...state,
        isScreenMounted: payload,
      };
    case SCENARIO_GET_ALL_STATUS:
      return {
        ...state,
        running: payload['running'],
        pending: payload['pending'],
        completed: payload['completed'],
        queued: payload['queued'],
      };
    case SCENARIO_CHECK_INTERVAL_VALUE:
      return {
        ...state,
        scenarioCheckIntervalValue: payload,
      };
    case CLUSTER_STATUS_CHECK_INTERVAL_VALUE:
      return {
        ...state,
        clusterStatusCheckIntervalValue: payload,
      };
    default:
      return state;
  }
};

export default scenarioReducer;
