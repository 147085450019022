/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import axios from '../../utils/axios';
import {
  REPORT_BEGIN,
  REPORT_ERROR,
  REPORT_SCENARIO_GET,
  REPORT_PHASE_GET,
  REPORT_WORKBOOK_GET,
  REPORT_WORKSHEET_GET,
} from '../actionTypes';

const getReportScenario = (id) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: REPORT_BEGIN });
      const url = `/api/scenarios/${id}/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      // parsing data from string to json
      const inputsList = data?.inputs.map((dt) => {
        const parsedData = JSON.parse(dt.data);
        return {
          ...dt,
          data: parsedData,
        };
      });

      const getData = {
        ...data,
        inputs: inputsList,
      };

      return dispatch({ type: REPORT_SCENARIO_GET, payload: { data: getData } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: REPORT_ERROR, payload: { error } });
    }
  };
};

const getReportPhase = () => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: REPORT_BEGIN });
      const url = '/api/phases/';
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: REPORT_PHASE_GET, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: REPORT_ERROR, payload: { error } });
    }
  };
};

const getPhaseWorkBooks = (phaseID) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: REPORT_BEGIN });
      const url = `/api/workbooks/phase/${phaseID}/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: REPORT_WORKBOOK_GET, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: REPORT_ERROR, payload: { error } });
    }
  };
};

const getPhaseWorksheets = (phaseID) => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: REPORT_BEGIN });

      const url = `api/worksheets/workbook/${phaseID}/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);

      return dispatch({ type: REPORT_WORKSHEET_GET, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: REPORT_ERROR, payload: { error } });
    }
  };
};

export { getReportScenario, getReportPhase, getPhaseWorkBooks, getPhaseWorksheets };
