/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import React from 'react';
import { useIntl } from 'react-intl';

// local dependencies
import './ScenarioProgress.scss';
import { PHASE_STATUSES, STATUSES_OBJ, DB_STATUSES, MODEL_EXIT_STATUSES_OBJ } from '../../../../constants/data';
import ScenarioProgressPhaseExpanded from '../ScenarioProgressPhaseExpanded/ScenarioProgressPhaseExpanded';
import ScenarioProgressPhase from '../ScenarioProgressPhase/ScenarioProgressPhase';
import StatusSuccessIcon from '../../../../assets/icons/status-success.svg';
import WText from '../../../WText/Wtext';
import { checkIsCurrentPhase, isValNumeric } from '../../../../utils/utils';

const ScenarioProgress = ({ inputs, item, isRunning = false }) => {
  // i18n
  const intl = useIntl();
  const phases = Object.entries(inputs).map(([key, el], index) => {
    const previousPhase = Object.keys(inputs)[index - 1];
    return {
      key,
      status: el.status,
      isFileIDValid: isValNumeric(el.file_id) && el.is_xl_valid,
      isFileUploaded: isValNumeric(el.file_id),
      isDataValid: JSON.parse(el.data).fields[0].status === PHASE_STATUSES.valid || false,
      isRunning: el.status === PHASE_STATUSES.running,
      isValid: el.status === PHASE_STATUSES.valid,
      isInvalid: el.status === PHASE_STATUSES.invalid,
      isDisabled: el.status === PHASE_STATUSES.void,
      isCurrent:
        el.status === PHASE_STATUSES.void && (!inputs[previousPhase] || inputs[previousPhase] === PHASE_STATUSES.done),
    };
  });

  const phaseProgress = (p, idx) => {
    let isExpanded = true;
    let compToReturn = null;
    let phaseProps = { id: item?.id, key: p.key, phase: idx + 1 };
    const isPhase1Or2 = phaseProps.phase === 1 || phaseProps.phase === 2;
    const isCurrPhs = checkIsCurrentPhase(inputs[idx], item);

    // phase is valid or running then showing the progress in percentage
    if ((p.isRunning || p.isValid) && item?.status === STATUSES_OBJ.running && isCurrPhs) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.running.toLowerCase(),
        progress: (item?.model_progress === null && 0) || item?.model_progress,
      };
    }

    // phase is done
    else if (p?.status === PHASE_STATUSES.done && p.isFileIDValid) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.done.toLowerCase(),
      };
    }

    // phase is valid and queued
    else if (item?.status === STATUSES_OBJ.queued && p.isValid && p.isFileIDValid && isCurrPhs) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.running.toLowerCase(),
      };
    }

    // phase data is void or invalid, phase is valid and file id is valid
    else if (!p.isDataValid && p.isValid && p.isFileIDValid && isPhase1Or2) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.void.toLowerCase(),
      };
    }

    // phase data is void or invalid, phase is valid and file id is invalid
    else if (!p.isDataValid && p.isValid && !p.isFileIDValid && isPhase1Or2) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.void.toLowerCase(),
      };
    }

    // phase is valid and file id is also valid
    else if (isPhase1Or2 && p.isDataValid && p.isValid && p.isFileIDValid) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.valid.toLowerCase(),
      };
    }

    // phase is valid and file id is also valid
    else if (isPhase1Or2 && p.isDataValid && !p.isValid && p.isFileIDValid && !p.isDisabled) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.valid.toLowerCase(),
      };
    }

    // phase is valid and file id is not numeric
    else if (isPhase1Or2 && p.isDataValid && p.isValid && !p.isFileIDValid) {
      phaseProps = {
        ...phaseProps,
        status: p.isFileUploaded ? PHASE_STATUSES.void.toLowerCase() : PHASE_STATUSES.void.toLowerCase(),
      };
    }

    // phase is valid and file id is not numeric
    else if (isPhase1Or2 && p.isDataValid && !p.isValid && !p.isFileIDValid && !p.isDisabled) {
      phaseProps = {
        ...phaseProps,
        status: p.isFileUploaded ? PHASE_STATUSES.void.toLowerCase() : PHASE_STATUSES.void.toLowerCase(),
      };
    }

    // phase is valid and file id is not numeric
    else if (!isPhase1Or2 && p.isDataValid && p.isValid) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.valid.toLowerCase(),
      };
    }

    // phase is invalid
    else if (p.isInvalid) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.void.toLowerCase(),
      };
    }

    // phase is current
    else if (p.isCurrent || p.isDisabled) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.void.toLowerCase(),
      };
    }

    // phase 3 status is valid
    else if (!isPhase1Or2 && !p.isDataValid && p.isValid) {
      phaseProps = {
        ...phaseProps,
        status: PHASE_STATUSES.void.toLowerCase(),
      };
    }

    // phase disabled and with warning
    else {
      isExpanded = false;
      phaseProps = {
        ...phaseProps,
        disabled: p.isDisabled,
        warning: item?.ETLDataStatus === DB_STATUSES.expired,
      };
    }

    // If the scenario is stopped or failed, use model_exit_status as phase status
    if ([MODEL_EXIT_STATUSES_OBJ.failed, MODEL_EXIT_STATUSES_OBJ.stopped].includes(item?.model_exit_status?.toUpperCase() || '') && p.isDataValid && p.isValid) {
      phaseProps = {
        ...phaseProps,
        status: item?.model_exit_status.toLowerCase(),
      };
    }

    // If some other phase is running or the input file is invalid, the phase cannot be restarted
    if ((!p?.isFileIDValid && phaseProps.phase < 3) || isRunning) {
      phaseProps = {
        ...phaseProps,
        disabled: true,
      };
    }

    // If the next phase is done or the next phase input is invalid,
    // or if the current phase is not done or the input is invalid,
    // the phase cannot be restarted
    if (phaseProps.phase <= 2) {
      const nextPhase = phases[phaseProps.phase]
      if ((nextPhase?.status === PHASE_STATUSES.done && nextPhase.isFileIDValid) || (p?.status != PHASE_STATUSES.done || !p.isFileIDValid)) {
        phaseProps = {
          ...phaseProps,
          disabled: true,
        };
      }
    }

    // If the phase is 3, only can be restarted if the previous phase is done and the phase input is valid
    if (phaseProps.phase >= 2 && phaseProps.phase <= 3) {
      const previousPhase = phases[phaseProps.key - 1]
      if (previousPhase?.status != PHASE_STATUSES.done || !previousPhase.isFileIDValid) {
        phaseProps = {
          ...phaseProps,
          disabled: true,
          status: PHASE_STATUSES.void.toLowerCase()
        };
      }
    }

    // If the scenario is failed, no phases cannot be restart
    if (item?.status === STATUSES_OBJ.failed) {
      isExpanded = true
      phaseProps = {
        ...phaseProps,
        disabled: true,
        status: PHASE_STATUSES.failed.toLowerCase(),
      };
    }

    // If the scenario is completed, no phases can be restarted
    if (item?.status === STATUSES_OBJ.done) {
      isExpanded = true
      phaseProps = {
        ...phaseProps,
        disabled: true,
        status: PHASE_STATUSES.done.toLowerCase(),
      };
    }

    phaseProps = {
      ...phaseProps,
      text: item?.name,
    };

    // eslint-disable-next-line
    if (isExpanded) compToReturn = <ScenarioProgressPhaseExpanded {...phaseProps} />;

    // eslint-disable-next-line
    if (!isExpanded) compToReturn = <ScenarioProgressPhase {...phaseProps} />;

    return compToReturn;
  };

  return (
    <div className="scenario-progress">
      {phases.map((p, idx) => (
        <React.Fragment key={p.key}>{phaseProgress(p, idx)}</React.Fragment>
      ))}
      {item?.status === STATUSES_OBJ.done && (
        <div className="done-progress">
          <img src={StatusSuccessIcon} alt="status_icon" />
          <WText typo="small" color="success">
            {intl.formatMessage({ id: 'scenario.completed' })}
          </WText>
        </div>
      )}
    </div>
  );
};
export default ScenarioProgress;
