/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';
import { useState } from 'react';
import clsx from 'clsx';

// local dependencies
import './WorkbookTabsList.scss';
import WorksheetTabsList from '../WorksheetTabsList/WorksheetTabsList';

const WorkbookTabsList = ({ workbooks, worksheets, onWorkSheetChange, workbookID, onworkbookIDChange }) => { 

  return (
    <div className="workbook-navbar phase-navbar">
      <div>
        {workbooks
          ?.filter((pwb) => pwb.is_active === true)
          .map((workBookItem) => {
            return (
              <div
                className={`workbook-subnav ${clsx({
                  activated: workBookItem.id === parseInt(workbookID, 10),
                })}`}
                key={workBookItem.name}
              >
                <button
                  className="workbook-subnavbtn"
                  type="button"
                  onClick={() => onworkbookIDChange(workBookItem.id)}
                >
                  {workBookItem.name}
                  <i className="fa fa-caret-down" style={{ marginLeft: '0.4rem' }} />
                </button>
                <div className="workbook-subnav-content">
                  <WorksheetTabsList
                    worksheets={worksheets}
                    workbookID={workbookID}
                    onWorkSheetChange={onWorkSheetChange}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

WorkbookTabsList.propTypes = {
  workbooks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      phase: PropTypes.number,
      is_active: PropTypes.bool,
    })
  ).isRequired,
  worksheets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tableau_ref_name: PropTypes.string,
      workbook: PropTypes.number,
      phase: PropTypes.number,
      is_active: PropTypes.bool,
    })
  ).isRequired,
  onWorkSheetChange: PropTypes.func.isRequired,
  workbookID: PropTypes.string.isRequired,
};

export default WorkbookTabsList;
