export default {
  title: 'Duplicate scenario',
  subtitle:
    'Choose the phases or sub-phases you want to copy, and keep in mind that some sub-phases depend on previous ones',
  phase1: {
    title: 'Phase 1',
    output: 'Output phase 1',
    subtitle: 'Analytics',
  },
  phase2: {
    title: 'Phase 2',
    output: 'Output phase 2',
    subtitle: 'Demand Forecast',
  },
  phase3: {
    title: 'Phase 3',
    output: 'Output phase 3',
    subtitle: 'Optimization',
  },
  duplicate: 'Yes, duplicate',
  loading: ' Loading ...',
  cancel: 'Cancel',
  help: 'The database has been updated, so you will have to rerun all the phases to get the outputs. ',
};
