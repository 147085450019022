/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';

// local dependencies
import WorkbookTabsList from '../../components/WorkbookTabsList/WorkbookTabsList';
import { getReportScenario, getScenarioList, getPhaseWorkBooks, getPhaseWorksheets } from '../../redux/actions';
import WText from '../../components/WText/Wtext';
import './ReportPopOutDetail.scss';
import { convertTableau } from './ReportDetailScreen';

const ReportPopOutDetail = () => {
  // params
  const { scenarioID, workbookID, phaseID, tableaurefname } = useParams();

  // local state
  const [iLinkPara, setILinkPara] = useState({
    workbookID,
    tableaurefname,
  });
  const [phaseOptions, setPhaseOptions] = useState([]);

  // navigate
  const navigate = useNavigate();

  // dispatch
  const dispatch = useDispatch();

  // global state
  const { loading: reportLoading, reportScenario, workbooks, worksheets } = useSelector((state) => state.reports);
  const { loading: scenarioLoading, completed } = useSelector((state) => state.scenarios);

  useEffect(() => {
    // phase options
    const pOptions = reportScenario?.inputs?.map((ip) => {
      return {
        value: ip.phase_number,
        label: `Phase ${ip.phase_number}`,
        status: ip.status,
      };
    });

    setPhaseOptions(pOptions);
  }, [reportScenario]);

  useEffect(() => {
    dispatch(getReportScenario(scenarioID));
    dispatch(getScenarioList('DONE'));
    dispatch(getPhaseWorkBooks(phaseID));
    dispatch(getPhaseWorksheets(phaseID));
  }, []);

  const onPhaseChange = (value) => {
    dispatch(getPhaseWorkBooks(value));
    dispatch(getPhaseWorksheets(value));
    // re-render the page for changing the url
    navigate(`/report/${scenarioID}/worksheet/${iLinkPara.workbookID}/phase/${value}/${iLinkPara.tableaurefname}`);
  };

  const onScenarioChange = (value) => {
    dispatch(getReportScenario(value));

    // re-render the page for changing the url
    navigate(`/report/${value}/worksheet/${iLinkPara.workbookID}/phase/${phaseID}/${iLinkPara.tableaurefname}`);
  };

  const onWorkBookChange = (bookID) => {
    if (bookID != iLinkPara.workbookID) {
      const workbookSheets = worksheets
        .filter(s => s.workbook == parseInt(bookID, 10))
        .filter(s => s.is_active)
      const defaultWorkbookSheet = workbookSheets[0]
      if (workbookSheets.length) {
        setILinkPara({
          workbookID: defaultWorkbookSheet.workbook.toString(),
          tableaurefname: defaultWorkbookSheet.tableau_ref_name
        });
    
       // navigate(`/report/detail/scenario/${scenarioID}/worksheet/${defaultWorkbookSheet.workbook}/phase/${phaseID}/${workbookSheets[0].tableau_ref_name}`);
        navigate(`/report/${scenarioID}/worksheet/${bookID}/phase/${phaseID}/${workbookSheets[0].tableau_ref_name}`);

      }
    }
  };

  const onWorkSheetChange = (bookID, tableauSheetName) => {
    setILinkPara({
      workbookID: bookID.toString(),
      tableaurefname: tableauSheetName,
    });

    // re-render the page for changing the url
    navigate(`/report/${scenarioID}/worksheet/${bookID}/phase/${phaseID}/${tableauSheetName}`);
  };

  return (
    <div className="report-pop-out-detail-screen">
      <div className="report-pop-out-flex">
        <div className="reports-phase-header">
          {reportLoading && <span className="scenario-name-skeleton" />}
          <div className="d-ib report-phase-title">
            <WText typo="h5" weight="light">
              {reportScenario?.name}
            </WText>
          </div>
          <div className="d-ib">
            <WText typo="small" color="gray">
              Reports
            </WText>
          </div>
          <button
            className="d-ib"
            style={{ color: 'white' }}
            type="button"
            onClick={() =>
              window.open(
                `/report/${scenarioID}/worksheet/${workbookID}/phase/${phaseID}/${tableaurefname}`,
                '',
                'width=1000, height=800, left=300, top=200'
              )
            }
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </button>
        </div>
        <div className="flex">
          <div className="phase-dropdown">
            <div className="date-select dapi-input d-ib">
              <label htmlFor="phase" className="date-input-label">
                Phases
              </label>
              <select
                id="phase"
                name="phase"
                component="select"
                className="date-input-select"
                onChange={(e) => onPhaseChange(e.target.value)}
                value={phaseID}
              >
                {phaseOptions?.map((po) => {
                  return (
                    <option
                      value={po.value}
                      className="date-input-select-option"
                      key={po.value}
                      disabled={po.status !== 'DONE'}
                    >
                      {po.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="scenario-dropdown">
            {scenarioLoading && <span className="scenario-dropdown-skeleton" />}
            <div className="date-select dapi-input d-ib">
              <label htmlFor="completed-scenario" className="date-input-label">
                Completed Scenarios
              </label>
              <select
                id="completed-scenario"
                name="completed-scenario"
                component="select"
                className="date-input-select"
                onChange={(e) => onScenarioChange(e.target.value)}
                value={scenarioID}
              >
                {completed?.map((c) => {
                  return (
                    <option value={c?.id} className="date-input-select-option" key={c?.id}>
                      {c?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="report-detail-container">
        <div className="virtual-scroller">
          <WorkbookTabsList
            workbooks={workbooks}
            worksheets={worksheets}
            onWorkSheetChange={onWorkSheetChange}
            workbookID={iLinkPara.workbookID}
            onworkbookIDChange={onWorkBookChange}
          />
          <div style={{ margin: '-2px', height: '800px' }}>
            <div style={{ width: '100%', height: '800px', marginTop: '1rem' }}>
              <iframe
                title="tableauIframe"
                id="iframe"
                width="100%"
                height="800px"
                sandbox="allow-same-origin allow-scripts allow-forms"
                src={`https://tableauha.bcg.com/t/15185-03/views/${convertTableau({ workbookID: iLinkPara.workbookID, tableaurefname: iLinkPara.tableaurefname,reportScenario:reportScenario,phaseID:phaseID })}?iframeSizedToWindow=true&%3Aembed=y&%3AshowAppBanner=false&%3Adisplay_count=no&%3AshowVizHome=no&%3Aorigin=viz_share_link&%3Atabs=no&%3Atoolbar=no#1`}
              // src={`https://tableauha.bcg.com/t/15185-03/views/1/${iLinkPara.tableaurefname}?iframeSizedToWindow=true&%3Aembed=y&%3AshowAppBanner=false&%3Adisplay_count=no&%3AshowVizHome=no&%3Aorigin=viz_share_link&%3Atabs=no&%3Atoolbar=no#1`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPopOutDetail;