/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

// local dependencies
import './Scenarios.scss';
import Scenario from '../../components/Scenario/scenario';
import { getScenarioList, getPinnedListScenario } from '../../redux/actions/scenarioActions';

const Scenarios = ({ name }) => {
  // dispatch
  const dispatch = useDispatch();

  const { pending, pinned, archived, completed, queued } = useSelector((state) => state.scenarios);

  useEffect(() => {
    document.title = `${name} | NEXO by BCG`;

    if (name.toLowerCase() === 'pending') {
      dispatch(getScenarioList('IDLE', false, false));
    } else if (name.toLowerCase() === 'queued') {
      dispatch(getScenarioList('QUEUED', false, false));
    } else if (name.toLowerCase() === 'completed') {
      dispatch(getScenarioList('DONE', false, false));
    } else if (name.toLowerCase() === 'pinned') {
      dispatch(getPinnedListScenario());
    } else {
      dispatch(getScenarioList('', true, false));
    }
  }, [name]);

  return (
    <div className="scenario-status">
      {name.toLowerCase() === 'pending' && <Scenario header={name} listItem={pending} listNo={pending?.length} />}
      {name.toLowerCase() === 'queued' && <Scenario header={name} listItem={queued} listNo={queued?.length} />}
      {name.toLowerCase() === 'completed' && (
        <Scenario header={name} listItem={completed} listNo={completed?.length} />
      )}
      {name.toLowerCase() === 'pinned' && <Scenario header={name} listItem={pinned} listNo={pinned?.length} />}
      {name.toLowerCase() === 'archive' && <Scenario header={name} listItem={archived} listNo={archived?.length} />}
    </div>
  );
};

Scenarios.propTypes = {
  name: PropTypes.string,
};

Scenarios.defaultProps = {
  // eslint-disable-next-line react/require-default-props
  name: '',
};

export default Scenarios;
