/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';

// local dependencies
import './WDivider.scss';

const WDivider = ({ withMargin }) => {
  const getStyle = withMargin ? { margin: '8px 24px' } : { margin: 0 };
  return (
    <div className="WDivider">
      <div className="divider-wrapper" style={getStyle}>
        <hr className="hr-divider" />
      </div>
    </div>
  );
};

export default WDivider;

WDivider.propTypes = {
  withMargin: PropTypes.bool,
};

WDivider.defaultProps = {
  withMargin: 'false',
};
