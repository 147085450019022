/**
 Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from "prop-types";

// local dependencies
import Modal from "../Modal/Modal";
import WText from "../WText/Wtext";
import "./ResetSessionModal.scss";
import { calculateRemainingSessionTime, refreshSession, secToTime } from "../../utils/sessionUtils";
import { SessionContext } from "../../utils/SessionContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/actions";

const ResetSessionModal = ({ isOpen, openHandler }) => {
  const { remainingSessionTime, setRemainingSessionTime, setSessionRefreshed } = useContext(SessionContext);
  const dispatch = useDispatch();
  const onRefreshSession = () => {
    refreshSession().then((sessionRefreshed) => {
      openHandler(false);
      if (sessionRefreshed) {
        setRemainingSessionTime(calculateRemainingSessionTime());
        setSessionRefreshed(true);
      } else {
        setSessionRefreshed(false);
        setRemainingSessionTime(-1);
        dispatch(logOut());
      }
    });
  };

  return (
    <Modal isOpen={isOpen} openHandler={openHandler} closeBtn={false} dismissable modalClasses={{
      parent: "session-modal",
      dialog: "session-modal-dialog",
      header: "modal-header session-modal-header",
      footer: "session-modal-footer session-modal-footer"
    }}>
      {{
        header: (
          <div className="reset-session-modal-wrapper">
            <div className="reset-session-modal-title">
              <WText typo="h4">Your session is expiring in {secToTime(remainingSessionTime)} minutes
              </WText>
            </div>
          </div>
        ),
        footer: (
          <div className="reset-session-modal-buttons">
            <button type="button" className="WButton primary ok-button" onClick={() => onRefreshSession()}>
              Refresh Session
            </button>
            <button type="button" className="WButton secondary ok-button" onClick={() => openHandler(false)}>
              Dismiss
            </button>
          </div>
        )
      }}
    </Modal>
  );
};

ResetSessionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openHandler: PropTypes.func.isRequired
};

export default ResetSessionModal;
