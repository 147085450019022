import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import { formatNumber } from '../../utils/utils';
import './ScatterChartComponent.css';

// const data = [
//   { x: 100, y: 200, z: 200 },
//   { x: 120, y: 100, z: 260 },
//   { x: 170, y: 300, z: 400 },
//   { x: 140, y: 250, z: 280 },
//   { x: 150, y: 400, z: 500 },
//   { x: 110, y: 280, z: 200 },
// ];

HighchartsBoost(Highcharts);

const ScatterChartComponent = ({ data }) => {
  const options = {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      backgroundColor: '#12141c',
      boost: {
        useGPUTranslations: true,
        usePreAllocated: true,
      },
      credits: {
        enabled: false,
      },
    },
    title: {
      text: 'Traffic Distribution',
      style: {
        color: '#FFFFFF', // White color for the title
      },
    },
    xAxis: {
      title: {
        enabled: true,
        text: 'Traffic Peak',
        style: {
          color: '#FFFFFF', // White color for the title
        },
      },
      labels: {
        style: {
          color: '#FFFFFF', // White color for the x-axis labels
        },
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: 'Concurrent Users',
        style: {
          color: '#FFFFFF', // White color for the title
        },
      },
      labels: {
        style: {
          color: '#FFFFFF', // White color for the x-axis labels
        },
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: 0,
      y: 70,
      floating: true,
      backgroundColor: '#12141c',
      borderWidth: 1,
      itemStyle: {
        color: '#FFFFFF', // White color for the legend items
      },
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 3,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)',
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          // pointFormat: 'Traffic Peak: {point.x}, Concurrent Users: {point.y}',
          pointFormatter: function () {
            return 'Traffic Peak: ' + formatNumber(this.x) + ', Concurrent Users: ' + formatNumber(this.y);
          },
        },
      },
      series: {
        boostThreshold: 2000,
      },
    },
    series: data,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ScatterChartComponent;
