/**
 Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// local dependencies

export const SessionContext = React.createContext();

const SessionContextProvider = ({ children }) => {
  const [remainingSessionTime, setRemainingSessionTime] = useState(-1); // in seconds
  const [sessionModalOpen, setSessionModalOpen] = useState(false);
  const [sessionRefreshed, setSessionRefreshed] = useState(false);

  return (
    <SessionContext.Provider
      value={{
        remainingSessionTime,
        setRemainingSessionTime,
        sessionModalOpen,
        setSessionModalOpen,
        sessionRefreshed,
        setSessionRefreshed,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

// Export component.
export default SessionContextProvider;
