/* eslint-disable no-else-return */
import './Filters.scss';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import MultiRangeSlider from '../MultiRangeSlider/MultiRangeSlider';
import WText from '../WText/Wtext';
import { convertToTitleCase } from '../../utils/utils';

// eslint-disable-next-line react/prop-types
const Filters = ({ data, filters, handleFilterChange, applyFilters }) => {
  const onShow = () => {
    const selectAllLabel = document.querySelector('.p-multiselect-select-all-label');
    if (selectAllLabel) {
      selectAllLabel.innerHTML = 'All';
      selectAllLabel.style.marginBottom = '3px';
    } else {
      console.error('Select all label not found');
    }
  };

  // const customSelectedItemTemplate = (selectedItems, key) => {
  //   let template = '';
  //   if (!rendered) {
  //     if (selectedItems.length === 0) {
  //       template += 'Select';
  //     } else if (selectedItems.length === data[key].values.length) {
  //       template += 'All';
  //     } else if (selectedItems.length === 1) {
  //       template += selectedItems[0].name;
  //     } else {
  //       template += 'Multiple items selected';
  //     }
  //     setRendered(true); // Set renderedRef.current to true
  //     // return template;
  //   }
  //   return template;
  // };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <WText typo="h5" color="gray">
        Filters:
      </WText>

      {Object.keys(data).map((key) => {
        const { type, values } = data[key];
        // if (type === 'range') {
        //   console.log('filters', filters[key]);
        //   console.log('filters values', filters[key].filters);
        // }
        return (
          <div className="date-select dapi-input d-ib" key={key} style={{ marginBottom: '10px' }}>
            <label className="date-input-label" htmlFor={key} style={{ marginRight: '10px' }}>
              {convertToTitleCase(key)}:
            </label>
            {type === 'single-select' && (
              <Dropdown
                value={filters[key] ? filters[key].values[0] : {}}
                onChange={(e) => handleFilterChange(key, type, [e.value])}
                options={values}
                optionLabel="name"
                placeholder="Select"
                className="date-input-select"
              />
            )}
            {type === 'select' && (
              <MultiSelect
                value={filters[key] ? filters[key].values : []}
                onChange={(e) => handleFilterChange(key, type, e.value)}
                options={values}
                optionLabel="name"
                placeholder="Select"
                maxSelectedLabels={1}
                className="date-input-select"
                onShow={onShow}
                selectedItemsLabel="Multiple Values"
                // selectAll={true}
                // style={{ backgroundColor: 'white' }}
                // selectedItemTemplate={customSelectedItemTemplate(filters[key].values || [], key)}
                // selectedItemTemplate={customSelectedItemTemplate(item, key)}
              />
            )}
            {type === 'range' && (
              <MultiRangeSlider
                min={Math.floor(Math.min(...values))}
                max={Math.ceil(Math.max(...values))}
                minVal={Math.floor(Math.min(...filters[key].values))}
                maxVal={Math.ceil(Math.max(...filters[key].values))}
                onChange={(e) => handleFilterChange(key, type, [e.currentMin, e.currentMax])}
                // onChange={(e) => console.log(e)}
                style={{ paddingTop: '10px' }}
              />
            )}
          </div>
        );
      })}
      <button
        type="button"
        className="WButton action secondary mini animated"
        style={{ marginLeft: '30px', width: 'max-content' }}
        onClick={applyFilters}
      >
        Apply Filters
      </button>
    </div>
  );
};

// Filters.propTypes = {
//   // eslint-disable-next-line react/forbid-prop-types
//   data: PropTypes.object.isRequired,
//   filters: PropTypes.object.isRequired,
// };

export default Filters;
