/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// local dependencies
import WText from '../../../WText/Wtext';
import './ScenarioProgressPhaseExpanded.scss';
import StatusAddIcon from '../../../../assets/icons/status-add.svg';
import StatusErrorIcon from '../../../../assets/icons/status-error.svg';
import StatusSuccessIcon from '../../../../assets/icons/status-success.svg';
import StatusDoneIcon from '../../../../assets/icons/status-done.svg';
import StatusRestartIcon from '../../../../assets/icons/status-restart.svg';
import StatusStopIcon from '../../../../assets/icons/status-stop.svg';
import TooltipWrapper from '../../../Tooltip/TooltipWrapper/TooltipWrapper';
import { launchScenario } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';

const ScenarioProgressPhaseExpanded = ({ id, phase, status, disabled, progress, text = '' }) => {
  const [width, setWidth] = useState(0);
  const intl = useIntl();

  // dispatch
  const dispatch = useDispatch();

  const iconByStatus = {
    void: StatusAddIcon,
    error: StatusErrorIcon,
    valid: StatusSuccessIcon,
    done: StatusDoneIcon,
    restart: StatusRestartIcon,
    stopped: StatusStopIcon,
    failed: StatusErrorIcon,
  };

  const textByPhase = {
    void: intl.formatMessage({ id: 'phase.status.void' }),
    valid: intl.formatMessage({ id: 'phase.status.valid' }),
    error: intl.formatMessage({ id: 'phase.status.error' }),
    done: intl.formatMessage({ id: 'phase.status.done' }),
    stopped: intl.formatMessage({ id: 'phase.status.stopped' }),
    failed: intl.formatMessage({ id: 'phase.status.failed' }),
  };

  const calcWidth = () => {
    setWidth(`${progress || 0}%`);
  };

  useEffect(() => {
    setTimeout(() => {
      calcWidth();
    });
  }, []);

  useEffect(() => {
    calcWidth();
  }, [progress]);

  const getIcon = () => {
    return iconByStatus[status];
  };

  const getPhase = () => {
    return textByPhase[status];
  };

  const onClickRestart = () => {
    dispatch(launchScenario({ id, restart: true, allPhases: true }));
  };

  // (['done', 'valid'].includes(status) || (status == 'stopped' && phase == 3)) && !disabled -> for removing restart button on phase 3 stopped
  return (
    <div
      className={`ScenarioProgressPhaseExpanded ${['error', 'failed'].includes(status) ? 'error' : ''} 
                    ${status === 'valid' ? 'valid' : ''} ${status === 'running' ? 'running' : ''} 
                    ${status === 'running' && progress !== null ? 'progress' : ''} 
                    ${status === 'done' ? 'done' : ''} 
                    ${status === 'stopped' ? 'stopped' : ''}`}
    >
      <div className="progress-scenario" style={{ width }}>
        <WText typo="small" color={status === 'done' ? 'black' : 'white'}>{`P${phase}`}</WText>
      </div>
      {status !== 'running' && (
        <WText typo="captionLight" color={status === 'done' ? 'black' : 'white'}>
          {getPhase()}{' '}
        </WText>
      )}
      {status === 'running' ? (
        <WText typo="small">{width}</WText>
      ) : ['done'].includes(status) && !disabled ? (
        <TooltipWrapper tooltipText={'Restart'}>
          <img
            src={getIcon()}
            style={{ height: '16px', width: '16px' }}
            alt="img"
            onMouseOver={(e) => (e.currentTarget.src = iconByStatus['restart'])}
            onMouseOut={(e) => (e.currentTarget.src = getIcon())}
            onClick={onClickRestart}
          />
        </TooltipWrapper>
      ) : (
        <img src={getIcon()} style={{ height: '16px', width: '16px' }} alt="img" />
      )}
    </div>
  );
};

export default ScenarioProgressPhaseExpanded;

ScenarioProgressPhaseExpanded.propTypes = {
  phase: PropTypes.number,
  status: PropTypes.oneOf(['void', 'valid', 'error', 'running', 'done', 'failed', 'stopped']),
  disabled: PropTypes.bool,
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ScenarioProgressPhaseExpanded.defaultProps = {
  phase: 1,
  status: 'void',
  disabled: false,
  progress: null,
};
