import { PropTypes } from 'prop-types';

// local dependencies
import Modal from '../Modal/Modal';
import UserForm from './UserForm';
import './UserModal.scss';

const UserModal = ({ isOpen, openHandler, isAddNewUser = false }) => {
  // style
  const style = { width: '30rem' };

  return (
    <Modal isOpen={isOpen} closeBtn={false} dismissable={false} openHandler={openHandler} style={style}>
      {{
        header: (
          <div className="validation-modal-wrapper">
            <div className="validation-modal-title">
              <p style={{ marginTop: '-1rem', fontSize: '1.2rem', color: 'white', marginLeft: '-1rem' }}>
                {isAddNewUser ? 'Add' : 'Update'} User
              </p>
            </div>
          </div>
        ),
        body: <UserForm openHandler={openHandler} isAddNewUser={isAddNewUser} />,
      }}
    </Modal>
  );
};

UserModal.propTypes = {
  isOpen: PropTypes.bool,
  openHandler: PropTypes.func.isRequired,
  isAddNewUser: PropTypes.bool,
};

UserModal.defaultProps = {
  isOpen: false,
  isAddNewUser: false,
};

export default UserModal;
