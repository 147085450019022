import PropTypes from 'prop-types';

const Popover = (props) => {
  return (
    <div>
      {props.mounted && (
        <div
          ref={props.setTooltipRef}
          {...props.getTooltipProps({
            className: 'tooltip-container',
            style: props.visible ? { visibility: 'visible' } : { visibility: 'hidden', pointerEvents: 'none' },
          })}
        >
          <div {...props.getArrowProps({ className: 'tooltip-arrow' })} />
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Popover;

Popover.propType = {
  mounted: PropTypes.bool,
  setTooltipRef: PropTypes.object,
  getTooltipProps: PropTypes.object,
  visible: PropTypes.bool,
  getArrowProps: PropTypes.object,
  children: PropTypes.children,
};

Popover.defaultProps = {
  mounted: false,
  setTooltipRef: {},
  getTooltipProps: {},
  visible: false,
  getArrowProps: {},
  children: [],
};
