export const STATUSES = ['IDLE', 'QUEUED', 'RUNNING', 'DONE'];

export const STATUSES_OBJ = {
  idle: 'IDLE',
  queued: 'QUEUED',
  running: 'RUNNING',
  done: 'DONE',
};

export const MODEL_EXIT_STATUSES_OBJ = {
  finished: 'FINISHED',
  stopped: 'STOPPED',
  failed: 'FAILED',
}

export const WS_STATUSES = ['RUNNING', 'QUEUED', 'PENDING', 'COMPLETED'];
export const WS_STATUSES_OBJ = {
  running: 'RUNNING',
  queued: 'QUEUED',
  pending: 'PENDING',
  completed: 'COMPLETED',
};

export const OUTPUTS = ['1', '2', '3'];

export const PHASE_STATUSES = {
  void: 'VOID',
  valid: 'VALID',
  invalid: 'INVALID',
  running: 'RUNNING',
  done: 'DONE',
  error: 'ERROR',
};

export const SUBPHASE_STATUSES = {
  void: 'VOID',
  warning: 'WARNING',
  error: 'INVALID',
  valid: 'VALID',
};

export const DB_STATUSES = {
  valid: 'VALID',
  expired: 'EXPIRED',
};

export const PHASES_TRANSFORMER = {
  toNumber: {
    phaseOne: 1,
    phaseTwo: 2,
    phaseThree: 3,
  },
  toString: {
    1: 'phaseOne',
    2: 'phaseTwo',
    3: 'phaseThree',
  },
};
