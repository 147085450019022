import axiosInstance from './axios';

export const calculateRemainingSessionTime = () => {
  try {
    const accessToken = localStorage.getItem('access');
    if (!accessToken) return 0;
    const tokenParts = JSON.parse(atob(accessToken.split('.')[1]));
    const now = Math.ceil(Date.now() / 1000);
    return tokenParts.exp - now - 5;
  } catch (e) {
    return 0;
  }
};

export const refreshSession = () => {
  return new Promise((resolve) => {
    const refreshToken = localStorage.getItem('refresh');
    axiosInstance
      .post('auth/jwt/refresh/', { refresh: refreshToken })
      .then((response) => {
        localStorage.setItem('access', response.data.access);
        resolve(true);
      })
      .catch((e) => {
        console.error(e);
        resolve(false);
      });
  });
};

export const secToTime = (seconds) => {
  const date = new Date(null);
  date.setSeconds(seconds);
  return date.toISOString().substr(14, 5);
};
