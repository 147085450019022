/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

// local dependencies
import PhaseItem from '../../../../../components/PhaseItem/PhaseItem';
import { PHASE_STATUSES, STATUSES } from '../../../../../constants/data';
import { disablePhaseInputs } from '../../../../../utils/utils';
import ScenarioContext from '../../../ScenarioContext';
import OptimizationParametersInput from './forms/OptimizationParametersInput';

// eslint-disable-next-line no-unused-vars
const Phase3 = ({ phase, currentPhase, readOnly }) => {
  // redux state
  const { data: scenario } = useSelector((state) => state.scenarios);

  // context
  const scenarioContext = useContext(ScenarioContext);

  const phase3InputIsDisabled = () => {
    if (scenarioContext.edit) return disablePhaseInputs(phase, scenario);
    return true;
  };

  return (
    <PhaseItem title="Phase 3" id="phase3" display={false}>
      <OptimizationParametersInput phaseData={phase} disabled={phase3InputIsDisabled()} readOnly={readOnly} />
    </PhaseItem>
  );
};

Phase3.propTypes = {
  phase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    file_id: PropTypes.number,
    scenario_id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    phase_number: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    status: PropTypes.oneOf(Object.values(PHASE_STATUSES)).isRequired,
    // eslint-disable-next-line react/require-default-props
    data: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        section: PropTypes.string,
        status: PropTypes.oneOf(Object.values(PHASE_STATUSES)),
        value: PropTypes.shape({
          coverageQualityThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          minimumMicrosegmentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          percentageOfClients: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          qualityConsistencyThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          targetMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          trafficTailSitesNonConsidered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          userName: PropTypes.string,
          userRole: PropTypes.string,
          kmeasnModelScenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      })),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // eslint-disable-next-line react/no-unused-prop-types
    created_at: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    updated_at: PropTypes.string,
  }).isRequired,
  currentPhase: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
export default Phase3;
