/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';

// local dependencies
import WText from '../WText/Wtext';
import './SubPhaseHeader.scss';

const SubPhaseHeader = ({ index, title }) => {
  return (
    <div className="subphase-header">
      <div className="texts">
        <WText typo="smallLight">{`${index}`.padStart(2, '0')}</WText>
        <WText>{title}</WText>
      </div>
    </div>
  );
};

export default SubPhaseHeader;

SubPhaseHeader.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};
