/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPhaseFolderKey } from '../../utils/utils';
// local dependencies
import './WorkSheetsList.scss';

const WorkSheetsList = ({ workBookID, worksheets, phaseID,scenarioDetails }) => {
  // just to iterate the 6 skeleton
  const iterateList = [1, 2, 3, 4, 5, 6];

  // navigate
  const navigate = useNavigate();

  // params
  const { id: scenarioID } = useParams();

  // global state
  const { loading } = useSelector((state) => state.reports);

  const clickHandler = (refname) => {
    if(scenarioDetails?.tableau_phase_1_folder||scenarioDetails?.tableau_phase_2_folder||scenarioDetails?.tableau_phase_3_folder){
      navigate(`/report/detail/scenario/${scenarioID}/worksheet/${getPhaseFolderKey(scenarioDetails,phaseID)}/phase/${phaseID}/${refname}`, {
        replace: false,
      });
    }else{
      navigate(`/report/detail/scenario/${scenarioID}/worksheet/${workBookID}/phase/${phaseID}/${refname}`, {
        replace: false,
      });
    };
    }
 

  return (
    <ul className="fa-ul custom-ul">
      {loading && (
        <div style={{ position: 'relative' }}>
          {iterateList.map((idx) => {
            return (
              <li className="worksheet worksheet-skeleton" key={idx}>
                <span className="fa-li">
                  <i className="fa fa-spinner fa-spin" />
                </span>
                <a href="/">&nbsp;</a>
              </li>
            );
          })}
        </div>
      )}
      {worksheets
        ?.filter((ws) => ws.workbook === workBookID)
        ?.filter((wsi) => wsi.is_active === true)
        .map((workSheetItem) => {
          return (
            <li className="worksheet" key={`${workSheetItem.name}--${workSheetItem.id}`}>
              <span className="fa-li">
                <i className="fa fa-circle" />
              </span>
              <button
                type="button"
                style={{ color: 'white', cursor: 'pointer' }}
                onClick={() => clickHandler(workSheetItem.tableau_ref_name)}
              >
                {workSheetItem.name}
              </button>
            </li>
          );
        })}
    </ul>
  );
};

WorkSheetsList.propTypes = {
  workBookID: PropTypes.number.isRequired,
  worksheets: PropTypes.arrayOf({
    id: PropTypes.number,
    name: PropTypes.string,
    tableau_ref_name: PropTypes.string,
    workbook: PropTypes.number,
    phase: PropTypes.number,
    is_active: PropTypes.bool,
  }).isRequired,
  phaseID: PropTypes.number.isRequired,
};

export default WorkSheetsList;
