/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import LOCALES from './locale';
import EnglishMessages from './locale/en';

const messages = {
  [LOCALES.ENGLISH]: EnglishMessages,
};

export default messages;
