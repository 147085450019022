export default {
  general: {
    title: 'NEXO by BCG',
    titleTemplate: '%s | NEXO by BCG',
    description: 'NEXO by BCG',
  },
  auth: {
    title: 'Login',
  },
};
