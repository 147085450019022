/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import axios from '../../utils/axios';
import {
  USER_BEGIN,
  USER_AUTH,
  USER_AUTH_ERROR,
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_ERROR,
  USER_RETRIEVE,
  USER_LOGOUT,
} from '../actionTypes';

import store from '../store';

const getUser = () => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: USER_BEGIN });
      // check user.
      if (!localStorage.getItem('access')) {
        const userNotLoggedIn = new Error('User not logged in.');
        return dispatch({ type: USER_ERROR, payload: userNotLoggedIn });
      }
      const url = '/auth/users/me/';
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: USER_RETRIEVE, payload: { data } });
    } catch (error) {
      // Handle error.
      if (error?.response?.data.code === 'token_not_valid') {
        return dispatch(logOut());
      }
      dispatch({ type: USER_ERROR, payload: { error } });
      if (error?.response?.status === 403) {
        return dispatch(logOut());
      }
    }
  };
};

const loginUser = (email, password) => {
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: USER_BEGIN });
      // Login user.
      const url = '/auth/jwt/create/';
      // console.log('API call:', url);
      const { data } = await axios.post(url, { email, password });
      dispatch({ type: USER_LOGIN, payload: { data } });
      // Fetch user data.
      return dispatch(getUser());
    } catch (error) {
      // Handle error.
      dispatch({ type: USER_LOGIN_ERROR, payload: { error } });
      if (error?.response?.status === 401) {
        return dispatch({ type: USER_ERROR, payload: { error: { message: 'Incorrect Username or Password' } } });
      }
      return dispatch({ type: USER_ERROR, payload: { error: { message: error?.message || 'Unknown error' } } });
    }
  };
};

const checkAuth = () => {
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: USER_BEGIN });
      if (!localStorage.getItem('access'))
        return dispatch({ type: USER_AUTH_ERROR, payload: { error: { message: 'Not logged in.' } } });
      const token = { token: localStorage.getItem('access') };
      const url = '/auth/jwt/verify/';
      // console.log('API call:', url);
      const res = await axios.post(url, token);
      if (res) {
        const { data } = await axios.post(url, token);
        // Check if token is valid.
        if (data.code === 'token_not_valid')
          return dispatch({ type: USER_AUTH_ERROR, payload: { error: { message: 'Authentication failed.' } } });
        // Return success.
        return dispatch({ type: USER_AUTH, payload: { data } });
      } else {
        return dispatch({ type: USER_AUTH_ERROR, payload: { error: { message: 'Some thing is wrong. Please check Auth API' } } });
      }
    } catch (error) {
      // Handle error.
      return dispatch({ type: USER_AUTH_ERROR, payload: { error } });
    }
  };
};

const logOut = () => {
  const currentState = store.getState();
  clearInterval(currentState.scenarios.scenarioCheckIntervalValue)
  clearInterval(currentState.scenarios.clusterStatusCheckIntervalValue)
// Return dispatch.
  return async (dispatch) => dispatch({ type: USER_LOGOUT });
};

// eslint-disable-next-line import/prefer-default-export
export { getUser, loginUser, logOut, checkAuth };
