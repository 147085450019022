/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import {
  REPORT_BEGIN,
  REPORT_ERROR,
  REPORT_SCENARIO_GET,
  REPORT_PHASE_GET,
  REPORT_WORKBOOK_GET,
  REPORT_WORKSHEET_GET,
} from '../actionTypes';

const initialState = {
  loading: false,
  error: null,
  reportScenario: null,
  phase: null,
  workbooks: null,
  worksheets: [],
};

// eslint-disable-next-line default-param-last
const reportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REPORT_SCENARIO_GET:
      return {
        ...state,
        loading: false,
        reportScenario: payload.data,
      };

    case REPORT_PHASE_GET:
      return {
        ...state,
        loading: false,
        phase: payload.data,
      };
    case REPORT_WORKBOOK_GET:
      return {
        ...state,
        loading: false,
        workbooks: payload.data,
      };
    case REPORT_WORKSHEET_GET:
      return {
        ...state,
        loading: false,
        worksheets: payload.data,
      };
    case REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default reportReducer;
