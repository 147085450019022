import './DifferenceComponent.scss';

const DownComponent = () => {
  return (
    <div className="svg-container">
      <svg
        className="circle"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" transform="matrix(1 0 0 -1 0 24)" fill="url(#paint0_linear_247_8747)" />
        <defs>
          <linearGradient id="paint0_linear_247_8747" x1="46" y1="-10" x2="12" y2="24" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DC504E" />
            <stop offset="1" stopColor="#762B2A" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="white"
        className="bi bi-chevron-down chevron"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
        />
      </svg>
    </div>
  );
};

const UpComponent = () => {
  return (
    <div className="svg-container">
      <svg
        className="circle"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" transform="matrix(1 0 0 -1 0 24)" fill="url(#paint0_linear_247_8753)" />
        <defs>
          <linearGradient
            id="paint0_linear_247_8753"
            x1="12"
            y1="24"
            x2="51"
            y2="-19.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#029765" />
            <stop offset="1" stopColor="#03FDA9" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="white"
        className="bi bi-chevron-up chevron"
        viewBox="0 0 16 16"
      >
        <path
          filRule="evenodd"
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
        />
      </svg>
    </div>
  );
};

const DifferenceComponent = ({ direction }) => {
  if (direction === 'down') {
    return <DownComponent />;
  }
  return <UpComponent />;
};

export default DifferenceComponent;
