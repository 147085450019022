import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import axios from '../../../utils/axios';
import PieChartComponent from '../../../components/Chart/PieChartComponent';
import HeatmapChart from '../../../components/Chart/HeatmapChart';
import BarChartComponent from '../../../components/Chart/BarChartComponent';
import Filters from '../../../components/Filters/Filters';
import { WSpinnerLarge } from '../../../components/WLoader/WSpinner';

import { areObjectsEqual, convertToTitleCase, formatNumber } from '../../../utils/utils';
import { tooltip } from 'leaflet';

const DeviceChange = ({ scenarioID }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);

  const categoryColors = {
    '2G': '#82ca9d', // Orange background: #B06811;

    '3G': '#A4548B', // Green #3E8838
    '4G': '#3E8838', // Red
    '5G': '#B06811',
  };

  const options = {
    chart: {
      type: 'heatmap',
      foreColor: '#f0f0f0',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: '#28a745',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#1f1f1f'],
      },
    },
    xaxis: {
      type: 'category',
      categories: ['2G', '3G', '4G', '5G'],
      labels: {
        style: {
          colors: ['#ffffff'],
        },
        // formatter: function (val) {
        //   return 'to ' + val;
        // },
      },
      title: {
        text: 'Final technology',
        style: {
          color: '#ffffff',
          // fontSize: '16px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#ffffff'],
        },
        // formatter: function (val) {
        //   return 'from ' + val;
        // },
      },
      title: {
        text: 'Initial technology',
        style: {
          color: '#ffffff',
          // fontSize: '16px',
        },
      },
    },
    grid: {
      borderColor: '#333',
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const handleFilterChange = (key, type, selectedValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        type,
        values: selectedValues,
      },
    }));
  };

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/phase1-devicechange/';
    axios
      .post(url, filters, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setFilterData(response.data.filters);
        Object.keys(response.data.filters).forEach((key) => {
          const type = response.data.filters[key].type;
          const values = response.data.filters[key].values;
          handleFilterChange(key, type, values);
        });
        setLoading(false);
      });
  }, []);

  const applyFilters = () => {
    setLoading(true);
    const url = '/api/charts/phase1-devicechange/';
    const filtersBody = areObjectsEqual(filters, filterData) ? {} : filters;
    axios
      .post(url, filtersBody, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        // setFilterData(response.data.filters);
        setLoading(false);
      });
  };

  return (
    // <div
    //   style={{
    //     display: 'flex',
    //     flexWrap: 'wrap',
    //     justifyContent: 'space-between',
    //     alignItems: 'flex-start',
    //     marginTop: '20px',
    //   }}
    // >
    //   <div
    //     style={{
    //       width: '48%',
    //       height: '306px',
    //       marginBottom: '20px',
    //       borderRadius: '8px',
    //       border: '0.5px solid transparent',
    //     }}
    //   >
    //     <PieChartComponent data={pieChartData} categoryColors={categoryColors} />
    //   </div>

    //   <div
    //     style={{
    //       width: '48%',
    //       height: '306px',
    //       marginBottom: '20px',
    //       borderRadius: '8px',
    //       border: '0.5px solid transparent',
    //     }}
    //   >
    //     <HeatmapChart />
    //   </div>
    //   <div
    //     style={{
    //       width: '48%',
    //       height: '306px',
    //       marginBottom: '20px',
    //       marginTop: '300px',
    //       borderRadius: '8px',
    //       border: '0.5px solid transparent',
    //     }}
    //   >
    //     <BarChartComponent phase={1} data={sampleBarData[0]} index={0} />
    //   </div>
    //   <div
    //     style={{
    //       width: '48%',
    //       height: '306px',
    //       marginBottom: '20px',
    //       marginTop: '300px',
    //       borderRadius: '8px',
    //       border: '0.5px solid transparent',
    //     }}
    //   >
    //     <BarChartComponent phase={1} data={sampleBarData[1]} index={1} />
    //   </div>
    // </div>
    <div className="container1">
      {/* {isOpen && <FiltersModal isOpen={isOpen} openHandler={openHandler} filterData={filterData} />} */}
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div style={{ flex: 1, marginLeft: '5px' }}>
            <Filters
              data={filterData}
              filters={filters}
              handleFilterChange={handleFilterChange}
              applyFilters={applyFilters}
            />
          </div>
          <div style={{ flex: 9, height: '75vh', overflowY: 'scroll' }}>
            <div className="p-grid p-nogutter stats-container">
              <div className="p-col-12 p-md-6 cards">
                {Object.keys(data.stats[0]).map((key) => (
                  <Card className="custom-card">
                    <div className="card-content">
                      <h2>{convertToTitleCase(key)}</h2>
                      <h3>{formatNumber(data.stats[0][key])}</h3>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginTop: '20px',
              }}
            >
              <div
                style={{
                  width: '48%',
                  height: '306px',
                  marginBottom: '20px',
                  borderRadius: '8px',
                  border: '0.5px solid transparent',
                }}
              >
                <PieChartComponent
                  data={data.chart_data.pie_chart}
                  categoryColors={categoryColors}
                  title="Customers per technology"
                />
              </div>

              <div
                style={{
                  width: '48%',
                  height: '306px',
                  marginBottom: '20px',
                  borderRadius: '8px',
                  border: '0.5px solid transparent',
                }}
              >
                <HeatmapChart
                  series={data.chart_data.heatmap_chart}
                  options={options}
                  title="Propensity to device technology change"
                  height={450}
                  width={450}
                />
              </div>
              <div
                style={{
                  width: '48%',
                  height: '306px',
                  marginBottom: '20px',
                  marginTop: '300px',
                  borderRadius: '8px',
                  border: '0.5px solid transparent',
                }}
              >
                <BarChartComponent
                  phase={1}
                  data={data.chart_data.bar_charts[0]}
                  index={0}
                  xAxisKey="upgrade"
                  yAxisKey="delta_mbou"
                />
              </div>
              <div
                style={{
                  width: '48%',
                  height: '306px',
                  marginBottom: '20px',
                  marginTop: '300px',
                  borderRadius: '8px',
                  border: '0.5px solid transparent',
                }}
              >
                <BarChartComponent
                  phase={1}
                  data={data.chart_data.bar_charts[1]}
                  index={1}
                  xAxisKey="upgrade"
                  yAxisKey="delta_arpu"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DeviceChange;
