/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';

// local dependencies
import WorkbookTabsList from '../../components/WorkbookTabsList/WorkbookTabsList';
import { getReportScenario, getScenarioList, getPhaseWorkBooks, getPhaseWorksheets } from '../../redux/actions';
import WText from '../../components/WText/Wtext';
import './ReportDetailScreen.scss';
import { getPhaseFolderKey } from '../../utils/utils';
import Summary from '../ReportCharts/Phase3/Optimization/Summary';
import CumulativeImpact from '../ReportCharts/Phase3/Optimization/CumulativeImpact';
import Map from '../ReportCharts/Phase3/Optimization/Map';
import Detail from '../ReportCharts/Phase3/Optimization/Detail';
import DemandSummary from '../ReportCharts/Phase2/DemandSummary';
import RevenueSummary from '../ReportCharts/Phase1/RevenueSummary';
import Coverage from '../ReportCharts/Phase1/Coverage';
import DeviceChange from '../ReportCharts/Phase1/DeviceChange';
import DemandMap from '../ReportCharts/Phase2/DemandMap';
import QualityImpact from '../ReportCharts/Phase1/QualityImpact';
import Phase1DemandMap from '../ReportCharts/Phase1/Phase1DemandMap';
import SiteQuality from '../ReportCharts/Phase2/SiteQuality';
// import DemandMap1 from '../ReportCharts/Phase2/DemandMap1';

// TODO Tableau Conversion
export const convertTableau = ({ workbookID, tableaurefname, reportScenario, phaseID }) => {
  if (
    reportScenario?.tableau_phase_1_folder ||
    reportScenario?.tableau_phase_2_folder ||
    reportScenario?.tableau_phase_3_folder
  ) {
    return `${getPhaseFolderKey(reportScenario, phaseID)}/${tableaurefname}`;
  } else {
    switch (workbookID) {
      case '8':
        if (tableaurefname === 'Quality') {
          return `11/${tableaurefname}`;
        }
      case '9':
        return `8/${tableaurefname}`;
      case '10':
        return `9/${tableaurefname}`;
      case '11':
        return `12/${tableaurefname}`;
      case '12':
        return `13/${tableaurefname}`;
      default:
        return `${workbookID}/${tableaurefname}`;
    }
  }
};

const ReportDetailScreen = () => {
  // params
  const { scenarioID, workbookID, phaseID, tableaurefname } = useParams();

  // local state
  const [iLinkPara, setILinkPara] = useState({
    workbookID,
    tableaurefname,
  });
  const [phaseOptions, setPhaseOptions] = useState([]);

  // navigate
  const navigate = useNavigate();

  // dispatch
  const dispatch = useDispatch();

  // global state
  const { loading: reportLoading, reportScenario, workbooks, worksheets } = useSelector((state) => state.reports);
  const { loading: scenarioLoading, completed } = useSelector((state) => state.scenarios);

  // const iframeRef = useRef();
  // const [iframeHeight, setIframeHeight] = useState('100%');

  // useEffect(() => {
  //   setIframeHeight(`${iframeRef.current.contentWindow.document.documentElement.scrollHeight}px`);
  // }, []);

  useEffect(() => {
    // phase options
    const pOptions = reportScenario?.inputs?.map((ip) => {
      return {
        value: ip.phase_number,
        label: `Phase ${ip.phase_number}`,
        status: ip.status,
      };
    });

    setPhaseOptions(pOptions);
  }, [reportScenario]);

  useEffect(() => {
    dispatch(getReportScenario(scenarioID));
    dispatch(getScenarioList('DONE'));
    dispatch(getPhaseWorkBooks(phaseID));
    dispatch(getPhaseWorksheets(phaseID));
  }, []);

  const onPhaseChange = (value) => {
    dispatch(getPhaseWorkBooks(value));
    dispatch(getPhaseWorksheets(value));
    // re-render the page for changing the url
    if (
      reportScenario?.tableau_phase_1_folder ||
      reportScenario?.tableau_phase_2_folder ||
      reportScenario?.tableau_phase_3_folder
    ) {
      navigate(
        `/report/detail/scenario/${scenarioID}/worksheet/${getPhaseFolderKey(reportScenario, value)}/phase/${value}/${
          iLinkPara.tableaurefname
        }`
      );
    } else {
      navigate(
        `/report/detail/scenario/${scenarioID}/worksheet/${iLinkPara.workbookID}/phase/${value}/${iLinkPara.tableaurefname}`
      );
    }
  };

  const onScenarioChange = (value) => {
    dispatch(getReportScenario(value));

    // re-render the page for changing the url
    if (
      reportScenario?.tableau_phase_1_folder ||
      reportScenario?.tableau_phase_2_folder ||
      reportScenario?.tableau_phase_3_folder
    ) {
      navigate(
        `/report/detail/scenario/${value}/worksheet/${getPhaseFolderKey(reportScenario, phaseID)}/phase/${phaseID}/${
          iLinkPara.tableaurefname
        }`
      );
    } else {
      navigate(
        `/report/detail/scenario/${value}/worksheet/${iLinkPara.workbookID}/phase/${phaseID}/${iLinkPara.tableaurefname}`
      );
    }
  };

  const onWorkBookChange = (bookID) => {
    if (bookID != iLinkPara.workbookID) {
      const workbookSheets = worksheets.filter((s) => s.workbook == parseInt(bookID, 10)).filter((s) => s.is_active);
      const defaultWorkbookSheet = workbookSheets[0];
      if (workbookSheets.length) {
        setILinkPara({
          workbookID: defaultWorkbookSheet.workbook.toString(),
          tableaurefname: defaultWorkbookSheet.tableau_ref_name,
        });
        if (
          reportScenario?.tableau_phase_1_folder ||
          reportScenario?.tableau_phase_2_folder ||
          reportScenario?.tableau_phase_3_folder
        ) {
          navigate(
            `/report/detail/scenario/${scenarioID}/worksheet/${getPhaseFolderKey(
              reportScenario,
              phaseID
            )}/phase/${phaseID}/${workbookSheets[0].tableau_ref_name}`
          );
        } else {
          navigate(
            `/report/detail/scenario/${scenarioID}/worksheet/${defaultWorkbookSheet.workbook}/phase/${phaseID}/${workbookSheets[0].tableau_ref_name}`
          );
        }
      }
    }
  };

  const onWorkSheetChange = (bookID, tableauSheetName) => {
    setILinkPara({
      workbookID: bookID.toString(),
      tableaurefname: tableauSheetName,
    });

    // re-render the page for changing the url
    if (
      reportScenario?.tableau_phase_1_folder ||
      reportScenario?.tableau_phase_2_folder ||
      reportScenario?.tableau_phase_3_folder
    ) {
      navigate(
        `/report/detail/scenario/${scenarioID}/worksheet/${getPhaseFolderKey(
          reportScenario,
          phaseID
        )}/phase/${phaseID}/${tableauSheetName}`
      );
    } else {
      navigate(`/report/detail/scenario/${scenarioID}/worksheet/${bookID}/phase/${phaseID}/${tableauSheetName}`);
    }
  };

  return (
    <div className="report-detail-screen">
      <div className="reports-detail-header">
        <div>
          {reportLoading && <span className="scenario-name-skeleton" />}
          <div className="d-ib report-phase-title">
            <WText typo="h5" weight="light">
              {reportScenario?.name}
            </WText>
          </div>
          <div className="d-ib">
            <WText typo="small" color="gray">
              Reports
            </WText>
          </div>
        </div>
        <button
          className="flex"
          style={{ color: 'white' }}
          type="button"
          onClick={() =>
            window.open(
              `/report/${scenarioID}/worksheet/${workbookID}/phase/${phaseID}/${tableaurefname}`,
              '',
              'width=1000, height=800, left=300, top=200'
            )
          }
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: '0.5rem' }} />
          <WText typo="small" color="gray">
            Pop Out
          </WText>
        </button>
        <div className="flex">
          <div className="phase-dropdown">
            <div className="date-select dapi-input report-input d-ib">
              <label htmlFor="phase" className="date-input-label">
                Phases
              </label>
              <select
                id="phase"
                name="phase"
                component="select"
                className="date-input-select"
                onChange={(e) => onPhaseChange(e.target.value)}
                value={phaseID}
              >
                {phaseOptions?.map((po) => {
                  return (
                    <option
                      value={po.value}
                      className="date-input-select-option"
                      key={po.value}
                      disabled={po.status !== 'DONE'}
                    >
                      {po.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="scenario-dropdown">
            {scenarioLoading && <span className="scenario-dropdown-skeleton" />}
            <div className="date-select dapi-input d-ib">
              <label htmlFor="completed-scenario" className="date-input-label">
                Completed Scenarios
              </label>
              <select
                id="completed-scenario"
                name="completed-scenario"
                component="select"
                className="date-input-select"
                onChange={(e) => onScenarioChange(e.target.value)}
                value={scenarioID}
              >
                {completed?.map((c) => {
                  return (
                    <option value={c?.id} className="date-input-select-option" key={c?.id}>
                      {c?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="report-detail-container">
        <div className="virtual-scroller">
          {workbooks && (
            <WorkbookTabsList
              workbooks={workbooks}
              worksheets={worksheets}
              onWorkSheetChange={onWorkSheetChange}
              workbookID={iLinkPara.workbookID}
              onworkbookIDChange={onWorkBookChange}
            />
          )}
        </div>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {phaseID === '3' && tableaurefname === 'Summary' && <Summary scenarioID={scenarioID} />}
        {phaseID === '3' && tableaurefname === 'CumulativeImpact' && <CumulativeImpact scenarioID={scenarioID} />}
        {phaseID === '3' && tableaurefname === 'Map' && <Map scenarioID={scenarioID} />}
        {phaseID === '3' && tableaurefname === 'Detail' && <Detail scenarioID={scenarioID} />}

        {phaseID === '2' && tableaurefname === 'DemandSummary' && <DemandSummary scenarioID={scenarioID} />}
        {phaseID === '2' && tableaurefname === 'DemandMap' && <DemandMap scenarioID={scenarioID} />}
        {/* {phaseID === '2' && tableaurefname === 'DemandMap' && <DemandMap1 scenarioID={scenarioID} />} */}
        {phaseID === '2' && tableaurefname === 'SiteQuality' && <SiteQuality scenarioID={scenarioID} />}

        {phaseID === '1' && tableaurefname === 'RevenueSummary' && <RevenueSummary scenarioID={scenarioID} />}
        {phaseID === '1' && tableaurefname === 'Coverage' && <Coverage scenarioID={scenarioID} />}
        {phaseID === '1' && tableaurefname === 'DeviceChange' && <DeviceChange scenarioID={scenarioID} />}
        {phaseID === '1' && tableaurefname === 'QualityImpact' && <QualityImpact scenarioID={scenarioID} />}
        {phaseID === '1' && tableaurefname === 'DemandMap' && <Phase1DemandMap scenarioID={scenarioID} />}
      </div>
    </div>
  );
};

export default ReportDetailScreen;
