/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';

// local dependencies
import './WLink.scss';
import WIcon from '../WIcon/WIcon';

const WLink = ({ link, icon, iconPlacement, disabled, iconClickable, children }) => {
  const actualIcon =
    {
      back: 'arrow-left',
      next: 'arrow-right',
      up: 'arrow-up',
      down: 'arrow-down',
      less: 'view-less',
      all: 'view-all',
    }[icon] || icon;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={link} className={`WLink ${iconPlacement}`} disabled={disabled}>
      {children}
      <WIcon icon={actualIcon} size={16} clickable={iconClickable} marRight />
    </a>
  );
};

export default WLink;

WLink.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
  iconClickable: PropTypes.bool,
  disabled: PropTypes.bool,
};

WLink.defaultProps = {
  link: '',
  icon: '',
  iconPlacement: 'right',
  iconClickable: true,
  disabled: false,
};
