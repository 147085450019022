import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import { getSelectedUserId } from '../../../redux/actions/adminActions';
import './UserItem.scss';

const UserItem = ({ row, setModalVisible }) => {
  const { userFunctions } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const handleEditClick = (val) => {
    dispatch(getSelectedUserId(val));
    setModalVisible(true);
  };

  return (
    <tr>
      <td>{row.name}</td>
      <td>{row.email}</td>
      <td>{row?.role?.name}</td>
      <td>{userFunctions?.find((item) => item.id === row.function)?.name}</td>
      <td>{row.is_active ? <div className="active-green" /> : <div className="active-grey" />}</td>
      <td className="edit-button">
        <ButtonIcon
          icon="edit"
          key="edit"
          lottie
          text="Edit"
          clickHandler={() => {
            handleEditClick(row.id);
          }}
        />
      </td>
    </tr>
  );
};

export default UserItem;

UserItem.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.shape({
      name: PropTypes.string,
    }),
    function: PropTypes.number,
    is_active: PropTypes.bool,
  }),
  setModalVisible: PropTypes.func,
};

UserItem.defaultProps = {
  row: {},
  setModalVisible: () => {},
};
