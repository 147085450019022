/**
 Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/layout';
import routes from './routes';
import NotFoundScreen from '../screens/Error/NotFoundScreen';

const ExtendedRouter = ({ userRole, isAuthenticated, authCheckComplete }) => {
  const extendedRoutes = routes.map((route, index) => {
    let routeToElement = route.element;
    if (!route?.blankLayout) {
      routeToElement = <Layout>{route.element}</Layout>;
    }
    if (isAuthenticated && authCheckComplete && (userRole && !route?.allowedRoles?.includes(userRole))) {
      routeToElement = <NotFoundScreen />;
    }
    return <Route key={`KEY_ROUTE_${index * 0.567}`} path={route.path} element={routeToElement} />;
  });
  return <Routes>{extendedRoutes}</Routes>;
};

export default ExtendedRouter;
