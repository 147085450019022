/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

// local dependencies
import Modal from '../Modal/Modal';
import WDivider from '../WDivider/WDivider';
import WText from '../WText/Wtext';
import './ValidationModal.scss';

const ValidationModal = ({ isOpen, excelErrors, name, openHandler }) => {
  const [errorDetails, setErrorDetails] = useState([]);
  const [errorDownload, setErrorDownload] = useState([]);

  const headers = [
    { label: 'Sheet Name', key: 'sheetName' },
    { label: 'Rows', key: 'rows' },
    { label: 'Description', key: 'description' },
  ];

  const data = excelErrors?.details?.map((er) => {
    const eeSplit = er.split('|');
    return {
      sheetName: eeSplit[0],
      rows: eeSplit[1],
      description: eeSplit[2],
    };
  });

  const renderBodyComponent = (errDetails, msg = '') => {
    if (errDetails.length > 0) {
      return (
        <ol className="validation-modal-subtitle">
          <li>
            <div className="sheet li-header">Sheet Name</div>
            <div className="rows-columns li-header">Rows</div>
            <div className="explain li-header">Description</div>
          </li>
          <WDivider withMargin />
          {data?.map((dt) => {
            return (
              <>
                <li>
                  <div className="sheet li-error">{dt.sheetName}</div>
                  <div className="rows-columns li-error">{dt.rows}</div>
                  <div className="explain li-error">{dt.description}</div>
                </li>
                <WDivider withMargin />
              </>
            );
          })}
        </ol>
      );
    }
    return (
      <ol className="validation-modal-subtitle">
        <li>
          <div className="explain li-header">&nbsp;</div>
        </li>
        <li>{msg}</li>
        <li>Please verify the file and try again.</li>
      </ol>
    );
  };

  const renderDownloadButton = (errDetails) => {
    if (errDetails.length > 0) {
      return (
        <CSVLink data={data} headers={headers} filename={name} className="WButton secondary">
          Export errors as CSV
        </CSVLink>
      );
    }
    return [];
  };

  useEffect(() => {
    let varMsg = '';
    if (excelErrors?.details?.length === 0) {
      varMsg = `An error occured while uploading the excel file.`;
      varMsg += ` Possible cause for the error could be:`;
      varMsg += ` Invalid file or ${(excelErrors?.error || '').toLowerCase()}.`;
      setErrorDetails(renderBodyComponent(excelErrors?.details, varMsg));
    }
    if (excelErrors?.details?.length > 0) {
      setErrorDetails(renderBodyComponent(excelErrors?.details));
      setErrorDownload(renderDownloadButton(excelErrors?.details));
    }
  }, [excelErrors]);

  return (
    <Modal isOpen={isOpen} closeBtn dismissable openHandler={openHandler}>
      {{
        header: (
          <div className="validation-modal-wrapper">
            <div className="validation-modal-title">
              <WText typo="h3">Errors in uploaded file</WText>
            </div>
          </div>
        ),
        body: errorDetails,
        footer: (
          <div className="validation-modal-buttons">
            {errorDownload}
            <button type="button" className="WButton primary ok-button" onClick={() => openHandler(false)}>
              OK
            </button>
          </div>
        ),
      }}
    </Modal>
  );
};

ValidationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  excelErrors: PropTypes.shape({
    error: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.string),
  }),
  name: PropTypes.string,
  openHandler: PropTypes.func.isRequired,
};

ValidationModal.defaultProps = {
  excelErrors: {},
  name: {},
};

export default ValidationModal;
