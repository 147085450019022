import clsx from 'clsx';

const ScenarioSkeleton = ({ expanded }) => {
  return (
    <div className={`ScenarioSkeleton ${clsx({ expanded })}`}>
      <div className="Icon" style={{ animationDelay: '-0.25s' }} />
      <div className="Avatar" />
      <div className="TitleSection">
        <div className="Name" />
        <div className="Description" />
      </div>
      <div className="DateSection">
        <div className="Date" />
        <div className="Time" />
      </div>
      <div className="Default">
        <div className="default-skeleton-phase">
          <div className="Phase one" />
          <div className="Phase two" />
          <div className="Phase three" />
        </div>
      </div>
      <div className="Icon" style={{ animationDelay: '2.75s' }} />
      <div className="Icon" style={{ animationDelay: '3s' }} />
      <div className="Icon" style={{ animationDelay: '3.25s' }} />
      <div className="Icon" style={{ animationDelay: '3.5s' }} />
      <div className="Icon" style={{ animationDelay: '3.75s' }} />
    </div>
  );
};

export default ScenarioSkeleton;
