import { useEffect, useState } from 'react';
import axios from '../../../../utils/axios';
import LineChartComponent from '../../../../components/Chart/LineChartComponent';
import './Summary.scss';
import { WSpinnerLarge } from '../../../../components/WLoader/WSpinner';

const CumulativeImpact = ({ scenarioID }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/cumulative-impact/';
    axios
      .post(
        url,
        {},
        {
          headers: {
            scenario_id: `scenario_${scenarioID}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      });
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <LineChartComponent data={data} title="Cumulative Revenue Captured vs Capex Employed" />
      )}
    </>
  );
};

export default CumulativeImpact;
