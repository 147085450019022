import './SkeletonWrapper.scss';
import ScenarioSkeleton from '../ScenarioSkeleton/ScenarioSkeleton';

// eslint-disable-next-line
const SkeletonWrapper = ({ expanded, skeletonNumber }) => {
  const skeletonList = Array(skeletonNumber).fill(0);
  return (
    <>
      {skeletonList.map((_, index) => {
        return <ScenarioSkeleton expanded={expanded} key={`KEY_SCEN_SKEL${index * 0.234}`} />;
      })}
    </>
  );
};

export default SkeletonWrapper;
