import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import './TooltipSpan.scss';

const TooltipSpan = (props) => {
  const { tooltipText, children } = props;
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  return (
    <span className="tooltip-span">
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          {tooltipText}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
      <span ref={setTriggerRef}>{children}</span>
    </span>
  );
};

export default TooltipSpan;
