/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

// local dependencies
import SubPhaseHeader from '../../../../../components/SubPhaseHeader/SubPhaseHeader';
import WText from '../../../../../components/WText/Wtext';
import './UploadExcel.scss';
import { iconTypes, LottieIconWrapper } from '../../../../../components/Lottie/LottieIconWrapper';
import { WSpinnerMedium } from '../../../../../components/WLoader/WSpinner';
import { isValNumeric } from '../../../../../utils/utils';
import ButtonIcon from '../../../../../components/ButtonIcon/ButtonIcon';
import { deleteInputFilePhase, getScenario } from '../../../../../redux/actions';

const UploadExcel = ({
  id,
  index,
  uploadState,
  templNum,
  handleUpload,
  downloadLink,
  value,
  errors,
  disabled,
  fileLoader,
  phase,
}) => {
  const [file, setFile] = useState({});
  const [lottieHover, setLottieHover] = useState(false);
  const { phase1ExcelData, phase2ExcelData } = useSelector((state) => state.inputFiles);
  const { data: scenario } = useSelector((state) => state.scenarios);

  // dispatch
  const dispatch = useDispatch();

  const showFileName = () => {
    const xlInputData = (templNum === 1 && phase1ExcelData) || (templNum === 2 && phase2ExcelData) || null;
    if(!phase1ExcelData?.is_uploaded && !phase1ExcelData?.is_valid && (phase1ExcelData?.local_path)?.length>0){
      let fileName = `${templNum}.xlsx`;
      if (value) fileName = value;
      if (!value && file?.name) fileName = file?.name;
      if (!xlInputData?.name) fileName = xlInputData?.name;

      return `Please fix validation errors in ${fileName} & try again`;
    }

    if ((xlInputData !== null && xlInputData?.is_uploaded && isValNumeric(xlInputData?.id)) ) {
      let fileName = `template-phase-${templNum}.xlsx`;
      if (value) fileName = value;
      if (!value && file?.name) fileName = file?.name;
      if (!xlInputData?.name) fileName = xlInputData?.name;

      return fileName;
    }
    return <FormattedMessage id="scenario.inputs.uploadExcel.template.placeholder" />;
  };

  const showFileDeleteButton = () => {
    const comp = [];
    const xlInputData = (templNum === 1 && phase1ExcelData) || (templNum === 2 && phase2ExcelData) || null;

    if (xlInputData !== null && xlInputData?.is_uploaded && isValNumeric(xlInputData?.id) && !disabled) {
      comp.push(
        <div className="delete-upload-file-button" title="DeleteExcel" key="cancel-button">
          <div>
            <ButtonIcon
              lottie
              icon="cancel"
              clickHandler={(e) => {
                dispatch(deleteInputFilePhase(xlInputData?.input, scenario?.id, templNum));
                dispatch(getScenario(scenario?.id, false));
                e.preventDefault();
              }}
            />
          </div>
        </div>
      );
    }

    return comp;
  };

  const getSubPhaseTitle = (phase = 1) => {
    switch (phase) {
      case 2:
        return 'Upload configuration parameters of Phase 2'
      default:
        return 'Upload configuration parameters of Phase 1'
    }
  }

  return (
    <>
      <SubPhaseHeader title={getSubPhaseTitle(phase)} index={phase == 1 ? index : index - 1} />
      <div className="download-view-content" id={id}>
        <div
          className={`${disabled ? 'scenario-disable-cursor' : 'scenario-enable-cursor'} section-body`}
          title="UploadExcel"
        >
          {fileLoader ? (
            <div className="align-item-horizontal-center" style={{ padding: '15px 0' }}>
              <WSpinnerMedium />
            </div>
          ) : (
            <>
              {showFileDeleteButton()}
              <Dropzone
                disabled={disabled}
                onDrop={(acceptedFiles) => {
                  setFile(acceptedFiles[0]);
                  handleUpload(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      className="upload-area"
                      onMouseEnter={() => setLottieHover(true)}
                      onMouseLeave={() => setLottieHover(false)}
                    >
                      <LottieIconWrapper
                        iconConfig={{
                          iconType: iconTypes[4].toLowerCase(),
                          iconStatus: `${uploadState || 'initial'}`.toLowerCase(),
                          loop: true,
                          play: true,
                          iconHover: lottieHover,
                        }}
                      />
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="info-wrapper">
                <div className="info-content">
                  <WText typo="bodyLight">
                    {errors && <FormattedMessage id="scenario.inputs.uploadExcel.error.placeholder" />}
                    {showFileName()}
                  </WText>
                  <div className="download-content">
                    <WText typo="smallLight" color="grey07">
                      {(errors && <FormattedMessage id="scenario.inputs.uploadExcel.error.label" />) ||
                        (!value && (
                          <>
                            {/* <FormattedMessage id="scenario.inputs.uploadExcel.template.label" />
                            <a href={downloadLink} download>
                              <FormattedMessage id="scenario.inputs.uploadExcel.template.link" />
                            </a> */}
                          </>
                        )) || (
                          <>
                            {/* <FormattedMessage id="scenario.inputs.uploadExcel.success.label" />
                            <a href={downloadLink} download>
                              <FormattedMessage id="scenario.inputs.uploadExcel.success.link" />
                            </a> */}
                          </>
                        )}
                    </WText>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

UploadExcel.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
  uploadState: PropTypes.string.isRequired,
  templNum: PropTypes.number.isRequired,
  handleUpload: PropTypes.func.isRequired,
  downloadLink: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.any,
  disabled: PropTypes.bool,
  fileLoader: PropTypes.bool,
  phase: PropTypes.number,
};

UploadExcel.defaultProps = {
  index: 2,
  errors: [],
  disabled: false,
  fileLoader: false,
  phase: 1
};

export default UploadExcel;
