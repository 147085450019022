/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import {
  USER_BEGIN,
  USER_AUTH,
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_ERROR,
  USER_AUTH_ERROR,
  USER_RETRIEVE,
  USER_LOGOUT,
} from '../actionTypes';

const initialState = {
  loading: false,
  data: {},
  isAuthenticated: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_BEGIN:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        error: null,
      };
    case USER_AUTH:
      return {
        ...state,
        loading: false,
        authCheckComplete: true,
        isAuthenticated: true,
        authError: null,
        error: null,
      };
    case USER_RETRIEVE:
      return {
        ...state,
        loading: false,
        data: payload.data,
        isAuthenticated: !!payload?.data?.id,
        error: null,
      };
    case USER_ERROR:
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        loading: false,
        access: null,
        authCheckComplete: true,
        refresh: null,
        isAuthenticated: false,
        data: {},
        error: payload.error,
      };
    case USER_AUTH_ERROR:
      return {
        ...state,
        loading: false,
        authCheckComplete: true,
        isAuthenticated: false,
        authError: payload.error,
        error: null,
      };
    case USER_LOGIN:
      localStorage.setItem('access', payload.data.access);
      localStorage.setItem('refresh', payload.data.refresh);
      return {
        ...state,
        access: payload.data.access,
        refresh: payload.data.refresh,
        isAuthenticated: true,
        authCheckComplete: true,
        loginError: null,
        error: null,
      };
    case USER_LOGIN_ERROR:
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        loading: false,
        access: null,
        refresh: null,
        isAuthenticated: false,
        authCheckComplete: true,
        loginError: payload.error,
      };
    case USER_LOGOUT:
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        loading: false,
        access: null,
        refresh: null,
        authCheckComplete: true,
        isAuthenticated: false,
        data: {},
        loginError: null,
        authError: null,
        error: null,
        scenarioCheckIntervalValue:null,
        clusterStatusCheckIntervalValue:null
      };
    default:
      return state;
  }
};

export default userReducer;
