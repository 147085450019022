import userRoles from './userRoles';

const { ADMIN_1, ADMIN_2, EDITOR, VIEWER, ACTIVE, INACTIVE, REPORTS_ONLY } = userRoles;

export const scenarioActionButton = {
    duplicate: [ADMIN_1, ADMIN_2, EDITOR],
    unarchive: [ADMIN_1, ADMIN_2, EDITOR],
    archive: [ADMIN_1, ADMIN_2, EDITOR],
    edit: [ADMIN_1, ADMIN_2, EDITOR],
    view: [ADMIN_1, ADMIN_2, EDITOR, VIEWER],
    delete: [ADMIN_1, ADMIN_2, EDITOR],
    stop: [ADMIN_1, ADMIN_2, EDITOR],
    save: [ADMIN_1, ADMIN_2, EDITOR],
    run: [ADMIN_1, ADMIN_2, EDITOR],
}
