/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import PropTypes from 'prop-types';

// local dependencies
import WText from '../WText/Wtext';
import './SubPhase.scss';
import { PHASE_STATUSES, SUBPHASE_STATUSES } from '../../constants/data';

const statuses = [...Object.values(SUBPHASE_STATUSES)];
const phaseStatuses = [...Object.values(PHASE_STATUSES)];

const SubPhase = ({ id, status, phaseStatus, expired, isCurrentPhase, clickHandler }) => {
  const classes = {
    [SUBPHASE_STATUSES.void]: 'void',
    [SUBPHASE_STATUSES.warning]: 'warning',
    [SUBPHASE_STATUSES.error]: 'error',
    [SUBPHASE_STATUSES.valid]: 'valid',
  };

  const phaseClasses = {
    [PHASE_STATUSES.done]: 'done',
  };

  const statusClass = () => {
    return classes[status];
  };

  const phaseStatusClass = () => {
    return phaseClasses[phaseStatus];
  };

  const disabled = () => {
    return phaseStatus === (PHASE_STATUSES.done || PHASE_STATUSES.running); // && !isCurrentPhase;
  };

  const text = () => {
    return `${id}`.padStart(2, '0');
  };

  return (
    <div
      className={`sub-phase ${phaseStatusClass()} ${isCurrentPhase ? statusClass() : ''} ${statusClass()} ${expired ? 'expired' : ''
        }`}
      onClick={clickHandler}
    >
      <WText typo="small" color={!disabled() ? 'white' : 'grey06'}>
        {text()}
      </WText>
      <div className={`subphase-notification ${statusClass()}`} />
    </div>
  );
};

export default SubPhase;

SubPhase.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf([...Object.values(statuses)]),
  phaseStatus: PropTypes.oneOf([...Object.values(phaseStatuses)]),
  expired: PropTypes.bool,
  isCurrentPhase: PropTypes.bool,
  clickHandler: PropTypes.func.isRequired,
};

SubPhase.defaultProps = {
  expired: false,
  isCurrentPhase: false,
};
