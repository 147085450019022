import { useEffect, useState } from 'react';
import BarChartComponent from '../../../components/Chart/BarChartComponent';
import DifferenceComponent from '../../../components/DifferenceComponent/DifferenceComponent';
import axios from '../../../utils/axios';
import Filters from '../../../components/Filters/Filters';
import { WSpinnerLarge } from '../../../components/WLoader/WSpinner';
import '../Phase3/Optimization/Summary.scss';
import { areObjectsEqual, formatNumber } from '../../../utils/utils';

// const data = [
//   {
//     title: 'Revenue (€)',
//     series: [
//       { category: 'Current', value: 3146209296 },
//       { category: 'Forecast', value: 3065164107 },
//       { category: 'Upside', value: 3098006873 },
//     ],
//     difference: {
//       Forecast: -2.6,
//       Upside: 1.1,
//     },
//   },
//   {
//     title: 'Customers (#)',
//     series: [
//       { category: 'Current', value: 43412200 },
//       { category: 'Forecast', value: 44900750 },
//       { category: 'Upside', value: 44900750 },
//     ],
//     difference: {
//       Forecast: 3.4,
//       Upside: 0.0,
//     },
//   },
//   {
//     title: 'ARPU (€)',
//     series: [
//       { category: 'Current', value: 34.3 },
//       { category: 'Forecast', value: 32.43 },
//       { category: 'Upside', value: 32.43 },
//     ],
//     difference: {
//       Forecast: -5.4,
//       Upside: 0.0,
//     },
//   },
//   {
//     title: 'Churn (%)',
//     series: [
//       { category: 'Current', value: 0.97 },
//       { category: 'Forecast', value: 0.94 },
//       { category: 'Upside', value: 0.89 },
//     ],
//     difference: {
//       Forecast: -3.0,
//       Upside: -6.6,
//     },
//   },
//   {
//     title: 'MBoU (MB/month)',
//     series: [
//       { category: 'Current', value: 12735 },
//       { category: 'Forecast', value: 14726 },
//       { category: 'Upside', value: 14726 },
//     ],
//     difference: {
//       Forecast: 15.6,
//       Upside: 0.0,
//     },
//   },
//   {
//     title: 'MoU (min./month)',
//     series: [
//       { category: 'Current', value: 110.84 },
//       { category: 'Forecast', value: 109.64 },
//       { category: 'Upside', value: 109.64 },
//     ],
//     difference: {
//       Forecast: -1.1,
//       Upside: 0.0,
//     },
//   },
// ];

const DemandSummary = ({ scenarioID }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);

  const handleFilterChange = (key, type, selectedValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        type,
        values: selectedValues,
      },
    }));
  };

  useEffect(() => {
    setLoading(true);
    const url = '/api/charts/phase2-demandsummary/';
    axios
      .post(url, filters, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setFilterData(response.data.filters);
        Object.keys(response.data.filters).forEach((key) => {
          const type = response.data.filters[key].type;
          const values = response.data.filters[key].values;
          handleFilterChange(key, type, values);
        });
        setLoading(false);
      });
  }, []);

  const applyFilters = () => {
    setLoading(true);
    const url = '/api/charts/phase2-demandsummary/';
    const filtersBody = areObjectsEqual(filters, filterData) ? {} : filters;
    axios
      .post(url, filtersBody, {
        headers: {
          scenario_id: `scenario_${scenarioID}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        // setFilterData(response.data.filters);
        setLoading(false);
      });
  };

  return (
    <div className="container1">
      {/* {isOpen && <FiltersModal isOpen={isOpen} openHandler={openHandler} filterData={filterData} />} */}
      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0 0 10px' }}>
            <Filters
              data={filterData}
              filters={filters}
              handleFilterChange={handleFilterChange}
              applyFilters={applyFilters}
            />
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                height: '75vh',
                overflowY: 'scroll',
              }}
            >
              {data.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '20px',
                    width: 'calc(50% - 40px)',
                    // border: '1px solid #e5e5e5',
                    // borderRadius: '5px',
                    marginRight: '10px',
                  }}
                >
                  <div style={{ flex: 1, margin: '10px' }}>
                    <BarChartComponent phase={2} data={item} index={index} stacked={false} />

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginLeft: '20px',
                      }}
                    >
                      <strong>Difference (%):</strong>
                      {Object.keys(item.difference).map((key) => (
                        <p key={key} style={{ display: 'flex', alignItems: 'center', paddingTop: '3px' }}>
                          <DifferenceComponent direction={item.difference[key] < 0 ? 'down' : 'up'} />{' '}
                          <span style={{ padding: '4px 0 0 5px' }}>{formatNumber(item.difference[key])}%</span>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>{' '}
        </>
      )}
    </div>
  );
};

export default DemandSummary;
