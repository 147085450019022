/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import WText from '../../../WText/Wtext';
import './ScenarioProgressPhase.scss';

const ScenarioProgressPhase = ({ phase, warning, notification, disabled }) => {
  return (
    <div
      className={`ScenarioProgressPhase ${warning ? 'warning' : ''} ${notification ? 'notification' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <WText color={disabled ? 'grey06' : 'black'} typo="small">{`P${phase}`}</WText>
      {notification && <div className="Notification" />}
    </div>
  );
};

export default ScenarioProgressPhase;
