/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { isNumber } from 'lodash';

// local
import WIcon from '../WIcon/WIcon';
import WText from '../WText/Wtext';
import configs from '../Lottie/lottieList';
import LottieStateManager from '../LottieStateManager/LottieStateManager';
import { iconTypes, iconStatus, LottieIconWrapper } from '../Lottie/LottieIconWrapper';
import './ButtonIcon.scss';

const ButtonIcon = React.forwardRef((props, ref) => {
  // props destructuring
  // eslint-disable-next-line
  const { active, focused, icon, lottie, disabled, small, text, large, clickHandler } = props;
  const { count: notificationCount } = useSelector((state) => state.notification);

  const [hover, setHover] = useState(false);
  const [lteState, setLteState] = useState(undefined);

  const lottieState = () => {
    if (active) {
      return setLteState(hover || focused ? 'ON_HOVER' : 'ON');
    }
    return setLteState(hover || focused ? 'OFF_HOVER' : 'OFF');
  };

  useEffect(() => {
    lottieState();
  }, [hover]);

  const lottieProps = () => {
    const config = configs[`${icon}`];
    if (lottie && config) {
      const { file, size, states, defaultState } = config;
      const start = states[defaultState].to[lteState];
      const stop = [states[defaultState].value];
      return {
        file,
        start,
        stop,
        width: size.width,
        height: size.height,
      };
    }
    return undefined;
  };

  const classes = clsx({
    disabled,
    small,
    large,
    hover: hover || focused,
  });

  // eslint-disable-next-line
  const renderLottieIcon = () => {
    const iconName = icon;
    let item = [];
    switch (iconName.toLowerCase()) {
      case 'notification':
        item = (
          <LottieIconWrapper
            iconConfig={{
              iconType: iconTypes[0],
              iconStatus:
                isNumber(notificationCount.unread) && notificationCount.unread > 0 ? iconStatus[0] : iconStatus[1],
              loop: isNumber(notificationCount.unread) && notificationCount.unread > 0,
              iconHover: hover,
            }}
          />
        );
        break;
      case 'pin':
        item = (
          <LottieIconWrapper
            iconConfig={{
              iconType: iconTypes[1],
              iconStatus: active ? iconStatus[2] : iconStatus[3],
              loop: hover,
              iconHover: hover,
            }}
          />
        );
        break;
      case 'archive':
      case 'unarchive':
        item = (
          <LottieIconWrapper
            iconConfig={{
              iconType: iconName.toLowerCase(),
              iconStatus: iconName.toLowerCase(),
              loop: iconTypes[2].toLowerCase() === iconName.toLowerCase() ? (hover && true) || false : false,
              iconHover: hover,
            }}
          />
        );
        break;
      case 'cancel':
        item = (
          <LottieIconWrapper
            iconConfig={{
              iconType: iconName.toLowerCase(),
              iconStatus: iconStatus[10].toLowerCase(),
              loop: false,
              iconHover: hover,
            }}
          />
        );
        break;
      default:
        // eslint-disable-next-line react/jsx-props-no-spreading
        item = <LottieStateManager {...lottieProps()} />;
        break;
    }
    return item;
  };

  return (
    <button
      ref={ref}
      type="button"
      className={`button-icon button-${icon} ${classes}`}
      disabled={disabled}
      onClick={clickHandler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {lottieProps() && !disabled ? <>{renderLottieIcon()} </> : <div style={{ margin: 2 }}><WIcon icon={icon} color="white" size={16} /></div>}
      {text && (
        <WText color="gray" typo="captionLight" align="center">
          {text}
        </WText>
      )}
    </button>
  );
});

ButtonIcon.propType = {
  icon: PropTypes.string,
  lottie: PropTypes.bool,
  text: PropTypes.string,
  focused: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  clickHandler: PropTypes.func,
};

ButtonIcon.defaultProps = {
  lottie: false,
  focused: false,
  active: false,
  disabled: false,
  small: false,
  large: false,
  clickHandler: () => { },
};

export default ButtonIcon;
