import axios from '../../utils/axios';
import { NOTIFICATION_BEGIN, NOTIFICATION_GET, NOTIFICATION_ERROR, NOTIFICATION_READ_POST } from '../actionTypes';

const getNotificationList = () => {
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: NOTIFICATION_BEGIN });
      const url = `/api/notifications/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: NOTIFICATION_GET, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: NOTIFICATION_ERROR, payload: { error } });
    }
  };
};

const getNotificationCount = () => {
  return async (dispatch) => {
    try {
      // Begin.
      dispatch({ type: NOTIFICATION_BEGIN });
      const url = `/api/notifications/count/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: NOTIFICATION_READ_POST, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: NOTIFICATION_ERROR, payload: { error } });
    }
  };
};

const postNotificationListReadStatus = () => {
  return async (dispatch) => {
    try {
      const url = `/api/notifications/read/`;
      // console.log('API call:', url);
      const { data } = await axios.put(url);
      console.log(data);
      return dispatch(getNotificationCount());
    } catch (error) {
      // Handle error.
      return dispatch({ type: NOTIFICATION_ERROR, payload: { error } });
    }
  };
};

export { getNotificationList, getNotificationCount, postNotificationListReadStatus };
