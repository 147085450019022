/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useState, useContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { isEmpty, isNumber } from 'lodash';
import PropTypes from 'prop-types';

// local dependencies
import PhaseItem from '../../../../../components/PhaseItem/PhaseItem';
import DatesAndParametersInput from './forms/DatesAndParametersInput';
import UploadExcel from '../forms/UploadExcel';
import { getInputFilePhase1, getScenario, postInputFilePhase1 } from '../../../../../redux/actions';
import ScenarioContext from '../../../ScenarioContext';
import { PHASE_STATUSES, STATUSES } from '../../../../../constants/data';
import ValidationModal from '../../../../../components/ValidationModal/ValidationModal';
import { disablePhaseInputs, isValNumeric, prepareServerURL } from '../../../../../utils/utils';
import { useAlert } from 'react-alert';
// eslint-disable-next-line no-unused-vars
const Phase1 = ({ phase, user, currentPhase, readOnly }) => {
  const initialMonthState = {
    startMonth: '',
    endMonth: '',
    targetMonth: '',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'startMonth':
        return {
          ...state,
          startMonth: action.value,
        };
      case 'endMonth':
        return {
          ...state,
          endMonth: action.value,
        };
      case 'targetMonth':
        return {
          ...state,
          targetMonth: action.value,
        };
      default:
        return { ...state };
    }
  }

  // alert
  const alert = useAlert();
  // state
  const [iconState, setIconState] = useState('INITIAL');
  const [modalOpen, setModalOpen] = useState(false);

  //reducer for storing months
  const [monthState, monthDispatch] = useReducer(reducer, initialMonthState);

  // dispatch
  const dispatch = useDispatch();

  // context
  const scenarioContext = useContext(ScenarioContext);

  // i18n
  const intl = useIntl();

  // state
  const { loadingPhase1, phase1ExcelData, errorPhase1, loadingPhase1Excel } = useSelector((state) => state.inputFiles);
  const { data: scenario } = useSelector((state) => state.scenarios);

  const pData = phase && phase?.data?.fields[0];
  useEffect(() => {
    if (!isEmpty(errorPhase1) && errorPhase1 !== null) {
      switch (errorPhase1?.response?.status) {
        case (200, 422):
          setModalOpen(true);
          break;
        case 500:
          alert.error({ text: `Unable to upload the file, Please contact admin` }, { position: 'top center' });
          break;
        default:
          alert.error({ text: `something went wrong` }, { position: 'top center' });
          setModalOpen(false);
          break;
      }
    }
  }, [errorPhase1]);

  useEffect(() => {
    if (isValNumeric(phase1ExcelData?.id) && !phase1ExcelData?.is_valid) {
      setIconState('ERROR');
    } else if (isNumber(phase1ExcelData.id) && phase1ExcelData.id > 0) {
      if (errorPhase1 && phase1ExcelData?.errors) {
        setIconState('ERROR');
      } else if (!phase1ExcelData?.is_valid && phase1ExcelData?.is_uploaded) {
        setIconState('ERROR');
      } else if (
        !phase1ExcelData?.is_valid &&
        !phase1ExcelData?.is_uploaded &&
        phase1ExcelData.local_path.length > 0
      ) {
        setIconState('ERROR');
      } else if (phase1ExcelData?.is_valid && phase1ExcelData?.is_uploaded) {
        setIconState('SUCCESS');
      } else if (loadingPhase1) {
        setIconState('LOADING');
      }
    } else {
      setIconState('INITIAL');
    }
  }, [
    loadingPhase1,
    phase1ExcelData?.id,
    phase1ExcelData?.is_uploaded,
    phase1ExcelData?.is_valid,
    phase1ExcelData?.errors,
  ]);

  useEffect(() => {
    if (!isEmpty(phase) && phase !== undefined && phase !== null) {
      dispatch(getInputFilePhase1(phase.id, phase?.scenario_id));
    }
  }, [
    phase1ExcelData?.upload_url,
    phase1ExcelData?.local_path,
    phase1ExcelData?.is_valid,
    phase1ExcelData?.is_uploaded,
  ]);

  const handleUpload = async (acceptedFiles) => {
    const postData = new FormData();
    postData.append('local_path', acceptedFiles[0]);
    postData.append('startMonth', monthState.startMonth);
    postData.append('endMonth', monthState.endMonth);
    postData.append('targetMonth', monthState.targetMonth);
    await dispatch(postInputFilePhase1(phase.id, phase?.scenario_id, postData, phase1ExcelData));
    await dispatch(getScenario(phase?.scenario_id, false));
  };

  const phase1InputIsDisabled = () => {
    if (scenarioContext.edit) return disablePhaseInputs(phase, scenario);
    return true;
  };

  return (
    <PhaseItem title={intl.formatMessage({ id: 'scenario.phase' }, { phase: 1 })} id="phase1">
      <DatesAndParametersInput
        phase={phase}
        sectionData={pData}
        user={user}
        disabled={phase1InputIsDisabled()}
        monthDispatch={monthDispatch}
      />
      <UploadExcel
        id="uploadExcelPhase1"
        disabled={phase1InputIsDisabled()}
        index={2}
        templNum={1}
        value={phase1ExcelData && phase1ExcelData?.local_path?.split('/input_files/')[1]}
        errors={errorPhase1}
        handleUpload={handleUpload}
        downloadLink={prepareServerURL(scenarioContext?.phase1ExcelData?.upload_url)}
        uploadState={iconState}
        fileLoader={loadingPhase1Excel}
        phase={1}
      />
      <ValidationModal
        isOpen={modalOpen}
        openHandler={setModalOpen}
        excelErrors={errorPhase1?.response?.data}
        name="phase-01-template-error.csv"
      />
    </PhaseItem>
  );
};

Phase1.propTypes = {
  phase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    file_id: PropTypes.number,
    scenario_id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    phase_number: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    status: PropTypes.oneOf(Object.values(PHASE_STATUSES)).isRequired,
    // eslint-disable-next-line react/require-default-props
    data: PropTypes.shape({
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          section: PropTypes.string,
          status: PropTypes.oneOf(Object.values(PHASE_STATUSES)),
          value: PropTypes.shape({
            coverageQualityThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.PropTypes.number]),
            minimumMicrosegmentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.PropTypes.number]),
            percentageOfClients: PropTypes.oneOfType([PropTypes.string, PropTypes.PropTypes.number]),
            qualityConsistencyThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            targetMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            trafficTailSitesNonConsidered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            userName: PropTypes.string,
            userRole: PropTypes.string,
            kmeasnModelScenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        })
      ),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // eslint-disable-next-line react/no-unused-prop-types
    created_at: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    updated_at: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    photo_url: PropTypes.string,
    function: PropTypes.number,
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    role: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  currentPhase: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default Phase1;
