/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// local dependencies
import WLink from '../WLink/WLink';
import WText from '../WText/Wtext';
import './ViewAll.scss';

const ViewAll = ({ opened, link }) => {
  return (
    <div className="view-more-button">
      <hr className="view-more-divider" />
      {/* TODO: FADE transistion */}
      <div className="view-more-wrapper-text">
        <WLink key={opened} icon={opened ? 'less' : 'all'} iconClickable={false} link={link}>
          <WText typo="smallLight">
            <FormattedMessage id={`actions.${opened ? 'viewLess' : 'viewAll'}`} />
          </WText>
        </WLink>
      </div>
      <hr className="view-more-divider" />
    </div>
  );
};

ViewAll.propTypes = {
  opened: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
};

export default ViewAll;
