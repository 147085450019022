import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNumber } from 'lodash';
import { useAlert } from 'react-alert';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import { getUserRoles, getUserFunctions, getUsers, getSelectedUserId } from '../../../redux/actions/adminActions';
import WLoader from '../../WLoader/WLoader';
import UserItem from '../UserItem/UserItem';
import UserModal from '../../UserModal/UserModal';
import './UserScreenItem.scss';

const UserScreenItem = () => {
  const { loading, usersList, selectedUserId, error } = useSelector((state) => state.admin);
  const { data: authUser } = useSelector((state) => state.users);
  const alert = useAlert();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalIsAdd, setModalIsAdd] = useState(true);
  const [filterQuery, setFilterQuery] = useState('');
  const [filteredUserList, setFilteredUserList] = useState(usersList);

  const isAdmin = authUser?.role?.id == 1

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getUserRoles());
    dispatch(getUserFunctions());
  }, []);

  useEffect(() => {
    if (error) {
      if (error?.response.status !== 200) {
        alert.error(
          { text: [error.response.data[Object.keys(error.response.data)[0]]][0] },
          { position: 'top center' }
        );
      } else if (error?.response.status === 200) {
        alert.success(
          { text: [error.response.data[Object.keys(error.response.data)[0]]][0] },
          { position: 'top center' }
        );
        setModalVisible(false);
      } else {
        alert.error({ text: 'Something went wrong' }, { position: 'top center' });
      }
    }
  }, [error]);

  useEffect(() => {
    if (!modalVisible) {
      dispatch(getSelectedUserId(null));
      setModalIsAdd(true);
    }
  }, [modalVisible]);

  useEffect(() => {
    if (isNumber(selectedUserId) && selectedUserId > 0) {
      setModalIsAdd(false);
    } else {
      setModalIsAdd(true);
    }
  }, [selectedUserId]);

  useEffect(() => {
    let filteredData = [];
    if (filterQuery !== '') {
      usersList.forEach((element) => {
        const objVal = [];
        Object.values(element).forEach((val) => {
          if (typeof val === 'object' && val !== null) {
            Object.values(val).forEach((item) => {
              objVal.push(item);
            });
          } else {
            objVal.push(val);
          }
        });
        if (objVal.join('').toLowerCase().includes(filterQuery.toLowerCase())) {
          filteredData.push(element);
        }
      });

      setFilteredUserList(filteredData);
    } else {
      filteredData = [...usersList];
      setFilteredUserList(filteredData);
    }
  }, [filterQuery, usersList]);

  const renderItems = (data) => {
    let itemComp = [];
    if (typeof data !== 'undefined') {
      itemComp = data.map((row, index) => {
        return <UserItem row={row} key={`KEY_${index * 0.0003}`} setModalVisible={setModalVisible} />;
      });
    }
    return itemComp;
  };

  const handleAddUserClick = () => {
    setModalVisible(true);
  };

  // formik initial values
  const initialValues = {
    searchQuery: '',
  };

  // formik validation
  const validationSchema = Yup.object().shape({
    searchQuery: Yup.string(),
  });

  if (loading === true) {
    return <WLoader />;
  }

  return (
    <div className="user-container">
      <div className="user-screen-header">
        <div className="w-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', color: 'white' }}>
          Users
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize>
            {() => (
              <Form className="formik-input-report">
                <div className="date-input input-report">
                  <Field
                    name="searchQuery"
                    type="text"
                    placeholder="Search"
                    value={filterQuery}
                    onChange={(e) => setFilterQuery(e.target.value)}
                  />
                </div>
              </Form>
            )}
          </Formik>
          {isAdmin && <button type="button" className="WButton primary small animated" onClick={handleAddUserClick} >
            <div className="content">
              <div className="add" />
              Add User
            </div>
          </button>}
        </div>
      </div>
      {modalVisible ? (
        <UserModal isOpen={modalVisible} openHandler={setModalVisible} isAddNewUser={modalIsAdd} />
      ) : (
        <div className="table-responsive tableFixHead">
          <table className="table table-dark">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Function</th>
                <th scope="col">Active</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>{renderItems(filteredUserList)}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UserScreenItem;
