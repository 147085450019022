import axios from '../../utils/axios';
import { USER_FUNCTIONS_BEGIN, USER_FUNCTIONS_ERROR, USER_FUNCTIONS_GET } from '../actionTypes';

const getUserFunctions = () => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      dispatch({ type: USER_FUNCTIONS_BEGIN });
      const url = '/auth/user-functions/';
      // console.log("API call:", url);
      const { data } = await axios.get(url);
      return dispatch({ type: USER_FUNCTIONS_GET, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: USER_FUNCTIONS_ERROR, payload: { error } });
    }
  };
};

export { getUserFunctions };
