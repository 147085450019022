/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import clsx from 'clsx';
import { useRef, useState } from 'react';
import WIcon from '../WIcon/WIcon';

// local dependencies
import './WCheckboxAll.scss';

const WCheckboxAll = ({ value, label, name, disabled, error, checkHandler, uncheckHandler }) => {
  // local state
  const [hover, setHover] = useState(false);

  //  ref
  const checkboxRef = useRef();

  const isChecked = () => {
    return value === 'checked';
  };

  const isHalfChecked = () => {
    return value === 'halfChecked';
  };

  const isUnchecked = () => {
    return value === 'unchecked';
  };

  const computedClasses = clsx({
    disabled,
    error,
    hover,
    checked: isChecked() || isHalfChecked(),
  });

  const emitChange = () => {
    if (isChecked()) uncheckHandler();
    else checkHandler();
  };

  return (
    <div className="checkbox-all" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {/* <div className={`checkbox-all-icon ${computedClasses}`} onClick={emitChange}>
        {!isUnchecked?.() ? (
          <WIcon icon={isChecked?.() ? 'checked' : 'half-checked'} sie={16} color={error ? 'danger' : 'white'} />
        ) : null}
      </div>
      <input
        name={name}
        className="checkbox-all-input"
        type="checkbox"
        ref={checkboxRef}
        checked={value}
        disabled={disabled}
        onChange={emitChange}
      /> */}
      <label htmlFor={value} className={`checkbox-all-label ${computedClasses}`}>
        {label}
      </label>
    </div>
  );
};

export default WCheckboxAll;
