export default {
  title: 'Scenario',
  completed: 'Scenario completed',
  phase: 'Phase {phase}',
  reviewOutput: 'Review output',
  actions: {
    copy: 'Copy',
    archive: 'Archive',
    unarchive: 'Unarchive',
    edit: 'Edit',
    launch: 'Launch Phase',
    launchAll: 'Run Scenario',
    stop: 'Stop',
    cancel: 'Cancel',
    pause: 'Pause',
    view: 'View',
    save: 'Save',
    pin: 'Pin scenario',
    unpin: 'Unpin scenario',
    reports: 'Reports',
    delete: 'Delete Scenario'
  },
  notifications: {
    outdatedDatabase: 'Outdated database',
    error: 'An error occurred',
  },
  popovers: {
    stop: {
      title: 'Do you want to continue?',
      text: 'If you stop the stage you will lose the progress of the phase and it will be repositioned in pending',
      confirm: 'Yes, continue',
    },
    copy: {
      title: 'Do you want to continue?',
      text: 'You are going to duplicate a scenario with few content',
      confirm: 'Yes, continue',
    },
  },
  statuses: {
    empty: ' is empty',
    filled: ' is filled',
    done: ' is completed',
    queue: ' is queued',
    running: ' is simulating',
    edit: ' is being edited',
  },
  requests: {
    reset: {
      before: 'Database has been updated, ',
      link: 'relaunch phases',
    },
    expiredCompleted: 'Simulated scenario with the DB of {date}',
    edit: 'Check the wrong or empty inputs to continue',
    empty: 'Fill in the inputs',
    queue: 'You can stop the process by clicking on cancel',
    running: 'You can stop the process by clicking on stop',
    filled: {
      before: 'You can now',
      link: 'launch phase {phase}',
    },
    expired: {
      before: 'Relaunch',
      link: ' from phase {phase}',
    },
    completed: {
      before: 'Check phase 3’s ',
      link: 'output',
    },
  },
  inputs: {
    name: 'Scenario name',
    create: 'Create Scenario',
    title: 'Scenario Inputs',
    save: 'Save',
    launch: 'Launch',
    running: 'Running',
    datesAndParameters: {
      title: 'Dates of data inputs',
      variable: 'Variable',
      value: 'Value',
      startMonth: 'Start month',
      endMonth: 'End month',
      targetMonth: 'Target month',
      qualityConsistencyThreshold: 'Quality consistency threshold',
      coverageQualityThreshold: 'Coverage quality threshold',
      trafficTailSitesNonConsidered: 'Traffic tail sites not considered',
      minimumMicrosegmentSize: 'Minimum microsegment size (# costumers)',
      userName: 'User name',
      userRole: 'User role',
      percentageOfClients: 'Customer sample',
      kmeasnModelScenarioId: 'Kmeans model scenario ID'
    },
    // unitConversion: {
    //     title: 'Unit conversion',
    //     1: '2G',
    //     2: '3G',
    //     3: '4G',
    //     erlangs: 'Erlangs',
    //     kbps: {
    //         label: 'Kbps converted',
    //         units: 'Kbps',
    //     },
    //     spectralEfficiency: {
    //         label: 'Spectral Efficiency',
    //         units: 'b/hz',
    //     },
    // },
    // infrastructure: {
    //     title: 'Infrastructure',
    //     help: 'The priority of the frequency bands will depend on the order in which they are introduced.',
    //     empty: 'You don’t have any frequency bands yet',
    //     frequencyBand: 'Name',
    //     regionId: 'Region',
    //     technologyId: 'Technology',
    //     uniqueError: 'Region - Technology - Frequency has to be unique',
    //     frequency: {
    //         label: 'Frequency',
    //         units: 'MHz',
    //     },
    //     bandwidth: {
    //         label: 'Max bandwidth',
    //         units: 'MHz',
    //     },
    //     minimumBlock: {
    //         label: 'Minimum block',
    //         units: 'MHz',
    //     },
    //     add: 'Add new infrastructure',
    // },
    // segmentation: {
    //     title: 'Segmentation',
    //     variable: 'Variable',
    //     option: 'Option',
    //     value: 'Value',
    //     segment: { label: 'Segment' },
    //     arpuSegment: {
    //         label: 'Arpu Segment',
    //         minUnits: 'min',
    //         maxUnits: 'max',
    //     },
    //     throttle: {
    //         label: 'Throttle',
    //         minUnits: '%',
    //         maxUnits: '%',
    //     },
    //     mbouSegment: {
    //         label: 'Mbou Segment',
    //         minUnits: 'min',
    //         maxUnits: 'max',
    //     },
    //     mouSegment: {
    //         label: 'Mou Segment',
    //         minUnits: 'min',
    //         maxUnits: 'max',
    //     },
    //     speedSegment: {
    //         label: 'Speed Segment',
    //         minUnits: 'min',
    //         maxUnits: 'max',
    //     },
    //     technologyCustomer: { label: 'Customer technology' },
    //     simTechnology: { label: 'SIM Technology' },
    //     regionTraffic: { label: 'Region Traffic' },
    //     service: { label: 'Service' },
    // },
    // qualityTable: {
    //     title: 'Quality table',
    //     fields: {
    //         frequency: 'Frequency',
    //         technology: 'Technology',
    //         quality: 'Quality Type',
    //         trafficMax: {
    //             label: 'Traffic MAX',
    //             units: 'kbps',
    //         },
    //         concurrentUsersMax: 'Concurrent users Max',
    //         max: 'MAX',
    //         allUsers: 'All users',
    //     },
    // },
    uploadExcel: {
      title: 'Upload Excel',
      template: {
        placeholder: 'Choose a file or drag it here ',
        label: 'If you don’t have the source excel ',
        link: 'download it here ',
      },
      error: {
        placeholder: 'Invalid file ',
        label: 'Choose another file or drag it here ',
      },
      success: {
        label: 'If you want to review the Uploaded template ',
        link: 'download it here',
      },
    },
    preprocessParameters: {
      title: 'Preprocess Parameters',
      partitionLevel: 'Partition Level',
    },
    // grid: {
    //     title: 'Grid',
    //     type: 'Type',
    //     value: 'Zone',
    //     help: 'In case of not using a clutter file, the information will be obtained from the soil type of the network inventory',
    // },
    // fallbackRules: {
    //     title: 'Load balancing rules',
    //     technology: 'Technology',
    //     frequency: 'Frequency',
    //     region: 'Region',
    //     threshold: 'Threshold for users for load balancing',
    // },
    // vendors: {
    //     title: 'Vendors',
    //     fields: {
    //         region: 'Region',
    //     },
    // },
    // actionRules: {
    //     title: 'Action rules',
    //     notApply: 'Not apply',
    //     fields: {
    //         technology: 'Technology',
    //         frequency: 'Frequency',
    //         vendor: 'Vendor',
    //         userThroughput: {
    //             label: 'User Throughput',
    //             units: 'mbps',
    //         },
    //         prbsUtilization: {
    //             label: 'PRBs Utilization',
    //             units: '%',
    //         },
    //     },
    // },
    optimizationParameters: {
      title: 'Optimization Parameters',
      fields: {
        variable: 'Variable',
        value: 'Value',
        numberOfIterations: 'Number of iterations',
        numberOfActionsPerLoop: 'No. of actions per loop',
        numberOfActionsConsInEachLoop: 'No. of actions consolidated in each loop',
        minimumDisBwNeighbouringSites: 'Min. dist. between neighbouring sites in same loop',
        proportionClients: 'Proportion Clients',
        proxyType: 'Proxy Type',
      },
    },
  },
  outputs: {
    title: 'Scenario Outputs',
  },
  newSimulation: {
    title: 'New simulation',
    placeholder: 'Enter new simulation name',
    enterName: 'Save name',
    lastEdit: 'Last edit',
    author: 'Author',
    data: 'Commercial data',
    idle: 'Idle simulation',
  },
  alertMsg: {
    name: { update: 'Scenario name updated', error: '' },
    launch: { launch: 'Scenario launched', error: '' },
    pause: { pause: 'Scenario paused', error: '' },
    pinned: { pinned: 'Scenario pinned', unpinned: 'Scenario unpinned', error: '' },
    archive: { archive: 'Scenario archived', unarchive: 'Scenario unarchived', error: '' },
    function: { update: 'Scenario function updated', error: '' },
  },
};
