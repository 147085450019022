/**
 Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Add third-party dependencies.
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// Add local dependencies
import ExtendedRouter from './utils/ExtendedRouter';
import { checkAuth, getUser, logOut } from './redux/actions';
import { calculateRemainingSessionTime } from './utils/sessionUtils';
import ResetSessionModal from './components/ResetSessionModal/ResetSessionModal';
import { SessionContext } from './utils/SessionContext';

const Site = () => {
  // Redux hooks.
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const [userRole, setUserRole] = useState(null);

  const { isAuthenticated, data: authUser, authCheckComplete } = useSelector((state) => state.users);
  const userRole = authCheckComplete ? authUser?.role?.name : null

  const {
    remainingSessionTime,
    setRemainingSessionTime,
    sessionModalOpen,
    setSessionModalOpen,
    sessionRefreshed,
    setSessionRefreshed,
  } = useContext(SessionContext);

  useEffect(() => {
    // if (authCheckComplete && authUser?.role?.name) {
    //   setUserRole(authUser?.role?.name);
    // }
    if (location?.pathname !== '/login' && (!isAuthenticated || !authUser?.id) && authCheckComplete) {
      navigate('/login');
    }
  }, [authUser, authCheckComplete]);

  useEffect(() => {
    if ((authCheckComplete && isAuthenticated) || sessionRefreshed) {
      const remainingSessionTime = calculateRemainingSessionTime();
      setRemainingSessionTime(remainingSessionTime);

      if (remainingSessionTime < 300) {
        setSessionModalOpen(true);
      } else {
        const sessionModalOpenTime = (remainingSessionTime - 295) * 1000;
        setTimeout(() => {
          setSessionModalOpen(true);
        }, sessionModalOpenTime);
      }
      if (sessionRefreshed) {
        setSessionRefreshed(false);
      }
    }
  }, [authCheckComplete, sessionRefreshed]);

  useEffect(() => {
    // if (authCheckComplete && authUser?.role?.name) {
    //   setUserRole(authUser?.role?.name);
    // }
    dispatch(checkAuth());
    dispatch(getUser());
  }, []);

  useEffect(() => {
    let sessionTimer = null;
    if (remainingSessionTime > 0) {
      sessionTimer = setTimeout(() => {
        setRemainingSessionTime(remainingSessionTime - 1);
      }, 1000);
      return () => {
        clearTimeout(sessionTimer);
      };
    } else {
      if (remainingSessionTime === 0) {
        setSessionModalOpen(false);
        setRemainingSessionTime(-1);
        dispatch(logOut());
        clearTimeout(sessionTimer);
      }
    }
  }, [remainingSessionTime]);

  return (
    <>
      <ExtendedRouter userRole={userRole} isAuthenticated={isAuthenticated} authCheckComplete={authCheckComplete} />
      <ResetSessionModal
        isOpen={remainingSessionTime >= 0 && remainingSessionTime < 300 && sessionModalOpen}
        openHandler={setSessionModalOpen}
      />
    </>
  );
};

export default Site;
