/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// third party dependencies
import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// local dependencies
import './navbar.scss';
import { usePopperTooltip } from 'react-popper-tooltip';
// import Logo from '../../assets/images/logos/Logo-VBR.png';
import Logo from '../../assets/images/logos/Logo-VBR-new-bg.png';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import WAvatar from '../WAvatar/WAvatar';
import 'react-popper-tooltip/dist/styles.css';
import Popover from '../Popover/Popover';
import {
  getNotificationList,
  getNotificationCount,
  postNotificationListReadStatus,
  logOut,
  getClusterStatus,
} from '../../redux/actions';
import PopoverContainer from '../Popover/PopoverContainer/PopoverContainer';
import { SessionContext } from '../../utils/SessionContext';
import { secToTime } from '../../utils/sessionUtils';
import TooltipAvator from '../Tooltip/TooltipAvator/TooltipAvator';
import TooltipWrapper from '../Tooltip/TooltipWrapper/TooltipWrapper';
import TooltipSpan from '../Tooltip/TooltipSpan/TooltipSpan';
import { useAlert } from 'react-alert';
import axios from '../../utils/axios';

const NavBar = () => {
  const { notificationListData, count } = useSelector((state) => state.notification);
  const { remainingSessionTime, sessionModalOpen, setSessionModalOpen, setRemainingSessionTime } =
    useContext(SessionContext);

  // eslint-disable-next-line
  const { isAuthenticated, data, authCheckComplete } = useSelector((state) => state.users);
  const { isClusterRunning } = useSelector((state) => state.cluster);
  const [mounted, setMounted] = useState(false);
  const [disableClusterButton, setDisableClusterButton] = useState(false);

  const dispatch = useDispatch();

  const alert = useAlert();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getNotificationCount());
      dispatch(getClusterStatus());
    }
  }, [isAuthenticated]);

  const setMountedOnceVisible = (visible) => {
    if (!mounted && visible) {
      setMounted(true);
    } else if (mounted && !visible) {
      if (typeof notificationListData !== 'undefined') {
        if (notificationListData.length > 0 && count.unread > 0) dispatch(postNotificationListReadStatus());
      }
    }
  };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    onVisibleChange: setMountedOnceVisible,
    placement: 'auto',
  });

  const handleNotificationClick = () => {
    dispatch(getNotificationCount());
    dispatch(getNotificationList());
  };

  const handleTestCluster = async () => {
    const url = '/api/test-cluster/';
    const res = await axios.get(url);
    if (res?.data?.code === 200) {
      alert.success(
        { text: `Succesfully Run The Test Job With Job ID: ${res?.data?.data?.jid}` },
        { position: 'top center' }
      );
    } else {
      alert.error({ text: `Found error: ${res?.data?.error}` }, { position: 'top center' });
    }
  };

  const startCluster = async () => {
    const url = '/api/cluster-start/';
    setDisableClusterButton((prevState) => !prevState);
    alert.success({ text: `Cluster start initiated.ETA 2.5-3 min` }, { position: 'top center' });
    try {
      const response = await axios.post(url);
      if (res.status === 200) {
        alert.success({ text: response?.data }, { position: 'top center' });
      } else {
        // Handle other status codes
        console.log(`Received status code: ${res.status}`);
      }
    } catch (error) {
      alert.error({ text: error.response.data }, { position: 'top center' });
    } finally {
      dispatch(getClusterStatus());
      setDisableClusterButton((prevState) => !prevState);
    }
  };

  const stopCluster = async () => {
    const url = '/api/cluster-stop/';
    setDisableClusterButton((prevState) => !prevState);
    alert.success({ text: `Cluster stop initiated` }, { position: 'top center' });
    try {
      const response = await axios.post(url);
      if (res.status === 200) {
        alert.success({ text: response?.data }, { position: 'top center' });
      } else {
        // Handle other status codes
        console.log(`Received status code: ${res.status}`);
      }
    } catch (error) {
      alert.error({ text: error.response.data }, { position: 'top center' });
    } finally {
      dispatch(getClusterStatus());
      setDisableClusterButton((prevState) => !prevState);
    }
  };

  return (
    <header className="TheNavbar">
      <div className="Logo">
        <img src={Logo} alt="logo" />
        <TooltipSpan tooltipText="WIP VERSION">
          <span className="beta-class">beta</span>
        </TooltipSpan>
      </div>
      <div className="Wrapper">
        {remainingSessionTime >= 0 && remainingSessionTime < 300 && !sessionModalOpen && (
          <button
            type="button"
            className="WButton danger ok-button"
            onClick={() => {
              setSessionModalOpen(true);
            }}
          >
            {secToTime(remainingSessionTime)}
          </button>
        )}
        {data?.is_superuser ? (
          <div className="test_button">
            <ButtonIcon
              icon={isClusterRunning ? 'stop' : 'play'}
              lottie
              text={isClusterRunning ? 'Stop cluster' : 'Start Cluster'}
              clickHandler={isClusterRunning ? stopCluster : startCluster}
              disabled={disableClusterButton}
            />
          </div>
        ) : (
          <></>
        )}

        <div ref={setTriggerRef} onClick={handleNotificationClick}>
          <ButtonIcon lottie icon="notification" active="true" />
        </div>
        <Popover
          setTooltipRef={setTooltipRef}
          getTooltipProps={getTooltipProps}
          getArrowProps={getArrowProps}
          mounted={mounted}
          visible={visible}
        >
          <PopoverContainer />
        </Popover>

        <TooltipAvator tooltipText={data.name} tooltipEmail={data.email}>
          <WAvatar name={data.name} pic={data.photo_url} />
        </TooltipAvator>

        {/* <button
          type="button"
          className="WButton primary small"
          onClick={() => {
            setRemainingSessionTime(-1);
            dispatch(logOut());
          }}
        >
          Log out
        </button> */}
      </div>
    </header>
  );
};

export default NavBar;
