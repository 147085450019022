/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// local dependencies
// import ScenarioContext from '../ScenarioContext';
import { SUBPHASE_STATUSES } from '../../../constants/data';

const updateScenarioContext = (form, isValid, touched, scenarioContext) => {
  if (isValid && !isEmpty(touched)) {
    if (form === 1) {
      scenarioContext.setStatuses({
        ...scenarioContext.statuses,
        phaseOne: {
          ...scenarioContext.statuses.phaseOne,
          subPhase: {
            ...scenarioContext.statuses.phaseOne.subPhase,
            one: SUBPHASE_STATUSES.valid,
          },
        },
      });
    } else if (form === 2) {
      scenarioContext.setStatuses({
        ...scenarioContext.statuses,
        phaseTwo: {
          ...scenarioContext.statuses.phaseTwo,
          subPhase: {
            ...scenarioContext.statuses.phaseTwo.subPhase,
            one: SUBPHASE_STATUSES.valid,
          },
        },
      });
    } else if (form === 3) {
      // do nothing
    } else {
      scenarioContext.setStatuses({
        ...scenarioContext.statuses,
        phaseThree: {
          ...scenarioContext.statuses.phaseThree,
          subPhase: {
            one: SUBPHASE_STATUSES.valid,
          },
        },
      });
    }
  } else if (!isValid && !isEmpty(touched)) {
    if (form === 1) {
      scenarioContext.setStatuses({
        ...scenarioContext.statuses,
        phaseOne: {
          ...scenarioContext.statuses.phaseOne,
          subPhase: {
            ...scenarioContext.statuses?.phaseOne?.subPhase,
            one: SUBPHASE_STATUSES.error,
          },
        },
      });
    } else if (form === 2) {
      scenarioContext.setStatuses({
        ...scenarioContext.statuses,
        phaseTwo: {
          ...scenarioContext.statuses.phaseTwo,
          subPhase: {
            ...scenarioContext.statuses.phaseTwo.subPhase,
            one: SUBPHASE_STATUSES.error,
          },
        },
      });
    } else if (form === 3) {
      // do nothing
    } else {
      scenarioContext.setStatuses({
        ...scenarioContext.statuses,
        phaseThree: {
          ...scenarioContext.statuses.phaseThree,
          subPhase: {
            one: SUBPHASE_STATUSES.error,
          },
        },
      });
    }
  }
};

const AutoSubmit = ({ form, scenarioContext }) => {
  // Grab values and submitForm from context
  const { isValid, touched, submitForm, values } = useFormikContext();

  // context
  // const scenarioContext = useContext(ScenarioContext);

  useEffect(() => {
    /*
    if (isValid && !isEmpty(touched)) {
      submitForm();

      if (form === 1) {
        scenarioContext.setStatuses({
          ...scenarioContext.statuses,
          phaseOne: {
            ...scenarioContext.statuses.phaseOne,
            subPhase: {
              ...scenarioContext.statuses.phaseOne.subPhase,
              one: SUBPHASE_STATUSES.valid,
            },
          },
        });
      } else if (form === 2) {
        scenarioContext.setStatuses({
          ...scenarioContext.statuses,
          phaseTwo: {
            ...scenarioContext.statuses.phaseTwo,
            subPhase: {
              ...scenarioContext.statuses.phaseTwo.subPhase,
              one: SUBPHASE_STATUSES.valid,
            },
          },
        });
      } else if (form === 3) {
        // do nothing
      } else {
        scenarioContext.setStatuses({
          ...scenarioContext.statuses,
          phaseThree: {
            ...scenarioContext.statuses.phaseThree,
            subPhase: {
              one: SUBPHASE_STATUSES.valid,
            },
          },
        });
      }
    } else if (!isValid && !isEmpty(touched)) {
      if (form === 1) {
        scenarioContext.setStatuses({
          ...scenarioContext.statuses,
          phaseOne: {
            ...scenarioContext.statuses.phaseOne,
            subPhase: {
              ...scenarioContext.statuses?.phaseOne?.subPhase,
              one: SUBPHASE_STATUSES.error,
            },
          },
        });
      } else if (form === 2) {
        scenarioContext.setStatuses({
          ...scenarioContext.statuses,
          phaseTwo: {
            ...scenarioContext.statuses.phaseTwo,
            subPhase: {
              ...scenarioContext.statuses.phaseTwo.subPhase,
              one: SUBPHASE_STATUSES.error,
            },
          },
        });
      } else if (form === 3) {
        // do nothing
      } else {
        scenarioContext.setStatuses({
          ...scenarioContext.statuses,
          phaseThree: {
            ...scenarioContext.statuses.phaseThree,
            subPhase: {
              one: SUBPHASE_STATUSES.error,
            },
          },
        });
      }
    }
    */
    if (isValid && !isEmpty(touched)) submitForm();
    updateScenarioContext(form, isValid, touched, scenarioContext);
  }, [isValid, values, submitForm]);

  // returning empty element context
  return null;
};

export { AutoSubmit, updateScenarioContext };

AutoSubmit.propTypes = {
  form: PropTypes.number.isRequired,
};
