import {
  ADMIN_START,
  ADMIN_ERROR,
  ADMIN_POST,
  ADMIN_GET,
  GET_USER_FUNCTION,
  GET_USER_ROLE,
  POST_USER,
  UDPATE_USER,
  SELECTED_USER_ID,
  SELECTED_USER_DETAILS,
} from '../actionTypes';

const initialState = {
  loading: false,
  error: null,
  usersList: [],
  userFunctions: [],
  userRoles: [],
  selectedUserId: 0 || null,
  selectedUserDetails: [] || null,
};

// eslint-disable-next-line
const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_START:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_GET:
      return {
        ...state,
        loading: false,
        usersList: payload.data,
      };

    case ADMIN_POST:
      return {
        ...state,
        loading: false,
        usersList: payload.data,
      };

    case ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case GET_USER_FUNCTION:
      return {
        ...state,
        loading: false,
        userFunctions: payload.data,
      };
    case GET_USER_ROLE:
      return {
        ...state,
        loading: false,
        userRoles: payload.data.filter(r => [1, 3].includes(r.id)),
      };

    case POST_USER:
      return {
        ...state,
        loading: false,
      };

    case UDPATE_USER:
      return {
        ...state,
        loading: false,
      };

    case SELECTED_USER_ID:
      return {
        ...state,
        selectedUserId: payload.userId,
      };

    case SELECTED_USER_DETAILS:
      return {
        ...state,
        loading: false,
        selectedUserDetails: payload.data,
      };

    default:
      return state;
  }
};

export default adminReducer;
