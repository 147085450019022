import { PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const PieChartComponent = ({ data, categoryColors, title }) => {
  // const categoryColors = {
  //   '2G': '#82ca9d', // Orange background: #B06811;

  //   '3G': '#A4548B', // Green #3E8838
  //   '4G': '#3E8838', // Red
  //   '5G': '#B06811',
  // };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" style={{ fontWeight: 'bold' }}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    );
  };
  // const renderLegend = (value, entry) => {
  //   const { color } = entry;
  //   return (
  //     <span>
  //       <svg width="14" height="14" style={{ marginRight: 5 }}>
  //         <circle cx="7" cy="7" r="7" fill={color} />
  //       </svg>
  //       {value}
  //     </span>
  //   );
  // };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyle: 'none', padding: 0, display: 'flex', justifyContent: 'center' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
            <svg width="14" height="14" style={{ marginRight: 5 }}>
              <circle cx="7" cy="7" r="7" fill={categoryColors[entry.value]} />
            </svg>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  // cx={350} cy={180}
  return (
    <>
      <h6>{title}</h6>
      <PieChart height={600} width={500}>
        <Pie
          dataKey="value"
          data={data}
          innerRadius={120}
          outerRadius={210}
          label={renderCustomizedLabel}
          labelLine={false}
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={categoryColors[entry.name]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend content={renderLegend} />
      </PieChart>
    </>
  );
};

export default PieChartComponent;
