export default {
  unauthorized: {
    text: 'User name or password is incorrect.',
    title: 'Error signing in..!!',
  },
  forbidden: {
    text: 'You donot have access to this resource.',
    title: 'Oops..!!',
  },
  conflict: {
    text: 'Make sure the data has no conflicts.',
    title: 'Oops..!!',
  },
  notfound: {
    text: 'Resource not found.',
    title: '404',
  },
  unknown: {
    title: 'Unexpected error.',
    text: 'Please, try again or contact us.',
  },
  404: {
    title: 'Page not found',
  },
  select: {
    noOptions: 'No elements found.',
  },
};
