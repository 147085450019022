/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Add third-party dependencies.
import axios from 'axios';

// Get environment variables.
const { REACT_APP_HTTP, REACT_APP_API } = process.env;

// Get current domain.
const domain = `${window.location.protocol}//${window.location.host}`;

// Concatentate base URL.
const api = REACT_APP_API || `${domain}/api`;
const baseURL = REACT_APP_HTTP ? `${REACT_APP_HTTP}://${api}/` : `${api}/`;

// return to login page
const returnToLogin = () => {
  window.location.href = '/login/';
};

// Create instance of function.
const axiosInstance = axios.create({
  baseURL,
  // timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('access') ? `Bearer ${localStorage.getItem('access')}` : null,
    Accept: 'application/json',
  },
});

// Update headers.
axiosInstance.interceptors.request.use((config) => {
  // ('axios interceptor', localStorage.getItem('access'));
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = localStorage.getItem('access') ? `Bearer ${localStorage.getItem('access')}` : null;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // console.log(
    //   'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx\n',
    //   'Print axios URL => => => => => =>',
    //   response.config.url,
    //   '\n*******************************************'
    // );
    return response;
  },
  async function check(error) {
    // console.log(
    //   'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx\n',
    //   'Print axios error => => => => => =>',
    //   error,
    //   '\n*******************************************'
    // );

    const originalRequest = error.config;

    if (typeof error.response === 'undefined') {
      console.log(
        'A server/network error occurred. Looks like CORS might be the problem. Sorry about this - we will get it fixed shortly.'
      );
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url === `${baseURL}token/refresh/`) {
      console.log('axios:', error);
      returnToLogin();
      return Promise.reject(error);
    }

    if (error.response.status === 0 && error.response.data === undefined) returnToLogin();

    if (
      error.response.data.code === 'token_not_valid' &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      const refreshToken = localStorage.getItem('refresh');

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        console.log(tokenParts.exp);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access', response.data.access);
              localStorage.setItem('refresh', response.data.refresh);

              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.access}`;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        console.log('Refresh token is expired', tokenParts.exp, now);
        // window.location.href = '/login/';
      } else {
        console.log('Refresh token not available.');
        // window.location.href = '/login/';
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

// Export function.
export default axiosInstance;
