import './PopoverContainerItem.scss';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

const getStatusCssClass = (type) => {
  let cssClass = '';
  switch (type) {
    case 'scenario-archived':
      cssClass = 'scenario-archived';
      break;
    case 'scenario-queued':
      cssClass = 'scenario-queued';
      break;
    case 'phase-started':
      cssClass = 'phase-started';
      break;
    case 'phase-completed':
      cssClass = 'phase-completed';
      break;
    case 'scenario-completed':
      cssClass = 'scenario-completed';
      break;
    case 'scenario-duplicated':
      cssClass = 'scenario-duplicated';
      break;
    default:
      cssClass = 'scenario-archived';
  }

  return cssClass;
};

const getScenarioProgess = (scenarioType, data) => {
  let text = '';

  switch (scenarioType) {
    case 'scenario-archived':
      text = 'Archived scenario';
      break;
    case 'scenario-queued':
      text = 'Added to queue';
      break;
    case 'phase-started':
      text = `Phase ${JSON.parse(data).phase} started to simulate`;
      break;
    case 'phase-completed':
      text = `Phase ${JSON.parse(data).phase} is completed. Data is refreshed`;
      break;
    case 'scenario-completed':
      text = 'Scenario completed';
      break;
    case 'scenario-duplicated':
      text = 'Scenario automatically duplicated';
      break;
    default:
      text = 'Archived scenario';
  }
  return text;
};

const getLifeDate = (date) => {
  const newdate = moment(date).format('YYYY-MM-DD[T]HH:mm:ss');
  return moment(newdate).fromNow(true);
};

const PopoverContainerItem = ({ row }) => {
  return (
    <>
      <div className={clsx('popoveritem', Boolean(row.is_seen) === false && 'unread')}>
        <div className="status-heading">
          <div
            className={clsx(
              'ScenarioNameContainer',
              Boolean(row.scenario.pinned) === true && 'scenario-pinned',
              Boolean(row.scenario.pinned) === false && 'scenario-unpinned'
            )}
          >
            {Boolean(row.scenario.pinned) === true && (
              <i className="WIcon icon-selected white" style={{ fontSize: '12px' }} />
            )}
            <p
              className={clsx(
                Boolean(row.scenario.pinned) === true && 'itemheading-pinned',
                Boolean(row.scenario.pinned) === false && 'itemheading-unpinned'
              )}
            >
              {row.scenario.name}
            </p>
            <p
              className={clsx(
                Boolean(row.scenario.pinned) === true && 'itemdate-pinned',
                Boolean(row.scenario.pinned) === false && 'itemdate-unpinned'
              )}
            >
              {getLifeDate(row.created_at)}
            </p>
          </div>
        </div>
        <div>
          <div className={clsx('statusdot', getStatusCssClass(row.type))} />
          <div className={clsx('item-progress', getStatusCssClass(row.type))}>
            {getScenarioProgess(row.type, row.data)}
          </div>
        </div>
      </div>
      <div className="borderseparator" />
    </>
  );
};

export default PopoverContainerItem;

PopoverContainerItem.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    scenario: PropTypes.shape({
      name: PropTypes.string,
      pinned: PropTypes.bool,
    }),
    type: PropTypes.string,
    data: PropTypes.string,
    created_at: PropTypes.string,
    is_seen: PropTypes.bool,
  }),
};

PopoverContainerItem.defaultProps = {
  row: {
    id: 0,
    scenario: {
      name: '',
      pinned: false,
    },
    type: '',
    data: '',
    created_at: '',
    is_seen: false,
  },
};
