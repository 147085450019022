import axios from '../../utils/axios';
import {
  ADMIN_ERROR,
  ADMIN_GET,
  ADMIN_START,
  GET_USER_FUNCTION,
  GET_USER_ROLE,
  POST_USER,
  SELECTED_USER_DETAILS,
  SELECTED_USER_ID,
  UDPATE_USER,
} from '../actionTypes';

const getUsers = () => {
  // Return dispatch.
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_START });
      const url = '/auth/users/';
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: ADMIN_GET, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: ADMIN_ERROR, payload: { error } });
    }
  };
};

const getUserFunctions = () => {
  return async (dispatch) => {
    try {
      const url = '/auth/user-functions/';
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: GET_USER_FUNCTION, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: ADMIN_ERROR, payload: { error } });
    }
  };
};

const getUserRoles = () => {
  return async (dispatch) => {
    try {
      const url = '/auth/roles/';
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: GET_USER_ROLE, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: ADMIN_ERROR, payload: { error } });
    }
  };
};

const updateProfilePicture = async (userId, profilePicture) => {
  const updateUrl = `/auth/users/${userId}/`;
  const formdata = new FormData();
  formdata.append(
    'photo_url',
    profilePicture,
    profilePicture?.name ? profilePicture?.name : `profile_pic_${userId}.png`
  );
  await axios.patch(updateUrl, formdata, { headers: { 'Content-Type': 'multipart/form-data' } });
};

const postUser = (userData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_START });
      const url = '/auth/users/';
      const urlUpdateRole = `/auth/assign-user-role/`;
      const { photo_url: photoUrl } = userData;
      delete userData?.photo_url; //eslint-disable-line
      const { data } = await axios.post(url, userData);
      if (data?.id) {
        await axios.post(urlUpdateRole, [{ user_id: data?.id, role_id: userData.role }]);
        if (photoUrl) {
          await updateProfilePicture(data.id, photoUrl);
        }
      }

      dispatch({ type: POST_USER, payload: { data } });
      dispatch({
        type: ADMIN_ERROR,
        payload: { error: { response: { status: 200, data: { message: `${userData.email} added successfully` } } } },
      });
      return dispatch(getUsers());
    } catch (error) {
      // Handle error.
      return dispatch({ type: ADMIN_ERROR, payload: { error } });
    }
  };
};

const updateUser = (userId, userData, photoURLUpdate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_START });
      const url = `/auth/users/${userId}/`;
      const { photo_url: photoUrl } = userData;
      delete userData?.photo_url; //eslint-disable-line
      const urlUpdateRole = `/auth/assign-user-role/`;
      // console.log('API call:', url, urlUpdateRole);
      const { data } = await axios.put(url, userData);
      await axios.post(urlUpdateRole, [{ user_id: userId, role_id: userData.role }]);
      if (photoURLUpdate && photoUrl) {
        await updateProfilePicture(userId, photoUrl);
      }
      dispatch({ type: UDPATE_USER, payload: { data } });
      dispatch({
        type: ADMIN_ERROR,
        payload: { error: { response: { status: 200, data: { message: `${userData.email} updated successfully` } } } },
      });
      return dispatch(getUsers());
    } catch (error) {
      // Handle error.
      return dispatch({ type: ADMIN_ERROR, payload: { error } });
    }
  };
};

const getSelectedUserId = (userId) => {
  return async (dispatch) => {
    try {
      return dispatch({ type: SELECTED_USER_ID, payload: { userId } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: ADMIN_ERROR, payload: { error } });
    }
  };
};

const getSelectedUserDetails = (userId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: ADMIN_START });
      const url = `/auth/users/${userId}/`;
      // console.log('API call:', url);
      const { data } = await axios.get(url);
      return dispatch({ type: SELECTED_USER_DETAILS, payload: { data } });
    } catch (error) {
      // Handle error.
      return dispatch({ type: ADMIN_ERROR, payload: { error } });
    }
  };
};

export { getUsers, getUserFunctions, getUserRoles, postUser, updateUser, getSelectedUserId, getSelectedUserDetails };
