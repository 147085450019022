/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, Transition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';

// local dependencies
import './ScenarioCreate.scss';
import WText from '../WText/Wtext';
import WIcon from '../WIcon/WIcon';
import { addScenario } from '../../redux/actions/scenarioActions';

const validationSchema = Yup.object().shape({
  scenarioName: Yup.string()
    .required('Scenario name is required')
    .min(4, 'Scenario name should have more than 4 characters'),
});

const defaultStyle = {
  transition: `opacity 200ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const ScenarioCreate = ({ isOpen, setIsOpen }) => {
  // i18n
  const intl = useIntl();

  //alert
  const alert = useAlert();

  // navigate
  const navigate = useNavigate();

  // dispatch
  const dispatch = useDispatch();

  const nodeRef = useRef(null)

  const { data: user } = useSelector((state) => state.users);
  const { addLoading, addData, addError } = useSelector((state) => state.scenarios);

  useEffect(() => {
    if (!addLoading && addData !== null) {
      setIsOpen(false);
      navigate(`/scenario/${addData?.id}/edit`, { replace: false, state: { edit: true } });
      alert.success({ text: "Scenario Created Successfully" }, { position: 'top center' })
    }

  }, [addData, addLoading]);

  useEffect(() => {
    if (addError != null) {
      alert.error({ text: addError.response.data.name[0] }, { position: 'top center' })
    }
  }, [addError])

  return (
    <CSSTransition nodeRef={nodeRef} in={isOpen} classNames="open-close" timeout={500} unmountOnExit>
      <div ref={nodeRef} className="scenario-create">
        <div className="dialog-scenario">
          {/* <WLink icon="back" iconPlacement="left">
            {' Back '}
          </WLink> */}
          <WText typo="title">{intl.formatMessage({ id: 'scenario.newSimulation.title' })}</WText>
          <button className="close-btn" type="button" onClick={() => setIsOpen(false)}>
            <WIcon icon="close" size={24} />
          </button>
          <div className="content-create">
            <Formik
              initialValues={{ scenarioName: '' }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const postData = {
                  name: values.scenarioName,
                };
                if (user?.id && user?.function) {
                  postData.function = user.function;
                }
                dispatch(addScenario(postData));
              }}
            >
              {({ errors, handleSubmit }) => (
                <Form className="WInput bigger">
                  <Field name="scenarioName" type="text" placeholder="Scenario name" className="formik-input" />
                  <Transition in={!!errors.scenarioName} timeout={200}>
                    {(state) => (
                      <div className="error-message" style={{ ...defaultStyle, ...transitionStyles[state] }}>
                        <WText typo="body">
                          <WIcon icon="error-fill" color="danger" size={16} />
                        </WText>
                        <div className="text-message">{errors.scenarioName}</div>
                      </div>
                    )}
                  </Transition>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className={`create-button btn primary large ${!errors.scenarioName ? '' : 'disabled'}`}
                    disabled={errors.scenarioName}
                  >
                    <div className="content-btn">{intl.formatMessage({ id: 'scenario.inputs.create' })}</div>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="backdrop" />
      </div>
    </CSSTransition>
  );
};

export default ScenarioCreate;

ScenarioCreate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

ScenarioCreate.defaultProp = {
  isOpen: false,
  setIsOpen: () => { },
};
