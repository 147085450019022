/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies
import {
    CLUSTER_STATUS_GET
  } from '../actionTypes';
  
  const initialState = {
    isClusterRunning: true,
    error: null,
  };
  
  // eslint-disable-next-line default-param-last
  const clusterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
    case CLUSTER_STATUS_GET:
        return {
            ...state,
            isClusterRunning: payload.data,
        };
      default:
        return state;
    }
  };
  
  export default clusterReducer;
  