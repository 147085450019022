export default {
  status: {
    void: 'Without input',
    valid: 'Ready',
    error: 'Error',
    done: 'Done',
    stopped: 'Stopped',
    failed: 'Failed',
  },
};
