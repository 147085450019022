/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies

export default {
  empty: 'There are no scenarios to show in this reports section',
};
