export default {
  workspace: 'Workspace',
  running: 'Running',
  completed: 'Completed',
  archive: 'Archive',
  queued: 'Queued',
  pending: 'Pending',
  pinned: 'Pinned',
  users: 'Users',
  reports: 'Reports'
};
