/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies
import { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isEmpty, isEqual } from 'lodash';

// local dependencies
import './ScenarioScreen.scss';
import { useAlert } from 'react-alert';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import {
  getUser,
  getScenario,
  getInputFilePhase1,
  getInputFilePhase2,
  updateScenario,
  pinScenario,
  unpinScenario,
  updateScreenMountFlag,
} from '../../redux/actions';
import WAvatar from '../../components/WAvatar/WAvatar';
import WText from '../../components/WText/Wtext';
import WDivider from '../../components/WDivider/WDivider';
import ScenarioInputs from './ScenarioInputs/ScenarioInputs';
import ActionBar from '../../components/ActionBar/ActionBar';
import ScenarioContext from './ScenarioContext';
import { SUBPHASE_STATUSES } from '../../constants/data';
import { updateScenarioContext } from './ScenarioInputs/AutoSubmit';
import { getUserFunctions } from '../../redux/actions/entityActions';
import DuplicateModalFetch from '../../components/DuplicateModalFetch/DuplicateModalFetch';
import { WSpinnerLarge } from '../../components/WLoader/WSpinner';
import { isValNumeric, prepareServerURL } from '../../utils/utils';
import { SCENARIO_UPDATE_MSG_RESET } from '../../redux/actionTypes';

const ScenarioScreen = () => {
  // params
  const { id } = useParams();

  // alert
  const alert = useAlert();

  // location
  const location = useLocation();
  const { edit } = location.state;

  // redux dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // global state
  const { loading, data: scenario, scenarioUpdateMsg, error: scenarioError } = useSelector((state) => state.scenarios);
  const { phase1ExcelData, phase2ExcelData } = useSelector((state) => state.inputFiles);
  const { data: user } = useSelector((state) => state.users);
  const { userFunctions } = useSelector((state) => state.entities);

  // local state
  const [scenarioFunctions, setScenarioFunctions] = useState([]);
  const [selectedScenarioFunction, setSelectedScenarioFunction] = useState(null);
  const [statuses, setStatuses] = useState({});
  const [mounted, setMounted] = useState(false);

  // phase 1, 2, 3 submit button ref
  const phaseSubmitBtnref = {
    name: useRef('scenario-name-submit'),
    phase1: useRef('scenario-input-phase1-submit'),
    phase2: useRef('scenario-input-phase2-submit'),
    phase3: useRef('scenario-input-phase3-submit'),
  };

  useEffect(() => {
    if (user?.id && user?.function) {
      setSelectedScenarioFunction(user.function);
    } else if (user?.id && !user?.function && scenario?.function) {
      setSelectedScenarioFunction(scenario.function);
    }
    if (user?.id && !userFunctions?.error && userFunctions?.data?.length) {
      setScenarioFunctions(userFunctions?.data?.filter((fn) => fn?.is_active));
    }
  }, [user, userFunctions, scenario]);

  useEffect(() => {
    if (scenario?.id == id) {
      dispatch(getInputFilePhase1(scenario?.inputs[0]?.id, id));
      dispatch(getInputFilePhase2(scenario?.inputs[1]?.id, id));
    }
  }, [id, scenario]);

  useEffect(() => {
    if (scenarioError?.response?.status === 404) {
      navigate('/not-found');
    }
  }, [scenarioError]);
  useEffect(() => {
    if (scenarioUpdateMsg != null) {
      if (scenarioUpdateMsg.response.status === 200) {
        alert.success({ text: scenarioUpdateMsg.response.data.message }, { position: 'top center' });
        dispatch({
          type: SCENARIO_UPDATE_MSG_RESET,
          payload: { data: null },
        });
      }
    }
  }, [scenarioUpdateMsg]);

  const subPhaseExcelDataStatus = (phase) => {
    let phaseExcelData = {};
    if (phase === 1) {
      phaseExcelData = phase1ExcelData;
    } else if (phase === 2) {
      phaseExcelData = phase2ExcelData;
    }

    if (!isEmpty(phaseExcelData)) {
      if (!isValNumeric(phaseExcelData?.id) && !phaseExcelData?.is_valid) {
        return SUBPHASE_STATUSES.error;
      }
      if (isValNumeric(phaseExcelData?.id)) {
        if (phaseExcelData?.is_valid && phaseExcelData?.is_uploaded) {
          return SUBPHASE_STATUSES.valid;
        }
        return SUBPHASE_STATUSES.error;
      }
    }
    return SUBPHASE_STATUSES.void;
  };

  useEffect(() => {
    setStatuses({
      phaseOne: {
        status: scenario?.inputs[0]?.status,
        subPhase: {
          one: scenario?.inputs[0]?.data?.fields[0]?.status,
          two: subPhaseExcelDataStatus(1),
        },
      },
      phaseTwo: {
        status: scenario?.inputs[1]?.status,
        subPhase: {
          one: scenario?.inputs[1]?.data?.fields[0]?.status,
          two: subPhaseExcelDataStatus(2),
        },
      },
      phaseThree: {
        status: scenario?.inputs[2]?.status,
        subPhase: {
          one: scenario?.inputs[2]?.data?.fields[0]?.status,
        },
      },
    });
  }, [scenario, phase1ExcelData, phase2ExcelData]);

  useEffect(() => {
    // meta info change
    document.title = 'Scenario Inputs | NEXO by BCG';

    dispatch(getScenario(id));
    dispatch(getUserFunctions());
    dispatch(getUser());
  }, [id]);

  useEffect(() => {
    let compMounted = true;
    if (compMounted) {
      dispatch(updateScreenMountFlag(compMounted));
    }
    return function cleanup() {
      compMounted = false;
      dispatch(updateScreenMountFlag(compMounted));
    };
  }, []);

  const initialValues = {
    scenarioName: scenario?.name || '',
  };

  const validationSchema = Yup.object().shape({
    scenarioName: Yup.string().required('is required'),
  });

  const getFunctionName = (functionId) => {
    if (scenarioFunctions.length > 0) {
      return scenarioFunctions.filter((fn) => fn.id === functionId)?.[0]?.name;
    }
    return null;
  };

  const scenarioValues = useMemo(
    () => ({ statuses, setStatuses, phase1ExcelData, phase2ExcelData, setMounted, edit, phaseSubmitBtnref }),
    [statuses, phase1ExcelData, phase2ExcelData]
  );

  return (
    <div className="scenario-screen">
      {/* TODO: fade transistion */}

      {loading ? (
        <div className="page-spinner">
          <WSpinnerLarge />
        </div>
      ) : (
        <>
          <div className="scenario-title">
            <ButtonIcon
              small
              lottie
              icon="unselected"
              active
              clickHandler={!scenario?.pinned ? () => dispatch(pinScenario(id)) : () => dispatch(unpinScenario(id))}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              validateOnBlur
              onSubmit={(values) => {
                dispatch(
                  updateScenario(scenario?.id, {
                    name: values.scenarioName,
                  })
                );
              }}
            >
              {({ errors, touched, values, isValid, setFieldTouched, handleChange, submitForm }) => (
                <Form>
                  <div
                    className={`date-input name-input ${clsx({
                      error: touched.scenarioName && errors.scenarioName,
                    })}`}
                  >
                    <Field
                      name="scenarioName"
                      type="text"
                      value={values.scenarioName}
                      placeholder={values.scenarioName}
                      autoComplete="false"
                      style={{ width: '600px', height: '175px', fontSize: '1.8rem' }}
                      disabled={!edit}
                      onChange={(e) => {
                        setFieldTouched(e.target.name);
                        handleChange(e);
                      }}
                    />
                    {touched.scenarioName && errors.scenarioName && (
                      <div className="message">
                        <WText typo="caption">{errors.scenarioName}</WText>
                      </div>
                    )}
                  </div>
                  <button
                    ref={phaseSubmitBtnref.name}
                    type="button"
                    className="scenarion-submit-button-hide"
                    onClick={() => {
                      if (!isEqual(initialValues, values)) {
                        if ((scenario?.name || '').toLowerCase() !== values.scenarioName.toLowerCase()) {
                          updateScenarioContext(3, isValid, touched, scenarioValues);
                          if (isValid && !isEmpty(touched)) submitForm();
                        }
                      }
                    }}
                  >
                    &nbsp;
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="scenario-config-header">
            <WAvatar
              medium
              name={scenario?.user_details?.name}
              pic={prepareServerURL(scenario?.user_details?.photo_url)}
              clickable={false}
            />
            <div className="ml-1">
              <WText typo="small" color="grey06">
                {scenario?.user.name?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
              </WText>
            </div>
            <div style={{ margin: '0 1rem' }}>
              <WText typo="smallLight" color="grey06">
                Scenario ID
              </WText>
              <WText typo="smallLight" color="grey06">
                {`scenario_${scenario?.id}`}
              </WText>
            </div>
            <div className="ml-2">
              <WText typo="smallLight" color="grey06">
                Last Edit
              </WText>
              <WText typo="smallLight" color="grey06">
                {moment(scenario?.updated_at).format('YYYY/MM/DD (HH:mm)')}
              </WText>
            </div>
            <div style={{ margin: '0 1rem' }}>
              <WText typo="smallLight" color="grey06">
                Function
              </WText>
              {user?.id && !user?.function && (
                <select
                  id="userRole"
                  name="userRole"
                  component="select"
                  className="user-input-select"
                  disabled={!edit}
                  onChange={(e) => {
                    setSelectedScenarioFunction(e.target.value);
                    dispatch(
                      updateScenario(scenario?.id, {
                        function: e.target.value,
                      })
                    );
                  }}
                  value={selectedScenarioFunction}
                >
                  {scenarioFunctions.map((scenarioFunction) => {
                    return (
                      <option
                        value={scenarioFunction.id}
                        className="user-input-select-option"
                        key={scenarioFunction.id}
                      >
                        {scenarioFunction.name}
                      </option>
                    );
                  })}
                </select>
              )}
              {user?.id && user?.function && (
                <WText typo="smallLight" color="grey06">
                  {getFunctionName(selectedScenarioFunction)}
                </WText>
              )}
            </div>
          </div>
          <div style={{ marginBottom: '64px' }}>
            <WDivider withMargin={false} />
          </div>
          {scenario && <ScenarioContext.Provider value={scenarioValues}>
            <ScenarioInputs scenario={scenario} />
            <ActionBar scenario={scenario} phases={scenario?.inputs} />
          </ScenarioContext.Provider>}
          <DuplicateModalFetch open={mounted} setOpen={setMounted} selectedItem={scenario} />
        </>
      )}
    </div>
  );
};

export default ScenarioScreen;
