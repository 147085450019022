/**
 * Copyright © 2022 by Boston Consulting Group. All rights reserved.
 */

// Third party dependencies

// local dependencies

const LOCALES = {
  ENGLISH: 'en-US',
  //   JAPANESE: 'ja-JA',
  //   FRENCH: 'fr-FR',
  //   GERMAN: 'de-DE',
};

export default LOCALES;
