import React from 'react';
import { transitions, positions, types, Provider as AlertProvider } from 'react-alert';
import clsx from 'clsx';
import './WNotification.scss';

const WNotification = ({ type, children }) => {
  const selectedTypeCss = (type) => {
    var typeClass = 'information';
    try {
      if (typeof type === 'string' || type instanceof String) {
        switch (type.toLowerCase()) {
          case 'info':
          case 'information':
          case 'i':
            typeClass = 'information';
            break;
          case 'err':
          case 'error':
          case 'e':
            typeClass = 'error';
            break;
          case 'succ':
          case 'success':
          case 's':
            typeClass = 'success';
            break;
          case 'warn':
          case 'warning':
          case 'w':
            typeClass = 'warning';
            break;
          default:
            typeClass = 'information';
        }
      }
    } catch (error) {
      typeClass = 'information';
    }

    return typeClass;
  };

  const options = {
    position: positions.BOTTOM_LEFT,
    timeout: 5000,
    offset: '30px',
    transition: transitions.FADE,
    containerStyle: {},
    className: 'WNotification',
    type: type,
  };

  const AlertTemplate = ({ style, options, message, close }) => (
    <>
      <div className={clsx('WNotification', selectedTypeCss(options.type))} style={options.containerStyle}>
        <div className="notification-title">{message.title}</div>
        <div className="notification-content">{message.text}</div>
      </div>
    </>
  );

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      {children}
    </AlertProvider>
  );
};

export default WNotification;
